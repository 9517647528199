/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorMessage } from '@hookform/error-message';
import IconDownArrow, { ReactComponent as IconArrowDownBig } from 'assets/_v2/_common/icon_arrow_down_12.svg';
import { ReactComponent as IconArrowDown } from 'assets/_v2/_common/input/icon_small_arrow_down.svg';

import { ReactComponent as IconArrowUpDown } from 'assets/_v2/_common/input/icon_small_arrow_up_down.svg';
import JDUnderBaseModal from 'components/_v2/_common/modals/JDUnderBaseModal';
import { StyledIconSVG } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { absoluteVerticalCenter, JDInputMessage, JDSmallSeletor } from 'consts/_v2/_common/style/mixins';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

export enum JDSelectorType {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  MEDIUMSMALL = 'MEDIUMSMALL',
  NORMAL = 'NORMAL',
  BIG = 'BIG',
  ORDER = 'ORDER',
}

const StyledSmallSelector = styled.div`
  ${JDSmallSeletor()}
`;

export const StyledMediumSelector = styled.div`
  min-width: 130px;
  position: relative;
  display: inline-flex;
  height: 38px;
  padding: 12px 16px;
  white-space: nowrap;
  font: ${Fonts.B3_Bold};
  color: ${colors.JOBDA_BLACK};
  background: ${colors.WHITE_100};
  border: 1px solid ${colors.JOBDA_BLACK};
  border-radius: 4px;

  svg {
    ${absoluteVerticalCenter()}
    right: 16px;
  }
  input:disabled {
    -webkit-text-fill-color: ${colors.CG_90};
    opacity: 1;
  }
`;

export const StyledMediumSmallSelector = styled.div`
  min-width: 70px;
  position: relative;
  display: inline-flex;
  height: 38px;
  padding: 10px 8px;
  white-space: nowrap;
  font: ${Fonts.B3_Medium};
  line-height: 16px;
  color: ${colors.CG_80};
  background: ${colors.WHITE_100};
  border: 1px solid ${colors.CG_50};
  border-radius: 4px;
  align-items: center;
  svg {
    ${absoluteVerticalCenter()}
    right: 5px;
  }
  input:disabled {
    -webkit-text-fill-color: ${colors.CG_90};
    opacity: 1;
  }
`;

const StyledNormalSelector = styled.div`
  width: 100%;
  height: 48px;
  position: relative;
  display: block;
  color: ${colors.CG_90};
  outline: 1px solid ${colors.CG_30};
  background: ${colors.CG_30};
  border-radius: 4px;

  &[data-disabled='true'] {
    opacity: 0.5;
    color: ${colors.CG_60};
  }

  input {
    width: 100%;
    height: 100%;
    padding: 0px 50px 0px 16px;
    background: inherit;
    font: ${Fonts.B2_Medium};
    border: none;
    outline: none;
    pointer-events: 'cursor';
    caret-color: transparent;

    ::placeholder {
      color: ${colors.CG_60};
    }

    /* IOS 사파리에서 선택시 placeholder 색 나오는 부분 해결 */
    &[data-selected='true'] {
      -webkit-text-fill-color: ${colors.CG_90};
      opacity: 1;
    }
  }

  /* &[data-disabled='true'] {
    opacity: 0.5;
  } */
 
  img {
    ${absoluteVerticalCenter()};
    right: 16px;
  }

  &.false {
    outline: 1px solid ${colors.ERROR};
    .icon-close {
      filter: invert(44%) sepia(78%) saturate(2124%) hue-rotate(331deg)
        brightness(102%) contrast(97%);
    }
  }
`;

const StyledBigSelector = styled.div`
  width: 100%;
  height: 48px;
  position: relative;
  display: block;
  color: ${colors.JOBDA_BLACK};
  outline: 1px solid ${colors.JOBDA_BLACK};
  background: ${colors.WHITE_100};
  border-radius: 4px;
  input {
    width: 100%;
    height: 100%;
    padding: 0px 50px 0px 16px;
    background: inherit;
    font: ${Fonts.B2_Medium};
    border: none;
    outline: none;
    /* IOS 사파리에서 선택시 placeholder 색 나오는 부분 해결 */
    &[data-selected='true'] {
      -webkit-text-fill-color: ${colors.JOBDA_BLACK};
      opacity: 1;
    }
  }
  img {
    ${absoluteVerticalCenter()};
    right: 16px;
  }
`;

const StyledOrderSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px 8px 0px;
  height: 28px;
  font: ${Fonts.B4_Bold};
  color: ${colors.JOBDA_BLACK};
  svg {
    margin-left: 6px;
  }

  span {
    text-align: left;
    width: fit-content;
    font: ${Fonts.B3_Bold};
  }
`;

const Frame = styled.div`
  width: 100%;
  select {
    display: none;
    &.placeholder ~ ${StyledNormalSelector} {
      color: ${colors.CG_60};
    }
  }

  .message {
    ${JDInputMessage()};
  }
`;

const StyledSelectOptions = styled.div`
  text-align: center;
  .option-title {
    padding: 16px 0;
    color: ${colors.JOBDA_BLACK};
    font: ${Fonts.B1_Bold};
    border-bottom: 1px solid ${colors.CG_40};
  }
  .option-wrapper {
    position: relative;
    max-height: 312px;
    margin-top: 12px;
    .snap-wrapper {
      overflow: auto;
      max-height: inherit;
      scroll-snap-type: y mandatory;
      -ms-overflow-style: none;
      scrollbar-width: none;
      ::-webkit-scrollbar {
        display: none;
      }

      &.active-grad-top {
        .grad-top {
          display: block;
        }
      }
      &.active-grad-bottom {
        .grad-bottom {
          display: block;
        }
      }
      .select-option {
        scroll-snap-align: center;
        padding: 16px 0;
        font: ${Fonts.H5_Medium};
        color: ${colors.CG_60};
        span {
          &[data-selected='true'] {
            color: ${colors.JOBDA_BLACK};
          }
        }
        &[data-disabled='true'] {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }
  }

  .grad-bottom {
    z-index: 1;
    display: none;
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 51.56%
    );
  }
  .grad-top {
    z-index: 1;
    display: none;
    position: absolute;
    width: 100%;
    height: 40px;
    top: 0px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 51.56%
    );
  }
`;

interface IJDSelector extends IComponentProps {
  selectTitle: string;
  name: string;
  onChange?: (value?: string) => void;
  value?: string;
  disabled?: boolean;
  type?: JDSelectorType;
  saveData?: (text: string) => void;
  defaultText?: string;
}

const JDSelector: FC<IJDSelector> = (props) => {
  const {
    name,
    saveData,
    onChange,
    selectTitle,
    value,
    disabled = false,
    type = JDSelectorType.SMALL,
    defaultText,
    ...rest
  } = props;
  const useFormed = useFormContext();

  const frameRef = useRef<HTMLDivElement>(null);
  const snapWrapperRef = useRef<HTMLDivElement>(null);
  const [selectedText, setSelectedText] = useState<string>('');
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectOptions, setSelectOptions] = useState<React.ReactNode>(<></>);
  const [optionLength, setOptionLength] = useState<number>(0);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const { setValue, control, errors, watch } = useFormed;
  const {
    field: { ref: BaseRef },
    meta,
  } = useController({ name, control });

  const scrollHandler = () => {
    if (!snapWrapperRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = snapWrapperRef.current;
    if (scrollTop > 0) {
      snapWrapperRef.current.classList.add('active-grad-top');
    } else {
      snapWrapperRef.current.classList.remove('active-grad-top');
    }
    if (scrollTop < scrollHeight - clientHeight) {
      snapWrapperRef.current.classList.add('active-grad-bottom');
    } else {
      snapWrapperRef.current.classList.remove('active-grad-bottom');
    }
  };

  useEffect(() => {
    if (snapWrapperRef.current) {
      snapWrapperRef.current.addEventListener('scroll', scrollHandler);
      if (snapWrapperRef.current !== null)
        snapWrapperRef.current.scroll(
          0,
          (snapWrapperRef.current.scrollHeight / optionLength)
            * (selectedIndex - 2),
        );
    }
    return () => {
      if (snapWrapperRef.current) {
        snapWrapperRef.current.removeEventListener('scroll', scrollHandler);
        setOptionLength(0);
      }
    };
  }, [isSelectOpen]);

  const generateSelectOptions = (
    selectEl: HTMLSelectElement,
    options: HTMLCollectionOf<HTMLOptionElement>,
    selectIdx: number,
  ) => {
    const optionsLength = selectEl.length;
    setSelectOptions(
      <>
        {[...new Array(optionsLength)].map((_, index) => {
          const optionEl = options[index];
          return (
            <div
              key={`${optionEl.value}-${index}`}
              role='button'
              className='select-option'
              hidden={optionEl.hidden}
              data-disabled={optionEl.disabled}
              onClick={() => {
                selectEl.selectedIndex = index;
                setSelectedIndex(index);
                setIsSelectOpen(false);
                if (saveData) saveData(optionEl.text);
                if (name)
                  setValue(name!, optionEl.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                if (onChange) onChange(optionEl.value);
              }}
            >
              <span data-selected={selectIdx === index}>
                {optionEl.innerText}
              </span>
            </div>
          );
        })}
        {optionsLength > 3 && <div className='select-option' data-disabled />}
      </>,
    );
  };
  const getSelectorTextComponent = () => {
    switch (type) {
      case JDSelectorType.ORDER:
        return (
          <StyledOrderSelector
            onClick={() => {
              if (disabled) return;
              setIsSelectOpen(true);
            }}
          >
            <span>{selectedText}</span>
            <StyledIconSVG fill={colors.JOBDA_BLACK}>
              <IconArrowUpDown />
            </StyledIconSVG>
          </StyledOrderSelector>
        );
      case JDSelectorType.NORMAL:
        return (
          <StyledNormalSelector
            className={
              !isSelectOpen
                ? `${errors[name] === undefined && !meta.invalid}`
                : `active ${errors[name] === undefined && !meta.invalid}`
            }
            onClick={(e) => {
              e.preventDefault();
              if (!disabled) setIsSelectOpen(true);
            }}
            data-disabled={disabled}
          >
            <input
              value={selectedText}
              data-selected={!!watch(name)}
              onClick={(e) => e.currentTarget.blur()}
            />
            <img alt='select 아이콘' src={IconDownArrow} />
          </StyledNormalSelector>
        );
      case JDSelectorType.BIG:
        return (
          <StyledBigSelector
            className={
                !isSelectOpen
                  ? `${errors[name] === undefined && !meta.invalid}`
                  : `active ${errors[name] === undefined && !meta.invalid}`
              }
            onClick={(e) => {
              e.preventDefault();
              if (!disabled) setIsSelectOpen(true);
            }}
            data-disabled={disabled}
          >
            <input
              value={selectedText}
              data-selected={!!watch(name)}
              onClick={(e) => e.currentTarget.blur()}
            />
            <img alt='select 아이콘' src={IconDownArrow} />
          </StyledBigSelector>
        );
      case JDSelectorType.MEDIUM:
        return (
          <StyledMediumSelector
            className={
              !isSelectOpen
                ? `${errors[name] === undefined && !meta.invalid}`
                : `active ${errors[name] === undefined && !meta.invalid}`
            }
            onClick={() => {
              if (!disabled) setIsSelectOpen(true);
            }}
            data-disabled={disabled}
          >
            <span>{selectedText}</span>
            <StyledIconSVG fill={colors.JOBDA_BLACK}>
              <IconArrowDownBig />
            </StyledIconSVG>
          </StyledMediumSelector>
        );
      case JDSelectorType.MEDIUMSMALL:
        return (
          <StyledMediumSmallSelector
            className={
                !isSelectOpen
                  ? `${errors[name] === undefined && !meta.invalid}`
                  : `active ${errors[name] === undefined && !meta.invalid}`
              }
            onClick={() => {
              if (!disabled) setIsSelectOpen(true);
            }}
            data-disabled={disabled}
          >
            <span>{selectedText}</span>
            <StyledIconSVG fill={colors.JOBDA_BLACK}>
              <IconArrowDown />
            </StyledIconSVG>
          </StyledMediumSmallSelector>
        );
      default:
        return (
          <StyledSmallSelector
            onClick={() => {
              if (disabled) return;
              setIsSelectOpen(true);
            }}
          >
            <span>{selectedText}</span>
            <StyledIconSVG fill={colors.CG_70}>
              <IconArrowDown />
            </StyledIconSVG>
          </StyledSmallSelector>
        );
    }
  };
  useEffect(() => {
    if (frameRef.current) {
      const currentValue = value ?? watch(name);

      const selectEl = frameRef.current.getElementsByTagName('select')[0];
      const options = selectEl.getElementsByTagName('option');
      const optionsArray = Array.from(options);
      const matchingOption = optionsArray.find((option) => option.value === currentValue);
      const optionText = matchingOption ? matchingOption.text : defaultText;

      const defaultIdx = options[selectEl.selectedIndex].dataset.clearOption
        ? 0
        : selectEl.selectedIndex;
      setSelectedIndex(defaultIdx);
      setOptionLength(options.length);
      if (!options[defaultIdx].value) {
        selectEl.classList.add('placeholder');
      } else {
        selectEl.classList.remove('placeholder');
      }
      if (!watch(name))setSelectedText(defaultIdx !== undefined ? options[defaultIdx].innerText : defaultText || selectTitle);
      else setSelectedText(optionText || '');

      generateSelectOptions(selectEl, options, defaultIdx);
    }
  }, [props.children, selectedIndex, value, watch(name)]);

  useEffect(() => {
    if (frameRef.current) {
      if (!value) {
        const selectEl = frameRef.current.getElementsByTagName('select')[0];
        selectEl.selectedIndex = 0;
        setSelectedIndex(0);
      } else {
        const selectEl = frameRef.current.getElementsByTagName('select')[0];
        const options = selectEl.getElementsByTagName('option');
        const findIdx = Array.from(options).findIndex(
          (item: HTMLOptionElement) => item.value === value,
        );
        if (findIdx !== -1) {
          options[findIdx].selected = true;
          setSelectedIndex(findIdx);
        }
      }
    }
    if (name) {
      setValue(name, value);
    }
  }, [value]);

  return (
    <Frame ref={frameRef} {...rest}>
      {props.children}
      <input style={{ display: 'none' }} ref={BaseRef} name={name} />
      {getSelectorTextComponent()}
      <JDUnderBaseModal
        isOpen={isSelectOpen}
        onClickClose={() => setIsSelectOpen(false)}
      >
        <StyledSelectOptions>
          <div className='option-title'>{selectTitle}</div>
          <div className='option-wrapper'>
            <div ref={snapWrapperRef} className='snap-wrapper'>
              <div className='grad-top' />
              {selectOptions}
              <div className='grad-bottom' />
            </div>
          </div>
        </StyledSelectOptions>
      </JDUnderBaseModal>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <h4 className='message false'>{message}</h4>}
      />
    </Frame>
  );
};

export default JDSelector;
