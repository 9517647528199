import { AxiosError } from 'axios';
import { IAccApplyDeleteRq } from 'interfaces/_v2/acc/IAccGate';
import { useMutation, useQuery } from 'react-query';
import { RemoteAccRepo } from 'repository/acc/AccRepo';

export const keys = ['v2', 'acc'];

const accRepo = new RemoteAccRepo();
const fetchAccDelete = async (rq:IAccApplyDeleteRq) => {
  const data = await accRepo.fetchAccDelete(rq);
  return data;
};

export function useAccDelete(onSuccess: () => void, onError?: (e: AxiosError) => void) {
  return useMutation((rq:IAccApplyDeleteRq) => fetchAccDelete(rq), {
    onSuccess,
    onError,
  });
}
