import { MatchApplyStatusTabType } from 'components/_v2/matchApply/matchApplyStatus/MatchApplyStatusBoard';
import { MatchTabType } from 'consts/_v2/match/MatchType';
import { serializeQueryString } from 'utils/CommonUtils';

export const RoutePaths = {
  root: '/',
  home: '/',
  error: '/error',
  calendar: '/jobs', // 채용 공고.
  jobflex_login: '/jobflex/login', // jobflex login -> UnavailableMobileOnly
  notice_update: '/notice/update', // 서비스 점검중
  notice_update_test: '/notice/update_test', // 서비스 점검중 테스트

  // 신버전
  position: '/position', // 포지션 리스트
  position_recommend: '/position/recommend', // 매칭 포지션 추천

  // 공고
  jobs: `/jobs`, // 공고 (카카오 주소복사 링크용)
  jobs_posting: (jobPostingSn: number | string = ':jobPostingSn') => `/jobs/${jobPostingSn}`, // 공고

  position_jd: (positionSn: number | string = ':positionSn', type?: string) => `/position/${positionSn}/jd${serializeQueryString({ type })}`, // 포지션 JD
  position_jd_preview: (publicKey?: string, positionId?: number) => `/position/jd/preview/${serializeQueryString({ publicKey, positionId })}`, // 매칭 포지션 JD 프리뷰 ATS에서 사용
  position_ats_preview: `/preview`, // ATS 포지션 JD 프리뷰
  challenge: '/challenge', // 챌린지
  challenge_detail: (challengeSn: number | string = ':challengeSn') => `/challenge/${challengeSn}`, // 챌린지 상세
  search_result: (keyword?: string) => `/search/result${serializeQueryString({ keyword })}`,

  // 내 프로필
  myprofile: '/myprofile', // 내 프로필
  profile: '/profile', // 신규 프로필
  profile_source: (sourcePage?: string) => `/profile?sourcePage=${sourcePage}`, // 신규 프로필
  profile_anchor: (anchor?:string) => `/profile?anchor=${anchor}`, // 프로필 anchor
  acca_results: (sn?:number) => (sn ? `/acca/results?sn=${sn}` : `/acca/results`), // 역량검사 결과
  phs_pacc_results: (sn?:number) => (sn ? `/phs/pacc/results?sn=${sn}` : '/phs/pacc/results'), // 개발자(역량검사) 검사 결과
  phs_pst_results: (sn?:number) => (sn ? `/phs/pacc/results?sn=${sn}` : '/phs/pst/results'), // 개발자(구현능력) 검사 결과
  phs_dchamps_results: (sn?:number) => (sn ? `/phs/pacc/results?sn=${sn}` : '/phs/dchamps/results'), // 개발자(디챔스)) 검사 결과
  acca_result_save: '/acca/result/save', // ACCA 역량검사 결과 저장 페이지 => PC에서만 가능으로 Redirect

  // 잡다 매치
  match: '/match', // redirect to mypage/apply
  match_apply: '/match/apply', // 잡다매치
  match_apply_status: '/match/apply/status', // 잡다매치현황
  match_apply_status_tab: (tab?: MatchApplyStatusTabType, sn?: number) => `/match/apply/status${serializeQueryString({ tab, sn })}`, // 잡다매치현황
  // PC 에서만 가능
  unavailableMobileOnly: '/unavailable-mobile-only', // PC에서만 페이지 (PC만 가능 Text)

  login: '/login', // 로그인
  join: '/join', // 회원가입
  matchLogin: '/match/login', // 수동인재 연동 로그인 페이지
  findAccount: (page?: string) => (page ? `/find/account/${page}` : `/find/account/:page`),
  deleteAccount: '/delete/account', // 회원탈퇴 페이지

  notification: '/notification', // 알람

  acca_agree: '/acca-agree', // 역량검사 연동 동의

  // 역량을 잡다
  acc_gate: '/acc/gate', // 역량을 잡다
  acc_introduce: '/acc/introduce', // 역량을 잡다란
  acc_tutorial: '/acc/tutorial', // 역량검사 수련(체험)
  acc_result: '/acc/result', // 역량검사 실전

  //* * 역량을 잡다 리디렉션용 */
  acc_result_test: '/acc/result/test',
  acc_result_competency: '/acc/result/competency',

  //* * 마이 페이지 */
  mypage: '/mypage',
  mypage_basic: '/mypage/basic', //* * 기본정보 */
  mypage_account: '/mypage/account', //* * 계정정보 */
  mypage_partnership: '/mypage/premium', //* * 제휴서비스 */
  mypage_partnership_list: (list?:boolean) => `/mypage/premium/${serializeQueryString({ list })}`, //* * 제휴서비스 */
  mypage_notice: '/mypage/notice', //* * 알림관리 */
  mypage_apply: '/mypage/apply', //* * 지원현황 */
  mypage_applying: '/mypage/applying', //* * 작성중인 지원서 */
  mypage_bookmark: '/mypage/bookmark', //* * 북마크 */
  mypage_apply_Message: (sn: number) => `/mypage/apply/${serializeQueryString({ sn })}`,
  // 리다이렉트
  match_redirect: (tab?: MatchTabType) => `/match/${serializeQueryString({ tab })}`,
  // 기업채널
  company_details: (companySn?: number) => (companySn ? `/company/${companySn}` : `/company/:companySn`),

  //* * 면접을 잡다 *
  pass_interview: '/pass/interview', // 온라인 면접 연습
  pass_interview_history: '/pass/interview/history', // 면접 연습 내역

  //* * 취업 콘텐츠 */
  info: '/info/list',
  info_category: (categorySn?:number) => `/info/list?category=${categorySn}`,
  info_detail: (infoSn: string | number = ':infoSn') => `/info/${infoSn}`,

  // 잡다 챌린지 이벤트 페이지
  jobda_challenge_event: '/event/challenge', // 잡다 챌린지 이벤트 페이지

  // 잡다 디챔스 이벤트 페이지
  jobda_dchamps_event: '/event/dchamps2309', // 잡다 디챔스 이벤트 페이지

  // 추석 이벤트
  jobda_chuseok_event: '/event/holiday2309', // 잡다 추석 이벤트 페이지

  // 프로필 이벤트
  jobda_profile_event: '/event/profile', // 잡다 추석 이벤트 페이지

  // 프로필 이메일 이벤트
  jobda_mail_profile_event: '/event/mailProfile', // 잡다 프로필 이메일 이벤트

  // 타임어택 기획매칭_마케터
  timeattack_marketer: '/event/timeattack_marketer', // 타임어택 기획매칭_마케터
  timeattack_marketer_detail: (userSn: number | string = ':userSn') => `/event/timeattack_marketer/${userSn}`,

  //* * 푸터 */
  jobda_declaration: '/declaration', // 잡다 선언문
  jobda_faq: '/jobdafaq', // 잡다 faq
  match_introduce: '/match/introduce', // 매칭 소개 페이지
  // 역량 센터
  acca_test: '/acca/test',
  phs_test: '/phs/test',
  acca_introcue: '/acca/introduce', // 역량 센터 소개
  acca_sample_result: '/acca/sampleResult', // 역량검사 샘플 결과

  //* * 취업준비 */
  preparation: '/preparation', // 취업 준비

  //* * 빌더 로그인 */
  builder_login: (positionSn?:number) => `/builder/login/${serializeQueryString({ positionSn })}`, // 빌더 로그인

  //* * hidden page */
  hiddenPageMain: '/hidden',
  hiddenPage: (page?: string) => (page ? `/hidden/${page}` : '/hidden/:page'),

  notFound: '/notFound',

  privacyNotice: (noticeSn?: number) => (noticeSn ? `/notice/${noticeSn}` : '/notice/:noticeSn'), // 사전안내문
  privacy: '/privacy', // 개인정보처리방침
  marketingPolicy: '/marketingPolicy', // 마케팅 수신 동의 상세
  thirdpartyAgreement: '/thirdparty-agreement', // 지원서 제3자동의

  version: '/version',
  passboard: '/passboard',
  passboard_new: '/passboard/new',
  passboard_detail: (sn: number | string = ':sn') => `/passboard/${sn}`,
  passboard_edit: (sn: number | string = ':sn') => `/passboard/${sn}/edit`,

  // 이벤트
  event: '/event',

  // 채팅
  chat: '/chat',
  chatList: '/chat/list',
  chatSearch: '/chat/search',
  chatChannel: '/chat/channel',

  update_birthdate: '/update-birthdate', // 만 15세 미만 생년월일 수정 페이지
  update_account: '/update-account', // 계정정보 수정 페이지

  oauth2_callback_kakao: '/oauth2/callback/kakao', // 카카오 로그인 콜백

  roulette: '/roulette', // 룰렛 이벤트 페이지
};

export const getInfoPreTestPreparationGuidePage = () => `https://www.jobda.im/info/328`;

export const RoutePathsFlexibleNavigation = [];

export const RoutePathsNeedNudge = [
  RoutePaths.root,
];

export const spinnerIgnoredURIs: Partial<Record<keyof typeof RoutePaths, Array<any>>> = {};

export default RoutePaths;
