import React from 'react';
// import JDALink from 'components/JDALink';
// import RoutePaths from 'consts/RoutePaths';
import JDSwiper, { JDSwiperSlide, PageNationType } from 'components/_v2/_common/JDSwiper';
import classnames from 'classnames/bind';
import styles from './resumeStyle.module.scss';

const cx = classnames.bind(styles);

const Resume = () => (
  <div className={cx('contentInner', 'bottomBox', 'bgDiff')}>
    {/* 이미지 슬라이드 제목 */}
    <div className={cx('slideSubText')}>
      <div className={cx('icon', 'folder')} />
      <span className={cx('text')}>어디 잘 쓴 이력서 하나 없냐고요?</span>
      <span className={cx('desc')}>여기 15개나 있습니다!</span>
      <div className={cx('star')} />
    </div>
    <div className={cx('basicTitle')}>
      <p className={cx('subTitle')}>실제 합격자의 이력서와<br />인사담당자의 꿀팁이 담긴</p>
      <p className={cx('mainTitle')}>합격자 이력서 모음.ZIP</p>
    </div>

    {/* 이미지 슬라이드 */}
    <div className={cx('slideInner')}>
      <JDSwiper className='service-swiper' paginationType={PageNationType.bullets} autoplay>
        <JDSwiperSlide className='service-card'>
          <div className={cx('sliderItem', 'type1')} />
        </JDSwiperSlide>
        <JDSwiperSlide className='service-card'>
          <div className={cx('sliderItem', 'type2')} />
        </JDSwiperSlide>
      </JDSwiper>
    </div>
  </div>
);

export default Resume;
