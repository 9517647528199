import { LazyImageLogo } from 'components/common/LazyImage';
import { Divider1G40 } from 'components/divider/Divider';
import JDPopover from 'components/_v2/_common/popover/JDPopover';
import RoutePaths from 'consts/RoutePaths';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox } from 'consts/_v2/_common/style/mixins';
import IJobsProps from 'interfaces/_v2/jobs/IJobsProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import JobsModel from 'models/_v2/JobsModel';
import React, { FC, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { DateFormatYYYYMMDDCCHHMMUnit, getDDayString, overDateNow, str2DateTime } from 'utils/DateUtils';
import Icon from '__designkit__/icon/Icon';
import Login from 'models/Login';
import Context from 'models/Context';
import CalendarModel from 'models/_v2/CalendarModel';

const Frame = styled.div`
  padding: 12px 16px 0;

  .align-div {
    display: flex;
    position: relative;

    .img {
      width: 56px;
      height: 56px;
      min-width: 56px;
      margin-right: 18px;
      border: 1px solid 'rgba(68, 71, 75, 0.2)';
    }

    .position-name {
      width: calc(100vw - 155px);
      text-align: left;
      margin-top: 12px;
      font: ${Fonts.H5_Bold};
      ${lineClampBox(2)};
    }
  }

  .tag-view-frame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    font: ${Fonts.B3_Medium};
    color: ${Colors.CG_70};
  }

  .d-day-frame {
    padding: 4px 6px;
    border-radius: 4px;
    margin-left: 8px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: ${Colors.JOBDA_BLACK};
    span {
      display: inline-block;
      font: ${Fonts.B4_Medium};
      color: ${Colors.WHITE_100};
      width: max-content;
    }
  }

  .align-div.center {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .title {
      font: ${Fonts.B3_Medium};
      color: ${Colors.CG_80};
      margin-right: 12px;
    }

    .content {
      font: ${Fonts.B3_Medium};
      color: ${Colors.JOBDA_BLACK};
    }
  }

  .bookmark {
    position: absolute;
    top: 16px;
    right: 0;
  }
`;

const Divider = styled(Divider1G40)`
  margin: 24px 0;
`;

const PopOverFrame = styled.div`
  color: ${Colors.WHITE_100};
  > h1 {
    font: ${Fonts.B3_Bold};
  }
`;

interface IJobsPostingInfoProps extends IJobsProps {
  jobPostingSn: number;
  calendarModel?: CalendarModel;
}

const JobsPostingInfo: FC<IJobsPostingInfoProps> = ({ jobsModel = new JobsModel(), context = new Context(), login = new Login(), calendarModel = new CalendarModel(), jobPostingSn }) => {
  const { jobPosting, tagList } = jobsModel;
  const [bookmark, setBookmark] = useState<boolean>(jobsModel.jobPosting ? jobsModel.jobPosting?.bookmarkYn : false);
  const history = useHistory();
  const { pathname } = useLocation();
  const handleBookmark = async () => {
    if (!login.userInfo) {
      context.setRedirectUrl(pathname);
      history.push(RoutePaths.login);
    } else {
      if (!bookmark) await jobsModel.saveJobPostingsBookmark(jobPostingSn);
      else await jobsModel.deleteJobPostingsBookmark(jobPostingSn);
      setBookmark(!bookmark);
      calendarModel.updateBookmarkYn(jobPostingSn);
    }
  };

  return (
    <Frame>
      <div className='align-div'>
        <LazyImageLogo src={jobPosting?.companyLogoUrl || ''} className='img' />

        <JDPopover useOutsideClick position='under' popoverMargin={30} anchorIcon={<div className='position-name'>{jobPosting?.name}</div>}>
          <PopOverFrame>
            <h1>{jobPosting?.name || 'name'}</h1>
          </PopOverFrame>
        </JDPopover>
        <div role='presentation' className='bookmark' onClick={handleBookmark}>
          {bookmark ? <Icon name='bookmark-filled' size={32} color={Colors.G_150} /> : <Icon name='bookmark' size={32} />}
        </div>
      </div>
      <div className='tag-view-frame'>
        <div className='tags'>{tagList.map((tag) => `#${tag} `)}</div>
      </div>
      <Divider />
      <div className='align-div center'>
        <div className='title'>기업</div>
        <Link to={RoutePaths.company_details(jobsModel.jobPosting?.companySn)}>
          <span className='content'>{jobPosting?.companyName}</span>
        </Link>
      </div>
      <div className='align-div center'>
        <div className='title'>진행</div>
        <span className='content'>{jobPosting?.postStartDateTime && jobPosting?.postEndDateTime ? `${DateFormatYYYYMMDDCCHHMMUnit(jobPosting?.postStartDateTime)} ~ ${DateFormatYYYYMMDDCCHHMMUnit(jobPosting?.postEndDateTime)}` : '상시 채용'}</span>
        {jobPosting?.postEndDateTime && !overDateNow(jobPosting?.postEndDateTime) && (
          <div className='d-day-frame'>
            <span>{getDDayString(str2DateTime(jobPosting?.postEndDateTime || ''))}</span>
          </div>
        )}
      </div>
    </Frame>
  );
};

export default inject(injectStore.jobsModel, injectStore.context, injectStore.login, injectStore.calendarModel)(observer(JobsPostingInfo));
