/* eslint-disable array-callback-return */
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDBottomFixedButton from 'components/_v2/_common/button/JDBottomFixedButton';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import ProfileAdditionInfoForm from 'components/_v2/positionJd/ProfileAdditionInfoForm';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import { ResumeFormType } from 'consts/_v2/profile/ProfileType';
import useToast from 'hooks/useToast';
import { IResumeAdditionInfoTemp } from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import { inject, observer } from 'mobx-react';
import PositionJdModel from 'models/_v2/PositionJdModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';
import AdditionalMoveDirtyModal from '../profile/AdditionalMoveDirtyModal';

const Frame = styled.div`
    padding: 32px 16px 154px;
`;

const TiTle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    >span {
        font: ${Fonts.H5_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-left: 4px;
    }
`;
interface IProfileAdditionInfoProps {
    handleClose: () => void;
    positionJdModel?: PositionJdModel;
    useFormed: UseFormMethods<IResumeAdditionInfoTemp>;
}
const ProfileAdditionInfoModal: FC<IProfileAdditionInfoProps> = ({ handleClose, positionJdModel = new PositionJdModel(), useFormed }) => {
  const { formState, setValue, getValues, reset, setError, errors, clearErrors, watch } = useFormed;
  const [isLoading, setIsLoading] = useState(true);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const { setToastObject } = useToast();
  const closeModal = () => {
    if (positionJdModel.isDirty) {
      setIsDirtyModalOpen(true);
    } else handleClose();
    reset();
  };

  const closeDirtyModal = () => {
    setIsDirtyModalOpen(false);
    handleClose();
    reset();
  };

  // temp로 저장했던 값들 reload
  useEffect(() => {
    setIsLoading(true);
    if (positionJdModel.tempAdditionalInfo && positionJdModel.tempAdditionalInfo.additionInfo) {
      positionJdModel.tempAdditionalInfo.additionInfo.forEach((info, idx) => {
        if (info?.answer) setValue(`additionInfo[${idx}].answer`, info.answer);
        if (info?.choices || info?.files) {
          reset({ additionInfo: positionJdModel.tempAdditionalInfo?.additionInfo });
        }
      });
    }
    setIsLoading(false);
  }, []);

  const checkValidation = () => {
    const formData = getValues();
    positionJdModel.tempAdditionalInfo = formData;
    if (positionJdModel.profileAdditionalInfo) {
      if (positionJdModel.profileAdditionalInfo.map((item, idx) => {
        if (item.required) {
          if (Object.keys(formData).length === 0 || (item.type === ResumeFormType.ADDITION_ATTACH_FILE && !formData.additionInfo[idx]?.files)) {
            setError(`additionInfo[${idx}].files`, { message: '내용을 입력해 주세요.' });
          } else {
            clearErrors(`additionInfo[${idx}].files`);
          }
        }
      }))
        positionJdModel.profileAdditionalInfo.map((item, idx) => {
          if (Object.keys(formData).length === 0) return;
          const choice = formData.additionInfo[idx]?.choices;
          const files = formData.additionInfo[idx]?.files;

          if (item.required) {
            if (item.type === ResumeFormType.TEXT) {
              if (!formData.additionInfo[idx].answer) {
                setError(`additionInfo[${idx}].answer`, { message: '내용을 입력해 주세요.' });
              } else {
                clearErrors(`additionInfo[${idx}].answer`);
              }
            } else if (item.type === ResumeFormType.SINGLE_CHOICE || item.type === ResumeFormType.DROPDOWN) {
              if (!formData.additionInfo[idx].choices) {
                setError(`additionInfo[${idx}].choices`, { message: '내용을 선택해 주세요.' });
              } else {
                clearErrors(`additionInfo[${idx}].choices`);
              }
            } else if (item.type === ResumeFormType.MULTI_CHOICE) {
              if (Array.isArray(choice) && !choice.some((v) => v)) {
                setError(`additionInfo[${idx}].choices`, { message: '내용을 선택해 주세요.' });
              } else {
                clearErrors(`additionInfo[${idx}].choices`);
              }
            } else if (item.type === ResumeFormType.ADDITION_ATTACH_FILE) {
              if (!files) {
                setError(`additionInfo[${idx}].files`, { message: '첨부 파일을 추가해 주세요.' });
              } else {
                clearErrors(`additionInfo[${idx}].files`);
              }
            }
          }
        });
    }
    if (Object.keys(errors).length === 0) {
      positionJdModel.additionInfoValid = true;
      handleClose();
    } else {
      formState.isDirty = false;
      setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '필수 입력 항목을 입력해 주세요.', subMessage: undefined });
      positionJdModel.additionInfoValid = false;
    }
    positionJdModel.setIsDirty(false);
  };

  useEffect(() => {
    positionJdModel.setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <JDFullModal closeCustom handleClose={closeModal}>
      <Frame>
        <TiTle>
          <Icon name='file-add' size={32} />
          <span>추가정보</span>
        </TiTle>
        {
            !isLoading && positionJdModel?.profileAdditionalInfo && positionJdModel?.profileAdditionalInfo.map((item, idx) => (
              <ProfileAdditionInfoForm key={item.additionInfoSn} info={item} idx={idx} formState={formState} errors={errors} clearErrors={clearErrors} />
            ))
        }
      </Frame>
      <JDBottomFixedButton
        primaryLabel='저장'
        handleSuccess={checkValidation}
        defaultClick={closeModal}
        defaultLabel='이전'
      />
      <AdditionalMoveDirtyModal
        isOpen={isDirtyModalOpen}
        onSuccess={() => closeDirtyModal()}
        onClickClose={() => {
          setIsDirtyModalOpen(false);
          positionJdModel.setIsDirty(true);
        }}
      />
    </JDFullModal>
  );
};

export default inject(injectStore.positionJdModel)(observer(ProfileAdditionInfoModal));
