import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { DateUtil } from 'components/_v2/_common/input/datePicker/DateUtil';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import UtmEventQuery from 'utils/UtmEventQeury';

const StyledSignUpFrame = styled.div`
  display: flex;
  justify-content: center;

  .jobda-sign-up-frame {
    font: ${Fonts.B3_Medium};
    color: ${colors.JOBDA_BLACK};

    button {
      font: ${Fonts.B3_Bold};
      margin-left: 8px;
      text-decoration: underline;
    }
  }
`;

interface ISignUpProps {
  checkMatchLogin: () => void;
  context? : Context;
}

export const SignUpFrame: FC<ISignUpProps> = ({ checkMatchLogin, context = new Context() }) => {
  const [queryParams, setQueryParams] = useState<string>('');
  useEffect(() => {
    const addQuery = UtmEventQuery.find(({ incomingUrls, startDate, endDate }) => {
      if (!DateUtil.getWhetherIncludedInThePeriod(startDate, endDate)) return false;
      return incomingUrls.some((url) => context.previousPathList.includes(url));
    })?.queryString ?? {};
    if (
      addQuery.utm_campaign !== undefined
      && addQuery.utm_source !== undefined
      && addQuery.utm_medium !== undefined
    ) {
      const validQuery = {
        utm_source: addQuery.utm_source,
        utm_medium: addQuery.utm_medium,
        utm_campaign: addQuery.utm_campaign,
      };

      const tmpQueryParams = new URLSearchParams(validQuery).toString();
      setQueryParams(tmpQueryParams);
    }
  }, [context.previousPathList]);

  return (
    <StyledSignUpFrame>
      <div className='jobda-sign-up-frame'>
        계정이 없다면?
        <Link to={{ pathname: RoutePaths.join, search: queryParams }}>
          <button onClick={() => checkMatchLogin()}>회원가입</button>
        </Link>

      </div>
    </StyledSignUpFrame>
  );
};
export default inject(injectStore.context)(observer(SignUpFrame));
