import Icon from '__designkit__/icon/Icon';
import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import React from 'react';
import styles from './gateCard.module.scss';

const cx = classnames.bind(styles);

interface GateListProps {
  thumbnail: string;
  number: number;
  title: string;
  list1: string;
  list2: string;
  list3: string;
  link: string;
  button: string;
}

const GateCard = ({ thumbnail, number, title, list1, list2, list3, link, button }: GateListProps) => (
  <div className={cx('gateInner')}>
    <div className={cx('thumbnail')} style={{ backgroundImage: `url(${thumbnail})` }} />
    <div className={cx('listInner')}>
      <div className={cx('titleInner')}>
        <span className={cx('number')}>{number}</span>
        <span className={cx('title')}>{title}</span>
      </div>
      <ul>
        <li className={cx('list')}>
          <Icon name='check' size={20} />
          {list1}
        </li>
        <li className={cx('list')}>
          <Icon name='check' size={20} />
          {list2}
        </li>
        <li className={cx('list')}>
          <Icon name='check' size={20} />
          {list3}
        </li>
      </ul>
      <JDALink to={`https://www.jobda.im${link}`} className={cx('link','acca_intro_mLinks')}>
        {button}
      </JDALink>
    </div>
  </div>
);

export default GateCard;
