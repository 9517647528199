import React, { FC } from 'react';
import styled from 'styled-components';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import Fonts from '__designkit__/common/fonts';
import Colors from '__designkit__/common/colors';
import Shadows from '__designkit__/common/shadows';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { Divider1G40 } from 'components/divider/Divider';
import { Button, ButtonMode } from '__designkit__/button/Button';
import Icon from '__designkit__/icon/Icon';
import RoutePaths from 'consts/RoutePaths';
import { useHistory } from 'react-router';
import request from 'utils/request';
import URIs from 'consts/URIs';

interface ISignupCongratulateModalProps {
  isOpen: boolean;
  loginProcess: () => Promise<void>;
}

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: 470px;
  padding: 32px 20px 24px;
  border-radius: 12px;
  background-color: ${Colors.WHITE_100};
  filter: ${Shadows.Shadow_300};
`;

const Text = styled.div`
  text-align: center;
  font: ${Fonts.H4_Bold};
  white-space: pre-wrap;

  &.semi {
    color: ${Colors.CG_70};
    font: ${Fonts.B2_Medium_P};
  }

  &.link {
    padding-bottom: 1px;
    border-bottom: 1px solid ${Colors.CG_80};
    color: ${Colors.CG_80};
    font: ${Fonts.B3_Medium};
  }
`;

const BenefitList = styled.ul`
  width: 100%;
  height: fit-content;

  & > li:not(last-child) {
    margin-bottom: 4px;
  }
`;

const BenefitItem = styled.li`
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  font: ${Fonts.B2_Bold};

  & > div {
    margin-right: 12px;
  }
`;

const SignupCongratulateModal: FC<ISignupCongratulateModalProps> = ({ isOpen = false, loginProcess = () => new Promise(() => {}) }: ISignupCongratulateModalProps) => {
  const history = useHistory();

  const runLoginProcess = async (path?: string) => {
    await loginProcess();
    if (path) history.push(path);
  };

  const clickAlarm = async () => {
    try {
      await runLoginProcess();
      await request({
        method: 'POST',
        url: URIs.post_profile_input_remind,
      });
      history.push('/');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <JDBaseModal
      dimmed
      isOpen={isOpen}
      onClickClose={() => {}}
    >
      <Frame>
        <Text>회원가입을 축하합니다!</Text>
        <SpacingBlock size={8} vertical />
        <Text className='semi'>프로필을 완성하시고 다양한 혜택을 받아보세요</Text>
        <SpacingBlock size={16} vertical />
        <Divider1G40 />
        <SpacingBlock size={16} vertical />
        <BenefitList>
          <BenefitItem>
            <Icon name='matching_person_green' size={40} />
            내 커리어를 깔끔하게 관리
          </BenefitItem>
          <BenefitItem>
            <Icon name='acca' size={40} />
            100만 취준생이 응시한 역량검사 결과 제공
          </BenefitItem>
          <BenefitItem>
            <Icon name='invitation-user' size={40} />
            내게 딱 맞는 기업으로부터 면접제안
          </BenefitItem>
          <BenefitItem>
            <Icon name='resume-done-green' size={40} />
            인사 담당자에게 적극 추천
          </BenefitItem>
        </BenefitList>
        <SpacingBlock size={20} vertical />
        <Button label='나중에 알림 받고 완성하기' buttonMode={ButtonMode.DEFAULT} outLined size='large' onClick={clickAlarm} />
        <SpacingBlock size={8} vertical />
        <Button label='1분만에 완성하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={() => runLoginProcess(RoutePaths.profile)} />
        <SpacingBlock size={16} vertical />
        <Text className='link' onClick={() => runLoginProcess('/')}>괜찮아요, 다음에 할게요</Text>
      </Frame>
    </JDBaseModal>
  );
};

export default SignupCongratulateModal;
