import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import JDFullModalHeader from 'components/_v2/_common/modals/JDFullModalComponents';
import ProfileExperience from 'components/_v2/profile/experience/ProfileExperience';
import IModalProps from 'interfaces/props/IModalProps';
import React, { FC } from 'react';

const ProfileExperienceEditModal: FC<IModalProps> = ({ isOpen, onClose }) => (
  !isOpen ? <></> : (
    <JDFullModal handleClose={onClose}>
      <JDFullModalHeader />
      <ProfileExperience />
    </JDFullModal>
  )
);

export default ProfileExperienceEditModal;
