import React from 'react';
import contentsImg1 from '__pc__/page/accaIntroduce/@assets/story1.png';
import contentsImg2 from '__pc__/page/accaIntroduce/@assets/story2.png';
import contentsImg3 from '__pc__/page/accaIntroduce/@assets/story3.png';
import Icon from '__designkit__/icon/Icon';
import classnames from 'classnames/bind';
import GateCard from './gateCard';
import styles from './gateBox.module.scss';

const cx = classnames.bind(styles);

interface GateBoxProps {
  fadeIn: boolean;
}

const GateBox = ({ fadeIn }: GateBoxProps) => (
  // 기업로고 리스트, 성공사례
  <div className={cx('sectionInner', { on: fadeIn })}>
    <p className={cx('mainTitle')}>
      <span className={cx('mainSmall')}>
        마치 게임처럼,
      </span>
      <br />
      쉽고 재미있게
      <br />
      취업 준비 하세요.
    </p>
    <span className={cx('subTitle')}>JOBDA 역량검사 응시, 전혀 어렵지 않아요!</span>
    <div className={cx('companyList')}>
      <GateCard
        thumbnail={contentsImg1}
        number={1}
        title='사전 가이드'
        list1='온라인 응시 환경'
        list2='응시 복장 안내'
        list3='응시 장소 안내'
        link='/info/328'
        button='사전 가이드 바로가기'
      />
      <GateCard
        thumbnail={contentsImg2}
        number={2}
        title='역량검사 A to Z'
        list1='역량검사 바로알기'
        list2='응시 과제별 소개 영상'
        list3='결과표 활용법'
        link='/info/344'
        button='역량검사 A to Z 바로가기'
      />
      <div className={cx('gateInner')}>
        <div className={cx('thumbnail')} style={{ backgroundImage: `url(${contentsImg3})` }} />
        <div className={cx('listInner')}>
          <div className={cx('titleInner')}>
            <span className={cx('number')}>3</span>
            <span className={cx('title')}>튜토리얼 연습</span>
          </div>
          <ul>
            <li className={cx('list')}>
              <Icon name='check' size={20} />
              실제와 동일한 과제
            </li>
            <li className={cx('list')}>
              <Icon name='check' size={20} />
              무제한 과제 연습
            </li>
            <li className={cx('list')}>
              <Icon name='check' size={20} />
              문항별 세부 피드백
            </li>
          </ul>
          <p className={cx('errorBox')}>
            <Icon name='error' size={20} />
            튜토리얼 연습은 PC에서만 가능합니다.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default GateBox;
