/* eslint-disable @typescript-eslint/no-shadow */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import ImgCelebrate from 'assets/img/img_celebrating_character_group.png';
import JDALink from 'components/JDALink';
import JDDescriptionInput from 'components/_v2/_common/input/JDDescriptionInput';
import JDDeleteModal from 'components/_v2/_common/modals/JDDeleteModal';
import { IShareFacebookParams } from 'components/_v2/_common/modals/ShareModal';
import PassboardCommentCard from 'components/_v2/passboard/PassboardCommentCard';
import PassboardDetailMoreList from 'components/_v2/passboard/PassboardDetailMoreList';
import { Divider12Grey, Divider1G } from 'components/divider/Divider';
import InfoSuggentionContent from 'components/info/InfoSuggentionContent';
import RoutePaths from 'consts/RoutePaths';
import { JDBaseInputMixin } from 'consts/_v2/_common/style/mixins';
import useToast from 'hooks/useToast';
import { PassReviewQuestionType } from 'interfaces/_v2/passReview/IPassReviewRqRs';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Info from 'models/Info';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { useDeletePassReviewPosts, usePassReviewCreateComments, usePassReviewLoadComments, usePassReviewPostDetail, usePassReviewPostQuestions } from 'query/passReview/usePassReveiwQuery';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import { DateFormatYYYYMMDDWithDot } from 'utils/DateUtils';
import { IShareKakaoTalkParams } from 'utils/KakaoUtils';

const ArticleFrame = styled.div`
  padding: 24px 16px 40px 16px;
`;
const HeaderFrame = styled.div`
    .title-text{
        font:${Fonts.H3_Bold};
        color:${Colors.JOBDA_BLACK};
    }
    .sub-frame{
        display: flex;
        align-items: center;
        margin-top: 16px;
        padding-bottom: 24px;

        .date-text{
          display: flex;
            font:${Fonts.B2_Medium_P};
            color:${Colors.CG_60};
            align-items: center;

        }
        .view-text{
          display: flex;
            font:${Fonts.B2_Medium_P};
            color:${Colors.CG_60};
            align-items: center;

        }
        .divider{
            width:1px;
            height:12px;
            background-color:${Colors.CG_40};
            margin:0 12px;
        
        }
    }
`;
const DividerFrame = styled.div`
  padding: 0 16px;
`;
const ImageCardFrame = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.CG_30};
  gap: 12px;
  border-radius: 8px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 40px;
  .image-card{
    display: flex;
    align-self: center;
    width: 240px;
    height: 240px;
  }
  .detail-card{
    display: flex;
    flex-direction: column;
    padding:24px;
    .title-font{
      margin-bottom: 12px;
      font:${Fonts.H4_Bold};
      color:${Colors.JOBDA_BLACK};
    }
    .sub-frame{
      display: flex;
      align-items: center;
      .sub-title{
        font:${Fonts.B2_Bold_P};
        color:${Colors.CG_70};
      }
      .sub-divider{
        width: 1px;
        height: 8px;
        margin:0 8px;
        background-color: ${Colors.CG_50};
      }
      .sub-detail{
        font:${Fonts.B2_Medium_P};
        color:${Colors.CG_70};  
      }
    }

  }
`;
const SpaceBetweenFrame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReviewBoxFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.CG_30};
  border-radius: 8px;
  margin-top: 48px;
  padding: 32px 0;

  .title-text{
    font:${Fonts.H5_Bold};
    color:${Colors.JOBDA_BLACK};
  }

  .sub-text {
    margin-top: 4px;
    font:${Fonts.B2_Medium};
    color:${Colors.CG_60};
  }

  .btnLink {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 115px;
    height: 38px;
    border-radius: 4px;
    background-color: ${Colors.JOBDA_BLACK};
    font:${Fonts.B2_Bold};
    color:${Colors.WHITE_100};
  }
`;

const SnsListFrame = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
  .ul-share {
      width:100%;
      height: fit-content;
      padding:24px 0 calc(env(safe-area-inset-bottom) + 24px);
      display:flex;
      flex-direction:row;
      justify-content:center;
      align-items:center;
      > *:not(:first-child) {
        margin-left:25px;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
      }
      .btn-share {
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        cursor:pointer;
      }
      .frame-label {
        margin-top:8px;
        font: ${Fonts.B2_Medium};
        text-align:center;
        color:${Colors.CG_80};
      }
    }
`;

const CommentListFrame = styled.div`
  margin: 40px 0 24px 0;

  .title {
    display: flex;
    gap: 8px;
    align-items: center;
    margin: 0 16px 4px 16px;
    font:${Fonts.B1_Bold};
    color:${Colors.JOBDA_BLACK};
    .count {
      color: ${Colors.G_150};
    }
  }

  .comment-list {
    .comment-item {
      padding: 12px 0;
      &.mine{
        background-color: ${Colors.JOBDA_WHITE};
      }
      .comment-header{
        display: flex;
        justify-content: space-between;
        margin: 0 16px;

        .comment-info {
        display: flex;
        margin-bottom: 8px;
        align-items: center;
        .comment-user {
          font:${Fonts.B2_Bold_P};
          color:${Colors.JOBDA_BLACK};

          &::after {
            content: '';
            display: inline-block;
            width: 1px;
            height: 8px;
            margin: 0 8px;
            background-color: ${Colors.CG_40};
          }
        }

        .comment-date {
          font:${Fonts.B2_Medium_P};
          color:${Colors.CG_60};
        }

      }
      }
     
      .comment-content {
          font:${Fonts.B2_Medium_P};
          color:${Colors.JOBDA_BLACK};
          margin: 0 16px;
        }
    
  }
}
  .comment-item-edit {
      padding: 12px 0;
      background-color: ${Colors.JOBDA_WHITE};
      .comment-header{
        margin:0 16px;
        display: flex;
        justify-content: space-between;
        margin: 0 16px;

        .comment-info {
        display: flex;
        margin-bottom: 8px;

        .comment-user {
          font:${Fonts.B2_Bold_P};
          color:${Colors.JOBDA_BLACK};

          &::after {
            content: '';
            display: inline-block;
            width: 1px;
            height: 8px;
            margin: 0 8px;
            background-color: ${Colors.CG_40};
          }
        }

        .comment-date {
          font:${Fonts.B2_Medium_P};
          margin: 0 16px;

          color:${Colors.CG_60};
        }

      }
      }
      .comment-frame{
        padding:0 16px;
        .comment-content {
        ${JDBaseInputMixin()};
        border: none;
      outline: none;
        border: 1px solid ${Colors.JOBDA_BLACK};
        border-radius: 4px;
        height: 76px;
        font:${Fonts.B2_Medium_P};
        color:${Colors.CG_90};
        padding: 16px;
        }
      }
      .register-frame{
    display: flex;
    justify-content: end;
      margin-right: 16px;
      margin-top: 8px;
    .register-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:8px 16px;
    background-color: ${Colors.JOBDA_BLACK};
    color:${Colors.WHITE_100};
    font:${Fonts.B3_Bold};
    border-radius: 4px;
  }
  .cancel-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:8px 16px;
    background-color: ${Colors.WHITE_100};
    color:${Colors.JOBDA_BLACK};
    font:${Fonts.B3_Bold};
    border-radius: 4px;
    border:1px solid ${Colors.JOBDA_BLACK};
    margin-right:8px;
  }
  }
    
  }
`;
const CommentCreateFrame = styled.div`
  padding:0 16px 40px 16px;
  .register-frame{
    display: flex;
    justify-content: end;
    .register-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:8px 16px;
    background-color: ${Colors.JOBDA_BLACK};
    color:${Colors.WHITE_100};
    font:${Fonts.B3_Bold};
    border-radius: 4px;
  }
  .register-button-disabled{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:8px 16px;
    background-color: ${Colors.CG_40};
    color:${Colors.CG_60};
    font:${Fonts.B3_Bold};
    border-radius: 4px;
  }
  }
  
`;
interface IPassboardDetail{
  context?: Context;
  login?:Login
  info?:Info
}

interface ItemProps {
  question: string;
  answer: string;
}
const QuestionFrame = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom:40px;
  .title-text{
    font:${Fonts.H5_Bold};
    color:${Colors.JOBDA_BLACK};
    margin-bottom: 12px;;
  }
  .sub-text{
    font:${Fonts.B1_Medium_P};
    color:${Colors.CG_70};
    white-space: pre-line;
  }
`;
const Item = ({ question, answer }: ItemProps) => {
  return (
    <QuestionFrame>
      <div className='title-text'>{question}</div>
      <div className='sub-text'>
        {answer}
      </div>
    </QuestionFrame>
  );
};

const ContentsFrame = styled.div`
  margin-top: 40px;
`;
const PassboardDetail:FC<IPassboardDetail> = ({ context = new Context(), login = new Login(), info = new Info() }) => {
  const { sn } = useParams<{ sn: string }>();
  const { data: passboardDetail, isLoading } = usePassReviewPostDetail(Number(sn));
  const { data: questionData } = usePassReviewPostQuestions({ type: 'DEFAULT' as PassReviewQuestionType });
  const { data: commentDetail, refetch } = usePassReviewLoadComments(Number(sn));
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState<boolean>(false);
  const saveSuccess = async () => {
    await refetch();
  };
  const { mutate: savePassReviewCreateComments } = usePassReviewCreateComments(saveSuccess);
  const saveDelete = async () => {
    history.push(RoutePaths.passboard);
  };
  const { mutate: deletePassReviewPost } = useDeletePassReviewPosts(saveDelete);
  const [kakaoData, setKakaoData] = useState<IShareKakaoTalkParams>({});
  const [facebookData, setFacebookData] = useState<IShareFacebookParams>({});
  const { pathname } = useLocation();
  const { setToastObject } = useToast();
  const formMethods = useForm({ mode: 'onSubmit' });
  const { watch, reset } = formMethods;
  const history = useHistory();

  useEffect(() => {
    const init = async () => {
      const env = process.env.REACT_APP_ENV;
      if (env === 'development') {
        await info.init(331);
      } else {
        await info.init(455);
      }
    };
    init();
  }, []);
  if (!passboardDetail) return null;

  const questionListEl = passboardDetail.answers.map((question) => {
    return <Item key={question.question.questionSn} question={question.question.question} answer={question.answer || ''} />;
  });
  const handleSaveButton = async () => {
    if (!login.userInfo) {
      context.setRedirectUrl(pathname);
      history.push(RoutePaths.login);
    }
    const rq = { sn: Number(sn), rq: { comment: watch('comment') } };
    await savePassReviewCreateComments(rq);
    reset({ comment: '' });
    await refetch();
  };

  const handleMoreButton = () => {
    setIsModalVisible(true);
  };
  const handleDelete = async () => {
    await deletePassReviewPost(Number(sn));
  };

  const handleEdit = async () => {
    history.push(RoutePaths.passboard_edit(sn));
  };
  return (
    <>
      {
      passboardDetail && (
        <>
          <ArticleFrame>
            <HeaderFrame>
              <div className='title-text'>{passboardDetail.title}</div>
              <SpaceBetweenFrame>
                <div className='sub-frame'>
                  <div className='date-text'><Icon name='calender' size={24} color={Colors.CG_60} /><SpacingBlock horizontal size={4} /> {DateFormatYYYYMMDDWithDot(passboardDetail.createdDateTime) }</div>
                  <div className='divider' />
                  <div className='view-text'><Icon name='eye-open' size={24} color={Colors.CG_60} /> <SpacingBlock horizontal size={4} />{passboardDetail.views}</div>
                </div>
                {
                  passboardDetail.userPost && (
                    <button onClick={handleMoreButton}>
                      <Icon name='more-horizon' size={24} color={Colors.JOBDA_BLACK} />
                    </button>

                  )
                }
                <PassboardDetailMoreList isOpen={isModalVisible} onClickClose={() => setIsModalVisible(false)} afterDelete={() => setIsInfoModalVisible(true)} afterEdit={handleEdit} />

              </SpaceBetweenFrame>
            </HeaderFrame>
            <Divider1G />
            {
              passboardDetail.type === 'NOTICE'
                ? (
                  <ContentsFrame>
                    <div dangerouslySetInnerHTML={{ __html: passboardDetail.contents }} />
                  </ContentsFrame>
                )

                : (
                  <>
                    <ImageCardFrame>
                      <img src={ImgCelebrate} alt='img' className='image-card' />
                      <div className='detail-card'>
                        <div className='title-font'>합격을 축하합니다</div>
                        <div className='sub-frame'>
                          <div className='sub-title'>기업</div>
                          <div className='sub-divider' />
                          <div className='sub-detail'>{ passboardDetail.companyName}</div>
                        </div>
                        <div className='sub-frame'>
                          <div className='sub-title'>직군</div>
                          <div className='sub-divider' />
                          <div className='sub-detail'>{context.jobGroupsWithTitles.find((jobGroup) => jobGroup.code === passboardDetail.jobGroupCode)?.name }</div>
                        </div>
                        <div className='sub-frame'>
                          <div className='sub-title'>직무</div>
                          <div className='sub-divider' />
                          <div className='sub-detail'>{context.jobTitles[String(passboardDetail.jobGroupCode)].titles.find((jobTitle) => jobTitle.code === passboardDetail.jobTitleCode)?.name }</div>
                        </div>
                      </div>
                    </ImageCardFrame>

                    {/* 내용 내용 */}
                    {questionListEl}
                  </>
                )

            }

            {/* 후기 작성하기 */}
            <ReviewBoxFrame>
              <div className='title-text'>당신의 합격 스토리를 들려주세요!</div>
              <div className='sub-text'>후기 작성자 전원 네이버페이 제공</div>
              <JDALink to={RoutePaths.passboard_new} className='btnLink'>
                후기 작성하기
              </JDALink>
            </ReviewBoxFrame>
          </ArticleFrame>

          <Divider12Grey />

          {/* 댓글 */}
          <CommentListFrame>
            <div className='title'>
              댓글 <span className='count'>{commentDetail?.posts.length}</span>
            </div>
            {/* 리스트 */}
            {
              commentDetail?.posts.map((comment, idx) => (
                <>
                  <PassboardCommentCard comment={comment} sn={sn} />
                  {idx !== commentDetail.posts.length - 1
                  && (
                  <DividerFrame>
                    <Divider1G />
                  </DividerFrame>
                  )}
                </>
              ))
            }
          </CommentListFrame>
          <FormProvider {...formMethods}>
            <CommentCreateFrame>
              <JDDescriptionInput
                name={`comment`}
                placeholder='댓글을 입력해 주세요.'
                height={76}
                maxLength={300}
                autoFocus={false}
                onFocus={() => {
                  if (!login.userInfo) {
                    context.setRedirectUrl(pathname);
                    history.push(RoutePaths.login);
                  }
                }}
                onlyMax
              />
              {
                watch('comment') && watch('comment').length > 0
                  ? (
                    <div className='register-frame' onClick={handleSaveButton} role='button'>
                      <div className='register-button'>등록</div>
                    </div>
                  )
                  : (
                    <div className='register-frame'>
                      <div className='register-button-disabled'>등록</div>
                    </div>
                  )
              }

            </CommentCreateFrame>
            <InfoSuggentionContent passboard />
            <JDDeleteModal
              title='게시글을 삭제하시겠어요?'
              descriptions='삭제한 게시글은 복구할 수 없습니다.'
              dimmed
              isOpen={isInfoModalVisible}
              onClickClose={() => setIsInfoModalVisible(false)}
              handleClickLeft={() => setIsInfoModalVisible(false)}
              handleClickRight={() => handleDelete()}
              btnTextLeft='취소'
              btnTextRight='삭제'
              isDimClickClose
            />
          </FormProvider>

        </>
      )
    }
    </>
  );
};

export default inject(injectStore.context, injectStore.login, injectStore.info)(observer(PassboardDetail));
