/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-continue */
/* eslint-disable no-bitwise */
/* eslint-disable prefer-const */
type ERROR = true | string;
// const EMAIL_PATTERN = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const EMAIL_PATTERN = /^[a-z0-9._-]+@(?:[a-z0-9-]+\.)+[a-z]{2,10}$/;
const CELLPHONE_PATTERN = /(^01.{0})([0,1,6,7,8,9]{1})([0-9]{3}|[0-9]{4})([0-9]{4})/;
export const NAME_PATTERN = /^[a-zA-Z가-힣]*$/;
const PASSWORD_PATTERN = /^[a-zA-Z0-9\s=$<>`~'"‘’“”|?/!@#%^&*()_]*$/;
const ID_ANTI_PATTERN = /^[a-z0-9-_]*$/;
const NUMBER_ID_ANTI_PATTERN = /^[0-9-_]*$/;
const KEY_BOARD = 'qwertyuiop asdfghjkl zxcvbnm QWERTYUIOP ASDFGHJKL ZXCVBNM';
const SERIAL_CHAR = 'abcdefghijklmnopqrstuvwxyz ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const SERIAL_NUM = '01234567890';
const ONLY_NUMBER = /^[0-9]*$/;
export const NUMBER_POINT = /^[0-9]*\.?[0-9]*$/; // 소수점을 가진 숫자만
export const NUMBER_POINT_STR = `^[0-9]*\\.?[0-9]*$`; // 소수점을 가진 숫자만
export const NUMBER_GPA = /^[0-9]{0,1}(\.[0-9]{0,2})?$/; // GPA 정수 1자리 , 소수점 한자리
export const NUMBER_GPA_STR = `^[0-9]{0,1}(\\.[0-9]{0,2})?$`; // GPA 정수 1자리 , 소수점 한자리
export const NUMBER_PERCENTAGE = /^[0-9]{0,3}$/; // 3자리 정수
export const NUMBER_PERCENTAGE_STR = `^[0-9]{0,3}$`; // 3자리 정수
export const BIRTHDATE_PATTERN = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;

const hasSameChar = (value: string, sameCharLength: number) => { // 같은 값이 연속되어 있는지 찾는 함수
  let i; let len; let j; let t;
  if (value.length < sameCharLength) return true;
  i = value.length - sameCharLength + 1;
  while (i--) {
    t = value[i];
    len = i + sameCharLength;
    for (j = i + 1; j < len; j++)
      if (value[j] === t) continue;
      else break;
    if (j === len) return false;
  }
  return true;
};

export const scoreValidator = (score: string): ERROR => {
  if (score === '') return '숫자와 특수기호 (.)만 사용 가능합니다.';
  if (NUMBER_POINT.test(score) === false) return '숫자와 특수기호 (.)만 사용 가능합니다.';
  return true;
};

export const idValidator = (id: string): ERROR => {
  if (id === '') return true;
  if (id.length < 5) return `5~20자의 영문 소문자, 숫자와 특수기호 (-), (_)만 사용`;
  if (id.length > 20) return `5~20자의 영문 소문자, 숫자와 특수기호 (-), (_)만 사용`;
  if (!ID_ANTI_PATTERN.test(id)) return `5~20자의 영문 소문자, 숫자와 특수기호 (-), (_)만 사용`;
  return true;
};

export const birthDateValidator = (date: string): ERROR => {
  if (date === '') return '숫자와 특수기호 (-)만 사용 가능합니다. (ex 1900-01-01) ';
  if (BIRTHDATE_PATTERN.test(date) === false) return '숫자와 특수기호 (-)만 사용 가능합니다. (ex 1900-01-01)';
  return true;
};

export const birthDate15Validator = (date: string): boolean | string => {
  if (!date) return true; // date가 없는 경우에는 검증하지 않음

  const currentDate = new Date();
  const minimumDate = new Date(currentDate.getFullYear() - 15, currentDate.getMonth(), currentDate.getDate());

  const year = Number(date.substring(0, 4));
  const month = Number(date.substring(4, 6)) - 1; // JavaScript의 월은 0부터 시작
  const day = Number(date.substring(6, 8));
  const dob = new Date(year, month, day);
  if (dob > currentDate) {
    return '정확한 생년월일을 8자리로 입력해주세요';
  }
  if (dob > minimumDate) {
    return '만 15세 미만은 가입할 수 없어요.';
  }

  return true;
};

export const nameValidator = (name: string): ERROR => {
  if (name === '') return true;
  if (!NAME_PATTERN.test(name)) return `한글, 영문만 입력해 주세요.`;
  return true;
};

export const emailValidator = (email: string, my_email?: string): ERROR => {
  if (email === '') return true;
  if (!(EMAIL_PATTERN).test(email)) return '올바른 이메일 형식을 입력해 주세요.';
  if (my_email != null && email === my_email) return '본인 이메일은 등록되지 않습니다.';
  return true;
};

export const emailValidatorPartnerShip = (email: string, my_email?: string): ERROR => {
  if (email === '') return true;
  if (my_email != null && email === my_email) return '본인 이메일은 등록되지 않습니다.';
  return true;
};

export const cellphoneValidator = (cellphone: string): ERROR => {
  if (cellphone === '') return true;
  if (!CELLPHONE_PATTERN.test(cellphone)) return '올바른 휴대폰번호를 입력해 주세요.';
  if (cellphone.length > 11) return '올바른 휴대폰번호를 입력해 주세요.';
  return true;
};

export const passwordValidator = (password: string, id?: string, email?: string): ERROR => {
  // 비밀번호 존재 여부 검사
  if (password === '') return '비밀번호를 입력해 주세요';
  // 비밀번호 최소 길이 검사
  if (password.length < 8) return '8자 이상 입력해 주세요.';
  // 사용 불가 문자 검사
  if (password.search(/[\s=&<>\\'"|?/]+/) !== -1) {
    return '공백, =, &, <, >, \', ", |, ?, /는 사용할 수 없어요.';
  }
  // 2가지 이상 문자 종류 사용 검사
  const tNum = password.search(/[0-9]/g) + 1;
  const tEng = password.search(/[a-z]/gi) + 1;
  const tSpe = password.search(/[^a-z0-9]/gi) + 1;
  if ((!tNum && !tEng) || (!tEng && !tSpe) || (!tSpe && !tNum)) {
    return '영어 대·소문자, 숫자, 특수문자 중 2종류 이상 사용하세요.';
  }
  // 이메일 포함 여부 검사
  if (email) {
    if (password.search(email) !== -1) return '이메일은 비밀번호로 사용할 수 없어요.';
  }
  // 아이디 포함 여부 검사
  if (id) {
    if (password.search(id) !== -1) return '아이디는 비밀번호로 사용할 수 없어요.';
  }
  // 연속된 문자 검사
  for (let i = 0; i < password.length - 2; i++) {
    const t = password.substring(i, i + 3);
    const tR = t.split('').reverse().join('');

    if (~KEY_BOARD.indexOf(t) || ~KEY_BOARD.indexOf(tR)) {
      return '3자리 연속 키보드에 근접한 문자는 비밀번호로 적합하지 않습니다\n';
    }
    if (~SERIAL_NUM.indexOf(t) || ~SERIAL_NUM.indexOf(tR)) {
      return '3자리 이상 연속된 숫자는 비밀번호로 적합하지 않습니다.';
    }
    if (~SERIAL_CHAR.indexOf(t) || ~SERIAL_CHAR.indexOf(tR)) {
      return '3자리 이상 연속된 문자는 비밀번호로 적합하지 않습니다.';
    }
  }
  // 동일한 문자 길이 검사
  if (!hasSameChar(password, 3)) {
    return '동일한 문자는 3자리 이상 사용할 수 없습니다.';
  }
  // 모두 통과 시, 사용 가능
  return true;
};

export const rePasswordValidator = (rePassword: string, password: string): ERROR => {
  if (rePassword === '') return true;
  if (rePassword === password) return true;
  return '비밀번호가 일치하지 않아요.';
};

export const numberValidator = (data: string) => (ONLY_NUMBER.test(data));
export const numberIdValidator = (data: string) => (NUMBER_ID_ANTI_PATTERN.test(data));
export const numberPointValidator = (data: string) => (NUMBER_POINT.test(data));
export const passwordSimpleValidator = (data: string) => (PASSWORD_PATTERN.test(data));
export const numberGPAValidator = (data: string) => (NUMBER_GPA.test(data));
export const numberPercentageValidator = (data: string) => (NUMBER_PERCENTAGE.test(data));
