import IconInfo from 'assets/_v2/_common/icon_info.svg';
import JDALink from 'components/JDALink';
import { IconLogo } from 'consts/assets/icons/iconPages';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import IPositionJdProps from 'interfaces/_v2/positionJd/IPositionJdProps';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import PositionJdModel from 'models/_v2/PositionJdModel';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Icon from '__designkit__/icon/Icon';
import SupportMail from 'consts/_v2/GlobalText';

const Frame = styled.div`
  padding: 32px 16px;
`;

const InnerFrame = styled.div`
  padding: 24px;
  background: ${colors.JOBDA_WHITE};
  border-radius: 8px;
  .content-frame {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;

    img {
      margin-top: 2px;
      margin-right: 4px;
    }

    .desc {
      font: ${Fonts.B3_Medium_P};
      color: ${colors.CG_60};
    }
    .more-frame {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .button-frame {
        display: flex;
        align-items: center;

        > span {
          font: ${Fonts.B3_Medium};
          color: ${colors.JOBDA_BLACK};
          text-decoration: underline;
          text-underline-position: under;
        }
      }
    }
  }
  .info-desc {
    display: flex;
    font: ${Fonts.B3_Medium_P};
    color: ${colors.CG_80};
    overflow: hidden;
    flex-direction: column;
    word-break: break-all;
    .copy-right {
      margin-top: 8px;
      font: ${Fonts.B3_Medium_P};
      color: ${colors.CG_80};
    }
    .divider {
      width: 100%;
      border-top: 1px solid ${colors.CG_50};
      margin: 24px 0;
    }
    &.folded {
      margin-top: 0px;
      height: 0px;
    }
  }
`;
export enum PositionTypeAll {
  ATS = 'ATS',
  CMS = 'CMS',
  INSIGHT = 'INSIGHT',
  JOBS = 'JOBS',
}
interface IPositionJdEditRequest extends IPositionJdProps {
  type: PositionTypeAll;
}
const PositionJdEditRequest: FC<IPositionJdEditRequest> = ({ positionJdModel = new PositionJdModel(), type }) => {
  const [infoFolded, setInfoFolded] = useState<boolean>(true);

  return (
    <>
      <Frame>
        <InnerFrame>
          <IconLogo />
          <div className='content-frame'>
            <img src={IconInfo} alt='icon' />
            <div className='content'>
              <span className='desc'>{type === PositionTypeAll.ATS || type === PositionTypeAll.INSIGHT ? `기재된 채용정보는 자인원의 동의 없이 무단전재, 재배포 및 재가공할 수 없습니다. 또한 해당 정보는 구직활동 이외의 용도로 사용하실 수 없습니다.` : `기재된 채용정보는 구직활동 이외의 용도로 사용하실 수 없습니다.`}</span>
              <div className='more-frame'>
                <a className='link-text' href={`mailto:${SupportMail}`}>
                  <div role='presentation' className='button-frame'>
                    <span>채용정보 수정 요청</span>
                    <Icon name='arrow-right' size={24} />
                  </div>
                </a>
                <div role='button' onClick={() => setInfoFolded(!infoFolded)}>
                  {infoFolded ? <Icon name='arrow-bottom' size={24} /> : <Icon name='arrow-top' size={24} />}
                </div>
              </div>
            </div>
          </div>
          <div className={infoFolded ? 'folded info-desc' : 'info-desc'}>
            {type === PositionTypeAll.ATS || type === PositionTypeAll.INSIGHT ? (
              <>
                <div className='divider' />
                {`본 채용정보는 ${positionJdModel.companyInfo?.companyName}에서 제공한 자료를 바탕으로 자인원에서 수정, 편집하여 제작한 자인원의 자산입니다. 정보의 일부 내지 혹은 전부에 대하여 자인원의 동의 없이 무단전재 또는 재배포, 재가공 및 크롤링할 수 없습니다. 또한, 게재된 기업의 채용정보는 구직자의 구직활동 이외의 용도로 사용하실 수 없습니다. 자인원은 ${positionJdModel.companyInfo?.companyName}에서 게재한 자료에 대한 오류나 그 밖에 자인원이 가공하지 않은 정보의 내용에 대하여 어떠한 보장도 하지 않으며, 사용자가 이를 신뢰하여 취한 조치에 대해 어떠한 책임도 지지 않습니다.`}
                <div className='copy-right'>{`<저작권자 자인원. 무단 전재 및 재배포 금지>`}</div>
              </>
            ) : (
              <>
                <div className='divider' />
                {`본 채용의 모집 분야, 모집 기간 등의 채용 정보는 기업의 실제 채용정보와 상이할 수 있습니다.
기업에서 제공하는 정보와 상이한 내용에 대해 자인원에서는 어떠한 보장도 하지 않으며, 이에 대한 책임을 지지 않습니다.`}
              </>
            )}
          </div>
        </InnerFrame>
      </Frame>
    </>
  );
};

export default inject(injectStore.positionJdModel)(observer(PositionJdEditRequest));
