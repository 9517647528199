import graphLottie from '__designkit__/assets/lottie/lottie_graph_bar.json';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Emoji from '__designkit__/icon/Emoji';
import Icon from '__designkit__/icon/Icon';
import Lottie from 'lottie-react';
import React from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  & .title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: fit-content;

    & .sub {
      font: ${Fonts.B2_Medium};
      color: ${Colors.G_200};
    }

    & .main {
      font: ${Fonts.H4_Bold};
      color: ${Colors.CG_90};
    }
  }

  & .lottie {
    width: 50%;
  }

  & .description-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;

    & .description-item {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      height: fit-content;

      & .text {
        font: ${Fonts.B2_Medium};
        color: ${Colors.CG_90};
      }
    }
  }
`;

const Introduce = () => (
  <Frame>
    <div className='title'>
      <div className='sub'>내가 언제 입사했더라? 🤔</div>
      <div className='main'>
        잊어버린 경력도<br />
        1분 만에 불러올 수 있어요.
      </div>
    </div>
    <div className='lottie'>
      <Lottie animationData={graphLottie} />
    </div>
    <div className='description-list'>
      <div className='description-item'>
        <Emoji name='nhis-logo' size={32} />
        <div className='text'>건강보험공단에서 경력, 소득 정보를 불러와요.</div>
      </div>
      <div className='description-item'>
        <Icon name='resume-done-green' size={32} />
        <div className='text'>근무했던 모든 회사의 경력을 확인할 수 있어요.</div>
      </div>
      <div className='description-item'>
        <Icon name='matching_person_green' size={32} />
        <div className='text'>원하는 경력만 선택해서 등록할 수 있어요.</div>
      </div>
    </div>
  </Frame>
);

export default Introduce;
