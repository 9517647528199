import { LocationCode } from 'consts/CompanyLocationType';
import { PositionRecruitmentTypeText } from 'consts/PositionType';
import URIs from 'consts/URIs';
import { IJobPostingRs } from 'interfaces/_v2/jobs/IJobPostingRs';
import { action, observable } from 'mobx';
import { request } from 'utils/request';

export default class JobsModel {
  @observable jobPosting: IJobPostingRs | null = null;
  @observable tagList: string[] = [];
  @observable jobPostingListSn: number[] = [];
  @observable listSnIndex: number = -1;
  @observable postingScale: number = 1;

    @action
    init = () => {
      this.jobPosting = null;
      this.tagList = [];
      this.postingScale = 1;
    }

    @action
    loadJobPostingsDetail = async (jobPostingSn: number) => {
      try {
        this.jobPosting = await request<IJobPostingRs>({
          method: 'get',
          url: URIs.get_job_postings(jobPostingSn),
        });

        this.jobPosting.positions.map((position) => {
          this.tagList.push(PositionRecruitmentTypeText[position.recruitmentType]);
        });

        this.jobPosting.positions.map((position) => {
          this.tagList.push(LocationCode(position.locationCode));
        });
        this.tagList = this.tagList.filter((v: string, i: number) => this.tagList.indexOf(v) === i);

        // list sn
        this.listSnIndex = this.jobPostingListSn.findIndex((v) => v === jobPostingSn);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    nextJobPosting = () => {
      if (this.listSnIndex === -1) return -1;
      if (this.listSnIndex < this.jobPostingListSn.length) {
        return this.jobPostingListSn[this.listSnIndex + 1] ? this.jobPostingListSn[this.listSnIndex + 1] : -1;
      }
      return -1;
    };

    @action
    beforeJobPosting = () => {
      if (this.listSnIndex === -1) return -1;
      if (this.listSnIndex > 0) {
        return this.jobPostingListSn[this.listSnIndex - 1] ? this.jobPostingListSn[this.listSnIndex - 1] : -1;
      }
      return -1;
    };

    @action
    saveJobPostingsBookmark = async (jobPostingSn: number) => {
      const res = (await request({
        method: 'put',
        url: URIs.put_job_postings_bookmark(jobPostingSn),
      }));
      return res;
    }

    @action
    deleteJobPostingsBookmark = async (jobPostingSn: number) => {
      const res = (await request({
        method: 'delete',
        url: URIs.delete_job_postings_bookmark(jobPostingSn),
      }));
      return res;
    }
}
