import React, { Fragment } from 'react';

import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import JDSwiper, {
  JDSwiperSlide,
  PageNationType,
} from 'components/_v2/_common/JDSwiper';
import styles from './companyList.module.scss';
import { CompanyListData, companyListData } from './companyListData';

const cx = classnames.bind(styles);

const CompanyList = () => {
  // 1차원 배열(데이터)를 2차원 배열로 변환
  // list: 1차원 배열 / size: 아이템 갯수
  const transform2dArray = (list: CompanyListData[], size: number) => {
    const arr: CompanyListData[][] = [];
    for (let i = 0; i < list.length; i += size) {
      arr.push(list.slice(i, i + size));
    }
    return arr;
  };

  const companyListEl = transform2dArray(companyListData, 5).map(
    (item, index) => (
      <JDSwiperSlide className="service-card">
        <Fragment key={index}>
          {item.map((innerItem, innerIndex) => (
            <div key={innerIndex} className={cx('dataInner')}>
              <div className={cx('dataWrap', innerItem.logoType)}>
                <div className={cx('infoArea')}>
                  <div className={cx('companyName')}>
                    {innerItem.companyName}
                  </div>
                  <div className={cx('job')}>{innerItem.job}</div>
                </div>
              </div>

              <JDALink
                to={RoutePaths.challenge_detail(9)}
                className={cx(
                  'link',
                  `dch_landing_recruit${index * 5 + innerIndex + 1}`,
                )}
              >
                지원하기
              </JDALink>
            </div>
          ))}
        </Fragment>
      </JDSwiperSlide>
    ),
  );

  return (
    <div className={cx('wrap')}>
      <div className={cx('inner')}>
        <div className={cx('sectionTitleArea')}>
          <strong className={cx('sectionTitle')}>
            D:CHAMPS
            <br />
            응시 완료한 분들께
            <br />
            기업 인사담당자님이
            <br />
            <span className={cx('emphasis')}>직접 면접을 제안 드려요!</span>
          </strong>
        </div>

        <p className={cx('sectionDesc')}>
          이미 디챔스에 응시 완료하신 경우
          <br />
          '즉시 지원' 공고에도 지원이 가능합니다.
          <br />
          지원하기 버튼 클릭 시 공고 리스트로 이동합니다.
        </p>

        <div className={cx('companyListArea')}>
          <div className={cx('companyListInner')}>
            <JDSwiper
              className="service-swiper"
              paginationType={PageNationType.fraction}
              autoplay
            >
              {companyListEl}
            </JDSwiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyList;
