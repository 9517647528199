// 매칭 포지션 타입들

export enum PositionRecruitmentType {
    NEW = 'NEW',
    CAREER = 'CAREER',
    INTERNSHIP = 'INTERNSHIP',
    ANY = 'ANY',
}

export enum PositionRecruitmentTypeText {
    NEW = '신입',
    CAREER = '경력',
    INTERNSHIP = '인턴',
    ANY = '경력무관',
}
export enum PositionApplyType {
    ATS = 'ATS',
    INSIGHT = 'INSIGHT',
    CMS = 'CMS'
}

export enum JobApplicationProcessType {
  APPLY = 'APPLY',
  INTERVIEW_OFFERED = 'INTERVIEW_OFFERED',
  INTERVIEW_ACCEPTED = 'INTERVIEW_ACCEPTED',
  INTERVIEW_DENIED = 'INTERVIEW_DENIED',
  INTERVIEW_PASSED = 'INTERVIEW_PASSED',
  INTERVIEW_FAILED = 'INTERVIEW_FAILED',
  ACC_REQUEST = 'ACC_REQUEST',
  ACC_SUBMIT = 'ACC_SUBMIT',
  PHS_REQUEST = 'PHS_REQUEST',
  PHS_SUBMIT = 'PHS_SUBMIT',
  WRITING_RESUME = 'WRITING_RESUME', // 지원서 작성
}

export enum MatchingOfferStatusType {
    OFFER = 'OFFER',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
}

export enum JobApplicationProcessTypeText {
    APPLY = '입사 지원',
    INTERVIEW_OFFERED = '면접 제안이 왔어요! <strong>지원현황</strong>에서 확인해 보세요.',
    INTERVIEW_ACCEPTED = '면접 제안을 <strong>수락</strong>하였습니다. ',
    INTERVIEW_DENIED = '면접 제안을 <strong>거절</strong>하였습니다. ',
    INTERVIEW_PASSED = '최종 <strong>합격</strong>하였습니다. ',
    INTERVIEW_FAILED = '<strong>불합격</strong>하셨습니다. ',
}

export default PositionRecruitmentType;
