import { ReactSelectOption } from 'consts/_v2/_common/ReactSelectOptions';
import { MajorType } from 'interfaces/_v2/profile/IProfileEducation';
import { ExperienceForeignActivitiesType } from 'interfaces/_v2/profile/IProfileExperienceRqRs';
import { SkillLevel } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import { TeamType } from 'interfaces/_v2/profile/IProfileProjectRqRs';

export interface InsightResumeListDto{
    sn:number;
    companyName:string;
    positionName:string;
    submitDateTime:string;
}
export interface IInsightResumeListGetRs{
    resumes:InsightResumeListDto[]
}

export interface InsightResumeGetRs {
    education: BaseProfileEducationsDto
    career: BaseProfileCareersDto
    project: BaseProfileProjectsDto
    knowledgeAndSkill: BaseProfileKnowledgeAndSkillsDto
    experience: BaseProfileExperienceDto
    prize: BaseProfilePrizeDto
  }

export interface InsightResumeSaveRq{
    resumeSn: number;
    resumeData: InsightResumeGetRs;
  }

export interface InsightResumeGetVO {
    education: BaseProfileEducationsDto
    career: BaseProfileCareersDto
    project: BaseProfileProjectsVO
    knowledgeAndSkill: BaseProfileKnowledgeAndSkillsDto
    experience: BaseProfileExperienceVO
    prize: BaseProfilePrizeDto
  }
export interface BaseProfileExperienceVO {
    activities: BaseProfileActivityAndForeignDto[]
    foreign:BaseProfileActivityAndForeignDto[]
  }

export interface BaseProfileEducationsDto {
    educations: BaseProfileEducationDto[]
  }

export interface BaseProfileEducationDto {
    schoolLevel: string
    educationType: string
    transferYn: boolean
    startDate: string
    endDate: string
    schoolCode: number
    schoolName: string
    profileMajors: ProfileMajorDto[] | null
    profileResearches: ProfileResearchDto[] | null
  }

export interface ProfileMajorDto {
    majorType?: string
    majorCode?: number
    majorName?: string
    scoreType?: string
    score?: number
    perfectScore?: number
  }

export interface ProfileResearchDto {
    attachFiles?: AttachFile[]
    description?: string
  }

export interface AttachFile {
    fileUid: string
    fileName: string
    fileSize: number
    fileUrl: string
  }

export interface BaseProfileCareersDto {
    careers: BaseProfileCareerDto[]
  }

export interface BaseProfileCareerDto {
    sn: number
    companySn: number
    companyName: string
    employmentStatus: string
    startDate: string
    endDate: string
    jobGroupCode: number
    jobGroupName: string
    jobTitleCode: number
    jobTitleName: string
    employmentType: string
    performance: string
    role: string
    verified: boolean
  }

export interface BaseProfileProjectsDto {
    projects: BaseProfileProjectDto[]
  }
export interface BaseProfileProjectsVO {
    projects: BaseProfileProjectVO[]
  }

export interface BaseProfileProjectDto {
    title: string
    startDate: string
    endDate: string
    teamType: string
    contribution: number
    performance: string
    institution: string
    skills: BaseProfileSkillDto[]
  }
export interface BaseProfileProjectVO {
    title: string
    startDate: string
    endDate: string
    teamType: string
    contribution: ReactSelectOption | undefined;
    performance: string
    institution: string
    skills: BaseProfileSkillDto[]
  }

export interface BaseProfileSkillDto {
    code: number
    keyword: string
    level: SkillLevel
  }

export interface BaseProfileKnowledgeAndSkillsDto {
    licenses: BaseProfileLicenseDto[]
    examinations: BaseProfileExaminationDto[]
    skills: BaseProfileSkillDto[]
  }

export interface BaseProfileLicenseDto {
    code: number
    name: string
  }

export interface BaseProfileExaminationDto {
    code: number
    name: string
    gradeCode: number
    grade: string
    score: number
    gradeYn: boolean
    scoreYn: boolean
  }

export interface BaseProfileExperienceDto {
    activitiesAndForeignExperiences: BaseProfileActivityAndForeignDto[]
  }

export interface BaseProfileActivityAndForeignDto {
    tag: string
    code: number
    title: string
    startDate: string
    endDate: string
    description: string
  }

export interface BaseEtcExperienceDto {
    description: string
  }

export interface BaseProfilePrizeDto {
    prizes: BaseProfileCareerPrizeDto[]
  }

export interface BaseProfileCareerPrizeDto {
    institution: string
    name: string
    date: string
    description?: string
  }

export interface BaseProfilePreferentialDto {
    military: BaseProfileJobSearchMilitaryDto
    disability: BaseProfileJobSearchDisabilityDto
    veteran: BaseProfileJobSearchVeteranDto
  }

export interface BaseProfileJobSearchMilitaryDto {
    status: string
    type: string
    militaryClass: string
    dischargeType: string
    startDate: string
    endDate: string
  }

export interface BaseProfileJobSearchDisabilityDto {
    grade: string
    type: string
  }

export interface BaseProfileJobSearchVeteranDto {
    status: string
    reason: string
  }

export interface InsightResumeLogRq {
  action:string;
  actionSource:string
  actionComponent:string
  data:Object
}

export interface InsightResumeAutoSavedGetRs{
  autoSaved:boolean;
  auditCount:number;
  companyName:string
}
export const convertResumeData = (data: InsightResumeGetRs) => {
  const convertEducation = (educationData:BaseProfileEducationsDto) => ({
    educations: educationData.educations.map((education) => ({
      schoolLevel: education.schoolLevel,
      educationType: education.educationType,
      transferYn: education.transferYn,
      startDate: education.startDate,
      endDate: education.endDate,
      schoolCode: education.schoolCode,
      schoolName: education.schoolName,
      profileMajors: (education.profileMajors && education.profileMajors.length > 0) ? education.profileMajors : (
        education.schoolLevel !== 'HIGHSCHOOL' ? [{
          majorCode: undefined,
          majorName: undefined,
          majorType: MajorType.MAJOR,
          perfectScore: undefined,
          score: undefined,
          scoreType: 'SCORE',
        }] : []
      ),
      profileResearches: (education.profileResearches && education.profileResearches.length > 0) ? education.profileResearches : (
        education.schoolLevel !== 'HIGHSCHOOL' ? [{
          attachFiles: [],
          description: '',
        }] : []
      ),
    })),
  });
  const convertExperience = (experienceData:BaseProfileExperienceDto) => ({
    activities: experienceData.activitiesAndForeignExperiences.filter((activity) => activity.tag !== ExperienceForeignActivitiesType.OVERSEAS).map((activity) => ({
      tag: activity.tag,
      title: activity.title,
      code: activity.code,
      startDate: activity.startDate,
      endDate: activity.endDate,
      description: activity.description,
    })),
    foreign: experienceData.activitiesAndForeignExperiences.filter((activity) => activity.tag === ExperienceForeignActivitiesType.OVERSEAS).map((foreign) => ({
      tag: foreign.tag,
      title: foreign.title,
      code: foreign.code,
      startDate: foreign.startDate,
      endDate: foreign.endDate,
      description: foreign.description,
    })),
  });
  const convertProject = (projectData: BaseProfileProjectsDto): BaseProfileProjectsVO => ({
    projects: projectData.projects.map((project) => {
      const newContribution: ReactSelectOption = project.contribution ? { label: `${project.contribution}%`, value: project.contribution } : { label: '', value: 0 };
      return {
        title: project.title,
        startDate: project.startDate,
        endDate: project.endDate,
        teamType: project.teamType === TeamType.PERSONAL ? TeamType.PERSONAL : TeamType.TEAM,
        contribution: newContribution,
        performance: project.performance,
        institution: project.institution,
        skills: project.skills,
      };
    }),
  });

  return {
    education: convertEducation(data.education),
    career: data.career,
    project: convertProject(data.project),
    knowledgeAndSkill: data.knowledgeAndSkill,
    experience: convertExperience(data.experience),
    prize: data.prize,
  };
};
