import ContentCard from '__designkit__/card/ContentCard';
import Colors from '__designkit__/common/colors';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import RoutePaths from 'consts/RoutePaths';
import Fonts from '__designkit__/common/fonts';
import { inject, observer } from 'mobx-react';
import PaccGateModel from 'models/_v2/PaccGateModel';
import { injectStore } from 'models/store';
import { IPaccGateProps } from 'pages/_v2/acca/PaccGate';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  width:100%;
  padding-top: 32px;
  > h1 {
    font: ${Fonts.H5_Bold};
    color: ${Colors.JOBDA_WHITE};
    margin-bottom: 20px;
    margin-left: 16px;
  }

`;
const PaccContentsFrame = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  background: ${Colors.JOBDA_BLACK};
  .contents-custom{
    width:240px;
    margin-right: 16px;
    border-radius: 10px;
  }
  
`;

const PaccGateContents: FC<IPaccGateProps> = ({ paccGateModel = new PaccGateModel() }) => {
  useEffect(() => {
    const init = async () => {
      await paccGateModel.loadPhsCenterContents();
    };
    init();
  }, []);
  return (

    <Frame>
      <h1>디챔스에 관한 모든 콘텐츠 모았다!</h1>
      <PaccContentsFrame>
        {
          paccGateModel.contentsCard && paccGateModel.contentsCard.posts
          && (
          <JDSwiper
            slidesPerView='auto'
            slidesOffsetBefore={16}
            slidesOffsetAfter={16}
            navigation
            contents
            main={false}
          >
            {
                paccGateModel.contentsCard.posts.map((item) => (
                  <JDSwiperSlide key={item.sn} className='contents-custom'>
                    <ContentCard
                      darkMode
                      className='content-card'
                      item={{
                        image: item.thumbnailUrl,
                        bannerLink: RoutePaths.info_detail(item.sn),
                        category: item.categoryName,
                        title: item.title,
                        label: item.label,
                      }}
                    />
                  </JDSwiperSlide>
                ))
              }

          </JDSwiper>
          )
        }

      </PaccContentsFrame>
    </Frame>
  );
};

export default inject(injectStore.paccGateModel)(observer(PaccGateContents));
