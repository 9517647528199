import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import JDFullModalHeader from 'components/_v2/_common/modals/JDFullModalComponents';
import ProfilePrize from 'components/_v2/profile/prize/ProfilePrize';
import IModalProps from 'interfaces/props/IModalProps';
import React, { FC } from 'react';

const ProfilePrizeEditModal:FC<IModalProps> = ({ isOpen, onClose }) => (
  !isOpen ? <></> : (
    <JDFullModal handleClose={onClose}>
      <JDFullModalHeader />
      <ProfilePrize onClose={onClose} />
    </JDFullModal>
  )
);

export default ProfilePrizeEditModal;
