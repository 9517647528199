import { WeekdayText } from 'consts/WeekdayType';
import { DateTime } from 'luxon';

export const DATE_FORMAT_YYYY_MM_DD = 'yyyy-MM-dd';
export const DATE_FORMAT_YYYY_MM = 'yyyy-MM';
export const DATE_FORMAT_YYYY_MM_DD_HH_MM_SS = 'yyyy-MM-dd HH:mm:ss';
export const DATE_FORMAT_YYYY_MM_DD_DOT = 'yyyy.MM.dd';
export const DATE_FORMAT_YYYY_MM_DD_HH_MM_DOT = 'yyyy.MM.dd HH:mm';
export const DATE_FORMAT_YY_MM_DD_DOT = 'yy.MM.dd';
export const DATE_FORMAT_YYYY_MM_DOT = 'yyyy.MM';
export const DATE_FORMAT_YYYY_MM_DD_UNIT = 'yyyy년 MM월 dd일';
export const DATE_FORMAT_YYYY_MM_DD_HH_MM_UNIT = 'yyyy년 MM월 dd일 HH시 mm분';
export const DATE_FORMAT_YYYY_MM_DD_CC_HH_MM_UNIT = 'yyyy.MM.dd(c) HH:mm';
export const DATE_FORMAT_MM_DD_HH_MM_UNIT = 'MM월 dd일 HH시 mm분';
export const MM_DD_UNIT = 'MM월 dd일';
export const MM_DD_DOT = 'MM.dd';
export const MM_DD_DOT_SPACE = 'MM. dd';
export const DATE_FORMAT_YY_MM_DD_HH_MM_DOT = 'yy.MM.dd (HH:mm)';
export const DATE_FORMAT_HH_MM_SS = 'hh:mm:ss';
export const DATE_FORMAT_HH_MM = 'HH:mm';
export const DATE_FORMAT_CC = 'ccc';
export const str2DateTime = (dateTimeString: string, format: string | undefined = DATE_FORMAT_YYYY_MM_DD_HH_MM_SS): DateTime => {
  const dateTime = DateTime.fromFormat(dateTimeString, format);
  return dateTime.isValid ? dateTime : DateTime.fromISO(dateTimeString);
};

export const DateFormatYYMMDDDoWHHMMWithDot = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_YY_MM_DD_HH_MM_DOT)}`;
};

export const DateFormatYYMMDDWithDot = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_YY_MM_DD_DOT)}`;
};

export const DateFormatYYYYMMDD = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_YYYY_MM_DD)}`;
};

export const DateFormatYYYYMM = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_YYYY_MM)}`;
};

export const DateFormatHHMM = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_HH_MM)}`;
};

export const DateFormatYYYYMMDDWEEk = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_YY_MM_DD_DOT)} (${dateTime.weekdayShort})`;
};

export const DateFormatYYYYMMDDWithDot = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_YYYY_MM_DD_DOT)}`;
};

export const DateFormatYYYYMMDDHHMMWithDot = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_YYYY_MM_DD_HH_MM_DOT)}`;
};
export const DateFormatYYYYMMDDHHMMCCWithDot = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_YYYY_MM_DD_CC_HH_MM_UNIT)}`;
};
export const DateFormatYYMMWithDot = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_YYYY_MM_DOT)}`;
};

export const DateFormatYYMMDDUnit = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_YYYY_MM_DD_UNIT)}`;
};

export const DateFormatYYMMDDHHMMUnit = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_YYYY_MM_DD_HH_MM_UNIT)}`;
};

// 2022.10.25(월) 13:33
export const DateFormatYYYYMMDDCCHHMMUnit = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  const changeDateTime:string = dateTime.toFormat(DATE_FORMAT_CC);
  const weekday = WeekdayText[changeDateTime];
  return `${`${dateTime.toFormat(DATE_FORMAT_YYYY_MM_DD_DOT)}(${weekday}) ${dateTime.toFormat(DATE_FORMAT_HH_MM)}`}`;
};

// 10.24(월) 13:33
export const DateFormatMMDDCCHHMMUnit = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  const changeDateTime:string = dateTime.toFormat(DATE_FORMAT_CC);
  const weekday = WeekdayText[changeDateTime];
  return `${`${dateTime.toFormat(MM_DD_DOT)}(${weekday}) ${dateTime.toFormat(DATE_FORMAT_HH_MM)}`}`;
};

// 13:33
export const DateFormatHHMMUnit = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_HH_MM)}`;
};

// 10.24(월)
export const DateFormatMMDDCCUnit = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  const changeDateTime:string = dateTime.toFormat(DATE_FORMAT_CC);
  const weekday = WeekdayText[changeDateTime];
  return `${`${dateTime.toFormat(MM_DD_DOT_SPACE)}(${weekday})`}`;
};

export const DateFormatMMDDHHMMUnit = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(DATE_FORMAT_MM_DD_HH_MM_UNIT)}`;
};

export const DateFormatMMDDDot = (dateString: string) => {
  const dateTime = str2DateTime(dateString);
  return `${dateTime.toFormat(MM_DD_DOT)}`;
};

export const getDCount = (dateTime: DateTime | string): number => {
  if (typeof dateTime === 'string') dateTime = str2DateTime(dateTime);
  let remainDay = Math.floor(-dateTime.diffNow('days').days + 1);

  if (remainDay <= 0) {
    const maxToday = DateTime.fromObject({ hour: 0, minute: 0, second: 0 });
    let diffDays = dateTime.diff(maxToday, 'days').days;
    diffDays = diffDays % 1 !== 0 ? diffDays : diffDays + 1; // 딱 24시간으로 나눠 떨어지는 경우
    remainDay = Math.floor(-diffDays + 1);
  }
  return remainDay;
};

export const getDHour = (dateTime: DateTime | string) => {
  if (typeof dateTime === 'string') dateTime = str2DateTime(dateTime);
  const remainHours = Math.floor(-dateTime.diffNow('hours').hours);
  return remainHours;
};

export const getDMinutes = (dateTime: DateTime | string) => {
  if (typeof dateTime === 'string') dateTime = str2DateTime(dateTime);
  const remainMin = Math.floor(-dateTime.diffNow('minutes').minutes);
  return remainMin;
};

// 날짜가 지났으면 T
export const overDateNow = (endDate: DateTime | string) => {
  if (typeof endDate === 'string') endDate = str2DateTime(endDate);
  const time = endDate.diffNow().milliseconds;
  if (time < 0) return true;
  return false;
};

export const getDDayString = (dateTime: DateTime, endDateText?: string): string => {
  const dCount = getDCount(dateTime);
  switch (true) {
    case (dCount === 0):
      return 'D-day';
    case (dCount > 0):
      return endDateText || `D+${dCount}`;
    case (dCount < 0):
    default:
      return `D${dCount}`;
  }
};

export const isImminentDCount = (dateTime: DateTime, imminentDay: number): boolean => {
  const dCount = -getDCount(dateTime);
  return dCount <= imminentDay;
};

// ex) 오후 12:30
export const getCreatedDayTime = (createdDateTime: string) => {
  const time = DateFormatHHMM(createdDateTime);
  const hours = Number(time.split(':')[0]);
  const minutes = time.split(':')[1];

  if (hours > 12) {
    return `오후 ${hours - 12}:${minutes}`;
  }
  return `오전 ${hours}:${minutes}`;
};

// ex) 2021-10-24 오후 11:59
export const getFullTime = (createdDateTime: string) => {
  if (createdDateTime !== null) {
    const result = `${DateFormatYYYYMMDD(createdDateTime)} ${getCreatedDayTime(createdDateTime)}`;
    return result;
  }
  return createdDateTime;
};

// ex) 2021.10.24 오후 11:59
export const getFullTimeDot = (time: string) => {
  if (time !== null) {
    const result = `${DateFormatYYMMDDWithDot(time)} ${getCreatedDayTime(time)}`;
    return result;
  }
  return time;
};

export enum TimeType {
  BEFORE = 'BEFORE',
  ING = 'ING',
  END = 'END',
}

export const getPositionDday = (closingDateTime: string | undefined) => {
  if (!closingDateTime) return '상시채용';
  const dCount = getDCount(closingDateTime);
  if (dCount === 0) return `오늘마감`;
  if (dCount > 0) return `마감${dCount}일지남`;
  if (dCount < 0) return `마감${dCount * -1}일남음`;
  return '상시채용';
};

export const getChipDday = (closingDateTime: string | undefined) => {
  if (!closingDateTime) return '';
  const dCount = getDCount(closingDateTime);
  if (dCount >= 0) return -1;
  if (dCount < 0) return dCount;
  return '';
};

export const getBetweenTime = (startTime: string, endTime: string, endYn?: boolean) => {
  const startYn = overDateNow(startTime);
  const overDayYn = overDateNow(endTime);

  if (endYn) return TimeType.END;
  if (!startYn) return TimeType.BEFORE;
  if (!overDayYn) return TimeType.ING;
  return TimeType.END;
};

export const weeksKor = ['일', '월', '화', '수', '목', '금', '토'];

// Date -> yyyy-mm
export const DateDataFormatYYYYMM = (date: Date) => {
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2); // add leading zero and take last two digits
  const formattedDate = `${year}-${month}`;
  return formattedDate;
};

// Date -> yyyy-mm-dd
export const DateDataFormatYYYYMMDD = (date: Date) => {
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2); // add leading zero and take last two digits
  const day = (`0${date.getDate()}`).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

// Date -> 10.24(월)
export const DateDataFormatMMDDCCUnit = (date: Date) => {
  const formattedDate = date.toLocaleDateString('ko-KR', { day: '2-digit', month: '2-digit', weekday: 'short' });
  const formattedMonthDay = formattedDate.slice(0, 6);
  const formattedWeekday = formattedDate.slice(8);
  const result = `${formattedMonthDay}${formattedWeekday}`;
  return result;
};

export const isBeforeDateOnly = (date1: Date, date2: Date) => {
  const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
  return d1 < d2;
};

// 오늘 만 15세가 되는 날짜 (yyyy-mm-dd)
export const make15YearsOldDate = () => {
  const date = new Date();
  const year = date.getFullYear() - 15;
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

// 2024.04.22 (화)
export const formatDateWithDayOfWeek = (isoDateString: string): string => {
  const dateTime = DateTime.fromISO(isoDateString).setLocale('ko');

  if (!dateTime.isValid) {
    console.error('Invalid date:', dateTime.invalidExplanation);
    return '';
  }

  return `${dateTime.toFormat('yyyy.MM.dd')} (${dateTime.toFormat('ccc')})`;
};
