import { AccaMetaTag } from 'components/_v2/_common/meta/MetaTag';
import AccaGateCustomPosition from 'components/_v2/acca/gate/AccaGateCustomPosition';
import AccaGateInfoContent from 'components/_v2/acca/gate/AccaGateInfoContent';
import AccaGateRecruit from 'components/_v2/acca/gate/AccaGateRecruit';
import AccaGateService from 'components/_v2/acca/gate/AccaGateService';
import AccaGateSuccessContent from 'components/_v2/acca/gate/AccaGateSuccessContent';
import AccaGateLatestExam from 'components/_v2/acca/gate/AccaGateLatestExam';
import { CenterHomeType } from 'consts/_v2/CenterType';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import AccaGateModel from 'models/_v2/acca/AccaGateModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import CommonUtils from 'utils/CommonUtils';
import ScrollUtil from 'utils/ScrollUtil';
import AccaTab from 'components/_v2/acca/gate/AccaTab';
import AccaGateCompanyLatestExam from 'components/_v2/acca/gate/AccaGateCompanyLatestExam';

const Frame = styled.div`
  height: 100%;
`;

export interface IAccaGateProps {
  login?: Login;
  accaGateModel?: AccaGateModel;
  context?: Context;
}

const AccaGate: FC<IAccaGateProps> = ({ login = new Login(), context = new Context(), accaGateModel = new AccaGateModel() }) => {
  const { search } = useLocation();
  const { move } = CommonUtils.parseQuery(search);
  const recruitRef = useRef<HTMLDivElement>(null);

  const scrollToRef = async () => {
    const refOffSetTop = recruitRef.current?.offsetTop;

    if (refOffSetTop) {
      ScrollUtil.scrollTo(0, refOffSetTop ? refOffSetTop - 100 : 0);
    }
  };

  useEffect(() => {
    const init = async () => {
      context.hideHeader = false;
      context.centerHomeType = CenterHomeType.ACCA;
      await accaGateModel.init();
    };
    init();
  }, []);

  useEffect(() => {
    if (move === 'jobPostings') {
      scrollToRef();
    }
  }, [recruitRef.current]);

  return (
    <Frame>
      <AccaTab />
      <AccaMetaTag />
      <AccaGateService />
      { login.userInfo && (
        <>
          {/* <AccaGateCompanyLatestExam /> */}
          <AccaGateLatestExam />
          <AccaGateCustomPosition />
        </>
      )}
      <div ref={recruitRef}>
        <AccaGateRecruit />
      </div>
      <AccaGateInfoContent />
      <AccaGateSuccessContent />
    </Frame>
  );
};

export default inject(injectStore.login, injectStore.accaGateModel, injectStore.context)(observer(AccaGate));
