import axios from 'axios';
import URIs from 'consts/URIs';
import { PositionOrderType } from 'consts/_v2/position/PositionType';
import { ICompanyEmploymentRs } from 'interfaces/_v2/company/ICompanyEmployment';
import { ICompanyFinanceRs } from 'interfaces/_v2/company/ICompanyFinance';
import { ICompanyCultureRs, ICompanyEmployeeStoryRs, ICompanyHeaderRs, ICompanyInformationRs, ICompanyQuestionRs, ICompanySpecialtyRs, ICompanyThemeRs, ICompanyWelfareRs } from 'interfaces/_v2/company/ICompanyInformation';
import { ICompanyNewsRs } from 'interfaces/_v2/company/ICompanyNews';
import { ICompanySalaryRs } from 'interfaces/_v2/company/ICompanySalary';
import { IPositionListRq, IPositionListRs } from 'interfaces/_v2/positionList/IPositionList';
import { action, observable } from 'mobx';
import request from 'utils/request';

const SEARCH_INIT_PAGE = 0;
const SEARCH_INIT_SIZE = 25;

export default class CompanyModel {
  @observable companySn: number = 0;
    @observable companyInformation: ICompanyInformationRs | null = null;
    @observable companyEmployment: ICompanyEmploymentRs | null = null;
    @observable companySalary: ICompanySalaryRs | null = null;
    @observable companyFinance: ICompanyFinanceRs | null = null;
    @observable companyNews: ICompanyNewsRs | null = null;
    @observable companyPositions: IPositionListRs | null = null;
    @observable companyAdvantage: ICompanyCultureRs | null = null;
    @observable companyInterviewPracticesPooledQuestions: ICompanyQuestionRs | null = null;
    @observable companySpecialty: ICompanySpecialtyRs | null = null;
    @observable companyTheme:ICompanyThemeRs | null = null;
    @observable companyWelfare:ICompanyWelfareRs | null = null;
    @observable companyHeader:ICompanyHeaderRs | null = null;
    @observable companyEmployStories:ICompanyEmployeeStoryRs | null = null;
    @observable locationX: string = '';
    @observable locationY: string = '';
    @observable searchFilters: Partial<IPositionListRq> = {
      jobTitles: '', // 직무 코드
      recruitments: '', // 채용 형태
      minSalary: undefined, // 연봉
      maxSalary: undefined, // 연봉
      locations: '', // 지역
      matchingYn: false, // 매칭 여부
      orderType: PositionOrderType.POPULAR, // 정렬 형태
      keyword: '',
      bookmarkYn: false,
    }

    @action
    init = async (sn:number) => {
      this.companySn = sn;
      this.companyInformation = null;
      this.companyEmployment = null;
      this.companySalary = null;
      this.companyFinance = null;
      this.companyNews = null;
      this.companyPositions = null;
    }

    @action
    terminate = () => {
      this.companySn = 0;
      this.companyInformation = null;
      this.companyEmployment = null;
      this.companySalary = null;
      this.companyFinance = null;
      this.companyNews = null;
      this.companyPositions = null;
    }

  @action
  resetAll = async () => {
    this.companyPositions = null;
    this.searchFilters = {
      jobTitles: '', // 직무 코드
      recruitments: '', // 채용 형태
      minSalary: undefined, // 연봉
      maxSalary: undefined, // 연봉
      locations: '', // 지역
      matchingYn: false, // 매칭 여부
      orderType: PositionOrderType.LATEST, // 정렬 형태
      keyword: '',
    };
  }

  @action
  resetFilter = async () => {
    this.companyPositions = null;
    this.searchFilters = {
      jobTitles: '', // 직무 코드
      recruitments: '', // 채용 형태
      minSalary: undefined, // 연봉
      maxSalary: undefined, // 연봉
      locations: '', // 지역
      matchingYn: false, // 매칭 여부
      orderType: PositionOrderType.POPULAR, // 정렬 형태
      keyword: this.searchFilters.keyword,
    };
  }

    @action
    getLocation = async () => {
      try {
        await axios.get(`https://dapi.kakao.com/v2/local/search/address.json?query=${this.companyInformation?.companyDetailDto?.location}`,
          { headers: { Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_KEY}` } })
          .then((res) => {
            const location = res.data.documents[0];
            this.locationX = location.address.x;
            this.locationY = location.address.y;
          });
      } catch (e) {
        console.error(e);
      }
    };

    @action
    loadCompanyInformation = async () => {
      try {
        this.companyInformation = (await request({
          method: 'get',
          url: URIs.get_company_information(this.companySn),
        }));
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    @action
    loadCompanyEmployment = async () => {
      try {
        this.companyEmployment = (await request({
          method: 'get',
          url: URIs.get_company_employment(this.companySn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadCompanySalary = async () => {
      try {
        this.companySalary = (await request({
          method: 'get',
          url: URIs.get_company_salary(this.companySn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadCompanyFinance = async () => {
      try {
        this.companyFinance = (await request({
          method: 'get',
          url: URIs.get_company_finance(this.companySn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadCompanyNews = async () => {
      try {
        this.companyNews = (await request({
          method: 'get',
          url: URIs.get_company_news(this.companySn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadCompanyPositions = async () => {
      try {
        this.companyPositions = (await request<IPositionListRs>({
          method: 'get',
          url: URIs.get_company_position(this.companySn),
          params: {
            page: SEARCH_INIT_PAGE,
            size: SEARCH_INIT_SIZE,
            ...this.searchFilters,
          },
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadCompanyAdvantage = async () => {
      try {
        this.companyAdvantage = (await request<ICompanyCultureRs>({
          method: 'get',
          url: URIs.get_company_advantage(this.companySn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadCompanyInterviewPracticesPooledQuestions = async () => {
      try {
        this.companyInterviewPracticesPooledQuestions = (await request<ICompanyQuestionRs>({
          method: 'get',
          url: URIs.get_company_interview_practices_pooled_questions(this.companySn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadCompanySpecialty = async () => {
      try {
        this.companySpecialty = (await request<ICompanySpecialtyRs>({
          method: 'get',
          url: URIs.get_company_specialty(this.companySn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadCompanyWelfare = async () => {
      try {
        this.companyWelfare = (await request<ICompanyWelfareRs>({
          method: 'get',
          url: URIs.get_company_welfare(this.companySn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadCompanyTheme = async () => {
      try {
        this.companyTheme = (await request<ICompanyThemeRs>({
          method: 'get',
          url: URIs.get_company_themes(this.companySn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadCompanyHeader = async () => {
      try {
        this.companyHeader = (await request<ICompanyHeaderRs>({
          method: 'get',
          url: URIs.get_company_header(this.companySn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    loadCompanyEmployeeStories = async () => {
      try {
        this.companyEmployStories = (await request<ICompanyEmployeeStoryRs>({
          method: 'get',
          url: URIs.get_company_employee_stories(this.companySn),
        }));
      } catch (e) {
        console.error(e);
      }
    }

    @action
    likeCompany = async (companySn: number) => {
      try {
        await request({
          method: 'put',
          url: URIs.put_company_like(companySn),
        });
      } catch (e) {
        console.error(e);
      }
    }

    @action
    unlikeCompany = async (companySn: number) => {
      try {
        await request({
          method: 'delete',
          url: URIs.put_company_unlike(companySn),
        });
      } catch (e) {
        console.error(e);
      }
    }
}
