/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
import ImgDumy from 'assets/_v2/positionList/img_dumy.png';
import { Divider12Grey } from 'components/divider/Divider';
import Fonts from '__designkit__/common/fonts';
import { lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import colors from '__designkit__/common/colors';
import { inject, observer } from 'mobx-react';
import Main from 'models/_v2/Main';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import RoutePaths from 'consts/RoutePaths';
import { AccJobPostingItemDto } from 'interfaces/_v2/main/IAccPositions';
import JobsModel from 'models/_v2/JobsModel';
import { useHistory } from 'react-router';
import { DateFormatYYYYMMDDCCHHMMUnit, getDDayString, str2DateTime } from 'utils/DateUtils';
import Icon from '__designkit__/icon/Icon';
import JDSwiper, { JDSwiperSlide, PageNationType } from '../_common/JDSwiper';
import MainNoPosition from './MainNoPosition';

const RootFrame = styled.div`
    width:100%;
`;

const Frame = styled.div`
    padding: 0 16px;
`;

const TitleFrame = styled.div`
    display: flex;
    flex-direction: column;
    margin:32px 0 24px 0px;
    .title{
        font: ${Fonts.H5_Bold};
        color:${colors.JOBDA_BLACK};
        width: 288px;
        word-break: break-all;
    }
    .subtitle-frame{
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        .subtitle-text{
            font: ${Fonts.B3_Medium};
            color:${colors.CG_70};
        }
        .more-text{
        display: flex;
        align-items: center;
        font: ${Fonts.B3_Medium};
        color:${colors.JOBDA_BLACK};
    }
    }
    
`;

const MainAccaRecruitFrame = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    .pagination-bullets{
      margin: 12px 0 40px 0;
    }

    .main-custom-slide{
    width:calc(100vw - 32px);
    padding:0 16px;
  }
`;

const MainAccaRecruitCardFrame = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 24px ;
    border: 1px solid ${colors.CG_40};
    border-radius: 8px;
    background-color: ${colors.WHITE_100};
    margin-bottom: 12px;
    .logo-frame{
        display: flex;
        justify-content: flex-start;

        .logo-image{
            width:48px;
            height: 48px;
        }
        .title-frame{
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            .title{
                width: 60vw;
                margin-top: 2px;
                font: ${Fonts.B1_Bold};
                color:${colors.JOBDA_BLACK};
                ${lineClampOnlyOne()}

            }
            .subtitle{
                width: 60vw;
                margin-top: 8px;
                font: ${Fonts.B3_Medium};
                color:${colors.JOBDA_BLACK};
                ${lineClampOnlyOne()}
            }
        }
    }
    .date-frame{
        display: flex;
        margin-top: 12px;
        align-items: center;
        .date{
            font: ${Fonts.B3_Medium};
            color:${colors.CG_70};
        }
        .d-day-frame{
                display: flex;
                background-color: ${colors.JOBDA_WHITE};
                border:1px solid ${colors.CG_50};
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                padding: 4px 6px;
                margin-left: 8px;
           .d-day-text{
                font: ${Fonts.B4_Medium};
                color:${colors.CG_90};
        }
    }
    .all-day-frame{
      display: flex;
      height: 25px;
      background-color: ${colors.WHITE_100};
      border:1px solid ${colors.CG_50};
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      padding:0 7px;
      .d-day-text{
        font: ${Fonts.B4_Medium_P};
        color:${colors.CG_90};
      }
    }
    }
`;
interface IMainAccaRecruit {
  main?: Main
  jobsModel?: JobsModel;
}
const MainAccaRecruit:FC<IMainAccaRecruit> = ({ main = new Main(), jobsModel = new JobsModel() }) => {
  const [accPositions, setAccPositions] = useState<any[]>([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await main.loadAccPostings(24);
      setLoading(false);
    };
    init();
  }, []);

  useEffect(() => {
    if (main.accPositions && main.accPositions.length > 0) {
      const realDummy:any[] = [];
      let tmpDummy:any[] = [];
      main.accPositions.map((item, idx) => {
        if (idx % 3 === 0 && idx !== 0) {
          realDummy.push(tmpDummy);

          tmpDummy = [];
        }
        tmpDummy.push(item);
      });
      if (tmpDummy.length > 0) {
        realDummy.push(tmpDummy);
      }
      setAccPositions(realDummy);
    }
  }, [main.accPositions]);

  const MainAccaRecruitCard = (item:AccJobPostingItemDto) => (

    <MainAccaRecruitCardFrame onClick={(e) => {
      const sns = main.accPositions.map((v) => v.sn);
      jobsModel.jobPostingListSn = sns;
      history.push(RoutePaths.jobs_posting(item.sn));
      e.stopPropagation();
    }}
    >
      <div className='logo-frame'>
        <img src={`${item.logoImageUrl}?w=96&h=96` || ImgDumy} className='logo-image' alt='logo' onError={(e) => e.currentTarget.src = ImgDumy} />
        <div className='title-frame'>
          <div className='title'>
            {item.companyName}
          </div>
          <div className='subtitle'>
            {item.title}
          </div>
        </div>
      </div>
      <div className='date-frame'>
        {item.closingDateTime
        && (
        <div className='date'>
          {DateFormatYYYYMMDDCCHHMMUnit(item.closingDateTime)}
        </div>
        )}

        {item.closingDateTime
          ? (
            <div className='d-day-frame'>
              <div className='d-day-text'>{getDDayString(str2DateTime(item.closingDateTime))}</div>
            </div>
          )
          : (
            <div className='all-day-frame'>
              <div className='d-day-text'>상시 채용</div>
            </div>
          )}

      </div>
    </MainAccaRecruitCardFrame>

  );
  return (
    <RootFrame>
      <Frame>
        <TitleFrame>
          <div className='title'>역량검사 활용 공고</div>
          <div className='subtitle-frame'>
            <div className='subtitle-text'>잡다에서 연습한 역량검사를 활용할 수 있어요.</div>
            <div onClick={() => history.push(RoutePaths.acca_test)} role='button' className='more-text'>전체보기 <Icon name='arrow-right' size={24} color={colors.JOBDA_BLACK} /></div>
          </div>

        </TitleFrame>
      </Frame>

      {accPositions && accPositions.length > 0
        ? (
          <MainAccaRecruitFrame>
            <JDSwiper
              slidesPerView={1}
              centeredSlides
              paginationType={PageNationType.bullets}
            >
              {accPositions && accPositions.length > 0
                && accPositions.map((item, idx) => (
                  <JDSwiperSlide className='main-custom-slide' key={item}>
                    {item.map((innerItem:AccJobPostingItemDto) => (
                      <>
                        { MainAccaRecruitCard(innerItem) }
                      </>
                    ))}

                  </JDSwiperSlide>
                ))}
            </JDSwiper>
          </MainAccaRecruitFrame>
        )
        : !loading ? <MainNoPosition /> : <></>}
    </RootFrame>
  );
};

export default inject(injectStore.main, injectStore.jobsModel)(observer(MainAccaRecruit));
