import { Button, ButtonMode } from '__designkit__/button/Button';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import NhisAPI from 'api/nhisAPI';
import ProfileAPI from 'api/profileAPI';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import NhisTermsModal from 'components/_v2/_common/terms/NhisTermsModal';
import CareerDetail from 'components/_v2/profile/careers/NHISModalContents/CareerDetail';
import CareerLoading from 'components/_v2/profile/careers/NHISModalContents/CareerLoading';
import CareerSelect from 'components/_v2/profile/careers/NHISModalContents/CareerSelect';
import Introduce from 'components/_v2/profile/careers/NHISModalContents/Introduce';
import Complete from 'components/_v2/profile/careers/NHISModalContents/Complete';
import VerificationForm from 'components/_v2/profile/careers/NHISModalContents/VerificationForm';
import VerificationWait from 'components/_v2/profile/careers/NHISModalContents/VerificationWait';
import { NHIS_ERROR_MESSAGE } from 'consts/_v2/profile/nhis';
import useToast from 'hooks/useToast';
import { ICompanyReport, IPostAuthRequestRq } from 'interfaces/_v2/profile/INhisRqRs';
import { INhisCareerRq } from 'interfaces/_v2/profile/IProfileCareerRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import { inject, observer } from 'mobx-react';
import ProfileCareersModel from 'models/_v2/profile/ProfileCareersModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const HeaderFrame = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  background-color: ${Colors.WHITE_100};
  font: ${Fonts.B1_Bold};
  color: ${Colors.JOBDA_BLACK};

  & .right-side {
    position: absolute;
    top: 50%;
    right: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    height: fit-content;
    transform: translateY(-50%);
  }
`;

const ContentsFrame = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 16px;
  overflow-y: auto;
`;

const ProgressBar = styled.div<{ percent: number }>`
  width: 100%;
  height: 3px;
  background-color: ${Colors.CG_30};

  & > div {
    width: 100%;
    height: 100%;
    background-color: ${Colors.JOBDA_BLACK};
    transform-origin: left; 
    transform: scaleX(${(props) => props.percent / 100}); 
    transition: transform 0.3s ease-in-out;
  }
`;

const BtnFrame = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: fit-content;
  padding: 16px;
`;

interface INHISCareerAddModalProps extends IComponentProps {
  onClickClose: () => void;
  afterLogic: () => void;
  profileCareersModel?: ProfileCareersModel;
}

const NHISCareerAddModal: FC<INHISCareerAddModalProps> = ({ onClickClose, afterLogic, profileCareersModel = new ProfileCareersModel() }) => {
  const { setToastObject } = useToast();
  const [step, setStep] = useState(0); // 0: 소개 및 시작, 1: 간편인증 요청, 2: 간편인증 대기, 3: 경력 불러오기 로딩, 4: 불러올 경력 선택하기, 5: 경력 필수값 채워넣기, 6: 경력 등록 완료
  const [persent] = useState([14, 28, 42, 56, 70, 84, 100]);
  const [isOpenTermModal, setIsOpenTermModal] = useState(false);
  const [tempVerificationData, setTempVerificationData] = useState<IPostAuthRequestRq>();
  const [tempCareerData, setTempCareerData] = useState<ICompanyReport[]>([]);
  const [selectedCareerData, setSelectedCareerData] = useState<ICompanyReport[]>([]);
  const [registerCareerList, setRegisterCareerList] = useState<(ICompanyReport & INhisCareerRq)[]>([]);

  const checkEssential = () => {
    const essentialList = registerCareerList.filter((career) => !career.jobGroupCode || !career.jobTitleCode || !career.employmentType);
    return essentialList.length > 0;
  };

  const checkVerificcation = async () => {
    try {
      await NhisAPI.postAuthCheck();
      setStep(3);
    } catch (error) {
      console.error(error);
      const e = error as { response: { data: { errorCode: string } } };
      setToastObject({ isOpen: true, type: 'ERROR', message: NHIS_ERROR_MESSAGE[`check_${e.response.data.errorCode}`], duration: 3000 });
    }
  };

  const registerCareer = async () => {
    try {
      await ProfileAPI.putNhisCareer({ careers: registerCareerList });
      setStep(6);
    } catch (error) {
      console.error(error);
      const e = error as { response: { data: { message: string } } };
      setToastObject({ isOpen: true, type: 'ERROR', message: e.response.data.message || '알 수 없는 오류로 실패했습니다. 잠시 후에 다시 시도해 주세요.', duration: 3000 });
    }
  };

  const onClickCompleteBtn = async () => {
    profileCareersModel.setCareers((await ProfileAPI.getCareers()).profileCareers);
    onClickClose();
  };

  useEffect(() => {
    setRegisterCareerList(selectedCareerData.map((career) => ({ ...career, jobGroupCode: undefined, jobTitleCode: undefined, employmentType: undefined })));
  }, [selectedCareerData]);

  return (
    <JDFullModal>
      <Frame>
        <HeaderFrame>
          경력 불러오기
          <div className='right-side'>
            <Icon name='close' size={32} color={Colors.JOBDA_BLACK} onClick={onClickClose} />
          </div>
        </HeaderFrame>
        <ContentsFrame>
          { step === 0 && <Introduce /> }
          { step === 1 && <VerificationForm stepSetter={setStep} tempSetter={setTempVerificationData} tempVerificationData={tempVerificationData} />}
          { step === 2 && <VerificationWait tempData={tempVerificationData} /> }
          { step === 3 && <CareerLoading name={tempVerificationData?.name} stepSetter={setStep} tempSetter={setTempCareerData} /> }
          { step === 4 && <CareerSelect careerData={tempCareerData} seletedSetter={setSelectedCareerData} /> }
          { step === 5 && <CareerDetail careerData={registerCareerList} registerCareerSetter={setRegisterCareerList} /> }
          { step === 6 && <Complete count={registerCareerList.length} /> }
        </ContentsFrame>
        { step !== 3 && (
          <ProgressBar percent={persent[step]}>
            <div />
          </ProgressBar>
        )}
        <BtnFrame>
          { step === 0 && <Button label='시작하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={() => setIsOpenTermModal(true)} /> }
          { step === 1 && (
            <>
              <Button label='이전' buttonMode={ButtonMode.DEFAULT} size='large' outLined onClick={() => setStep(0)} />
              <Button type='submit' label='인증 요청' buttonMode={ButtonMode.PRIMARY} size='large' form='verificationForm' />
            </>
          )}
          { step === 2 && (
            <>
              <Button label='이전' buttonMode={ButtonMode.DEFAULT} size='large' outLined onClick={() => setStep(1)} />
              <Button label='인증 완료' buttonMode={ButtonMode.PRIMARY} size='large' onClick={checkVerificcation} />
            </>
          )}
          { step === 4 && (
            tempCareerData.length > 0
              ? <Button label='불러오기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={() => setStep(5)} disabled={selectedCareerData.length === 0} />
              : <Button label='직접 추가하기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={() => { onClickClose(); afterLogic(); }} />
          )}
          { step === 5 && (
            <>
              <Button label='이전' buttonMode={ButtonMode.DEFAULT} size='large' outLined onClick={() => setStep(4)} />
              <Button label='경력 등록' buttonMode={ButtonMode.PRIMARY} size='large' onClick={registerCareer} disabled={checkEssential()} />
            </>
          )}
          { step === 6 && (
            <>
              <Button label='확인' buttonMode={ButtonMode.PRIMARY} size='large' onClick={onClickCompleteBtn} />
            </>
          )}
        </BtnFrame>
      </Frame>
      { isOpenTermModal && <NhisTermsModal onClickComplete={() => setStep(1)} onClickClose={() => setIsOpenTermModal(false)} /> }
    </JDFullModal>
  );
};

export default inject(injectStore.profileCareersModel)(observer(NHISCareerAddModal));
