import colors from '__designkit__/common/colors';
import JDALink from 'components/JDALink';
import { RequestUrlOption } from 'consts/URIs';
import useRequest from 'hooks/useRequest';
import { IAdMainBannerDto } from 'interfaces/_v2/main/IAdMainBanner';
import { inject, observer } from 'mobx-react';
import Main from 'models/_v2/Main';
import { injectStore } from 'models/store';
import useAdsImpressionQuery from 'query/ads/useAdsImpressionQuery';
import useAdsViewsQuery from 'query/ads/useAdsViewsQuery';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { usePassReviewBanners } from 'query/passReview/usePassReveiwQuery';
import JDSwiper, { JDFractions, JDSwiperSlide } from '../_common/JDSwiper';

const Skeleton = styled.div`
  width: calc(100vw - 32px);
  height: calc((100vw - 32px) / 2.175);
`;

const Frame = styled.div`
  width:100%;
  margin-top: 12px;
`;

export const PassboardBannerFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc((100vw - 32px) / 2.175);
  min-height: 160px;
  background: ${colors.WHITE_100};

  .main-custom-slide{
    width: 100vw;
    display: flex;
    justify-content: center;
  }
`;

export const PassboardBannerCardFrame = styled.div`
  position:relative;
  width: calc(100vw - 32px);
  height: calc((100vw - 32px) / 2.175);
  
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

const PassboardBanner = () => {
  const { data: passboardBanner, isLoading } = usePassReviewBanners();
  const MainAdCard = (item:IAdMainBannerDto) => (
    <JDALink to={item.bannerLink}>
      <PassboardBannerCardFrame>
        <img
          src={`${item.moBannerImageUrl}?w=${(window.innerWidth - 64) * 2}`}
          alt='test-img'
          // eslint-disable-next-line no-return-assign
          onError={(e) => e.currentTarget.src = 'https://picsum.photos/seed/picsum/343/160'}
        />
      </PassboardBannerCardFrame>
    </JDALink>
  );
  if (isLoading) return <Skeleton />;

  return (
    <Frame>
      {passboardBanner?.banners && passboardBanner.banners.length > 0 && (
        <PassboardBannerFrame>
          <JDSwiper
            slidesPerView={1}
            autoplay={{ delay: 3000 }}
            pagination={JDFractions}
            loop
            main
          >
            {
                   passboardBanner.banners.map((item) => (
                     <JDSwiperSlide key={item.moBannerImageUrl} className='main-custom-slide'>
                       {MainAdCard(item)}
                     </JDSwiperSlide>
                   ))
                 }

          </JDSwiper>
        </PassboardBannerFrame>
      )}
    </Frame>
  );
};

export default PassboardBanner;
