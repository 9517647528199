/* eslint-disable react-hooks/exhaustive-deps */
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import HeaderAlertIcon from 'components/_v2/_common/mainHeader/HeaderAlertIcon';
import ShareModal, { ShareButton } from 'components/_v2/_common/modals/ShareModal';
import DrawerNavigation from 'components/_v2/_common/navigation/DrawerNavigation';
import GlobalSearchModal from 'components/_v2/search/GlobalSearchModal';
import HambugerMenu from 'components/common/HambugerMenu';
import RoutePaths from 'consts/RoutePaths';
import { CHATTING_TODAY_RENDER_CHECK_STRING } from 'consts/_v2/chatting/chatting';
import { IconArrowBack, IconClose, IconLogo } from 'consts/assets/icons/iconPages';
import { absoluteHorizonCenter } from 'consts/style/mixins';
import shadows from 'consts/style/shadows';
import IContextPageProps from 'interfaces/props/IContextPageProps';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components/macro';
import ScrollUtil from 'utils/ScrollUtil';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import ImgLogo from 'assets/_v2/verification/icon_jobda_logo.png';
import { useInsightResumeLog } from 'query/resume/useResumeQuery';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';

export interface IMainHeaderProps extends ILoginPageProps, IContextPageProps {
  title?: string;
  backable?: boolean;
  onBack?: () => void;
  gnb?: boolean;
  share?: boolean;
  search?: boolean;
  close?: boolean;
  alarm?: boolean;
  onClose?: () => void;
  underShadow?: boolean;
  login?: Login;
  handleTitleClick?: () => void;
  shareDescription?: string;
  employment?: boolean;
  accCenter?:boolean;
  chat?: boolean;
}

export const MainHeaderFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: calc(56px);
  padding: 0px 12px 0px 16px;
  background: ${Colors.WHITE_100};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10002;
  transition: top 0.25s, background-color 0.25s;

  button {
    background: transparent;
  }

  .left-frame {
    display: flex;
    align-items: center;

    .btn-back {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 40px;
      height: 40px;
    }
  }
  .center-frame {
    ${absoluteHorizonCenter()};
    span {
      font: ${Fonts.B1_Bold};
      color: ${Colors.JOBDA_BLACK};
    }
  }
  .right-frame {
    display: flex;
    gap: 4px;
    
    .btn-search, .btn-menu, .btn-chatting {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
    }
  }

  &.shadow {
    ${shadows.headers};
  }
  &.blend-mode {
    box-shadow: none;
  }
  &.blend-mode-force {
    transition: top 0.25s;
  }
  .employment-home-label {
    display: flex;
    align-items: center;
    margin-left: 24px;
    span {
      flex: none;
      font: ${Fonts.B2_Medium};
      color: ${Colors.JOBDA_BLACK};
    }
  }
`;

const ChattingBeta = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;

`;
const AppInduceFrame = styled.div`
  width: 100%;
  height: 72px;
  padding: 12px 16px;
  justify-content: space-between;
  background-color: ${Colors.JOBDA_BLACK};
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  z-index: 10002;
  .left-frame{
    display: flex;
    gap:12px;
    align-items: center;
    .logo-frame{
      width: 48px;
      height: 48px;
      border-radius: 8px;
      background-color: ${Colors.WHITE_100};
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 6px;
    }
    .text-frame{
      font:${Fonts.B1_Bold};
      color:${Colors.WHITE_100};
    }
  }
`;

const MainHeader: FC<IMainHeaderProps> = ({ login = new Login(), title, search = true, gnb = true, backable = false, onBack, share = false, close = false, alarm = true, chat = false, onClose, underShadow = true, context = new Context(), handleTitleClick, shareDescription, ...props }) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const [shadow, setShadow] = useState<boolean>(false);
  const { active, color } = context.blendMode;
  const MainHeaderFrameStyle = active ? { background: color } : {};
  const loginRouteMatch = useRouteMatch(RoutePaths.login);
  const history = useHistory();
  const [isAppInduceBanner, setIsAppInduceBanner] = useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      const check = await TodayRenderCheckUtil.getTodayRenderCheck('appInduceBanner');
      if (!check && !MobileStore.isMobile) {
        setIsAppInduceBanner(true);
      }
    };
    init();
  }, []);

  const onBackBtnClickCallback = useCallback(() => {
    // 다른 페이지에서 redirect_url_suffix가 붙은채로 잡다 진입 후 뒤로가기 클릭시 해당 페이지로 이동
    if (context.otherPageRedirectUrl) {
      windowOpenUtils(context.otherPageRedirectUrl, '_self');
    }

    if (onBack) {
      return onBack();
    }
    if (context.previousPathList.length > 1) {
      return history.goBack();
    }
    return history.push('/');
  }, [history, loginRouteMatch]);

  const blendHandler = async () => {
    const scrollTop = await ScrollUtil.getScrollTop();
    if (scrollTop < 56) setShadow(false);
    else setShadow(underShadow && true);
    if (!context.blendMode.activeHandler) return;
    if (context.blendMode.activeForce || scrollTop < 56) {
      context.blendMode.active = true;
    } else {
      context.blendMode.active = false;
    }
  };

  useEffect(() => {
    ScrollUtil.addEventListener(blendHandler);
    return () => {
      ScrollUtil.removeEventListener(blendHandler);
    };
  }, []);

  useEffect(() => {
    if (!context.blendMode.activeHandler) {
      context.blendMode = {
        activeHandler: false,
        color: Colors.WHITE_100,
        active: false,
      };
    } else {
      blendHandler();
    }
  }, [context.blendMode.activeHandler]);
  const saveSuccess = () => {
  };
  const { mutate: saveResumeInduce } = useInsightResumeLog(saveSuccess);
  const { pathname } = useLocation();

  const onClickStoreBtn = async () => {
    const induceRq:InsightResumeLogRq = {
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: 'header_btn_move_store',
      data: { modalAction: 'click_to_store' },
    };
    saveResumeInduce(induceRq);
  };
  const onClickExitBtn = async () => {
    const induceRq:InsightResumeLogRq = {
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: 'header_btn_exit',
      data: { modalAction: 'click_exit' },
    };
    saveResumeInduce(induceRq);
  };

  return (
    <>
      <MainHeaderFrame className={`${context.blendMode.active ? 'blend-mode' : ''} ${shadow ? 'shadow' : ''} ${context?.blendMode.activeForce ? 'blend-mode-force' : ''}`} style={MainHeaderFrameStyle} data-hide={context.hideHeader} {...props}>
        <div className='left-frame'>
          { backable && (
          <button className='btn-back'>
            <IconArrowBack onClick={onBackBtnClickCallback} fill={(active && context?.blendMode.iconFill) || Colors.CG_80} />
          </button>
          )}
          { title === undefined && (
          <Link to={RoutePaths.home}>
            <IconLogo className='logo' fill={active ? context?.blendMode.iconFill : undefined} />
          </Link>
          )}
        </div>
        <div className='center-frame'>
          { title && <span role='presentation' onClick={() => handleTitleClick && handleTitleClick()}>{ title }</span> }
        </div>
        <div className='right-frame'>
          { search && (
          <button className='btn-search' onClick={() => setOpenSearch(true)}>
            <Icon name='search' size={32} className='icon-search' fill={(active && context?.blendMode.iconFill) || `${Colors.CG_80}`} />
          </button>
          )}
          { chat && (
          <ChattingBeta className='btn-chatting'>
            <Icon name='message' size={32} onClick={async () => { await TodayRenderCheckUtil.setTodayRenderCheck(CHATTING_TODAY_RENDER_CHECK_STRING.chattingInduction); history.push(RoutePaths.chat); }} />
          </ChattingBeta>
          )}
          { login.userInfo?.id && alarm && <HeaderAlertIcon /> }
          { share && <ShareButton className='btn-share' onClick={() => setOpenShare(true)} /> }
          { gnb && (
          <button className='btn-menu' onClick={() => setOpenDrawer(true)}>
            <HambugerMenu open={openDrawer} fill={active ? context?.blendMode.iconFill : undefined} />
          </button>
          )}
          { close && (
          <button className='btn-search' onClick={onClose || (() => history.goBack())}>
            <IconClose className='icon-search' />
          </button>
          )}
          <GlobalSearchModal isOpen={openSearch} onClickClose={() => setOpenSearch(false)} />
          <DrawerNavigation open={openDrawer} onClose={() => setOpenDrawer(false)} />
        </div>
        <ShareModal open={openShare} onClose={() => setOpenShare(false)} shareDescription={shareDescription} />
      </MainHeaderFrame>
      {
        isAppInduceBanner && (
        <AppInduceFrame onClick={(e) => {
          onClickStoreBtn();
          MobileStore.moveToStore();
          e.stopPropagation();
        }}
        >
          <div className='left-frame'>
            <div className='logo-frame'>
              <img src={ImgLogo} alt='logo' className='logo' />
            </div>
            <div className='text-frame'>취업 준비는 잡다 앱에서</div>
          </div>
          <button onClick={(e) => {
            TodayRenderCheckUtil.setTodayRenderCheck('appInduceBanner');
            setIsAppInduceBanner(false);
            onClickExitBtn();
            e.stopPropagation();
          }}
          >
            <Icon name='close' size={24} color={Colors.WHITE_100} />
          </button>
        </AppInduceFrame>
        )
      }

    </>
  );
};

export default inject(injectStore.login, injectStore.context)(observer(MainHeader));
