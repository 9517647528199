import React from 'react';
import Icon from '__designkit__/icon/Icon';
import Colors from '__designkit__/common/colors';
import JDSwiper, { JDSwiperSlide, PageNationType } from 'components/_v2/_common/JDSwiper';
import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import RoutePaths from 'consts/RoutePaths';
import styles from './index.module.scss';

const cx = classnames.bind(styles);

const Interviewee = () => (
  <div className={cx('commonBox')}>
    {/* 타이틀 */}
    <div className={cx('basicTitleInner')}>
      <span className={cx('basicTitle')}>JOBDA matching으로 기업에게 입사 제안 받고</span>
      <span className={cx('subTitle')}>
        합격한 선배들의 Story를
        <br />
        확인해보세요.
      </span>
    </div>

    <span className={cx('subText')}>* 이미지를 누르면 풀 스토리를 확인할 수 있어요.</span>

    {/* 이미지 슬라이드 */}
    <div className={cx('slideInner')}>
      <JDSwiper className='service-swiper' paginationType={PageNationType.bullets} autoplay>
        <JDSwiperSlide className='service-card'>
          <JDALink to={'https://www.jobda.im/info/466'} className={cx('interViewItem', 'item1')} />
        </JDSwiperSlide>
        <JDSwiperSlide className='service-card'>
          <JDALink to={'https://www.jobda.im/info/422'} className={cx('interViewItem', 'item2')} />
        </JDSwiperSlide>
        <JDSwiperSlide className='service-card'>
          <JDALink to={'https://www.jobda.im/info/459'} className={cx('interViewItem', 'item3')} />
        </JDSwiperSlide>
        <JDSwiperSlide className='service-card'>
          <JDALink to={'https://www.jobda.im/info/395'} className={cx('interViewItem', 'item4')} />
        </JDSwiperSlide>
      </JDSwiper>
    </div>

    <JDALink to={RoutePaths.passboard} className={cx('btnJoin')}>
      <span className={cx('title')}>취업 합격후기 풀버전 확인하기</span>
      <div className={cx('btnIcon')}>
        <Icon name='arrow-right' size={32} color={Colors.LG_100} />
      </div>
    </JDALink>
  </div>
);

export default Interviewee;
