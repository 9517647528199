import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { JDModalTitle } from 'components/_v2/_common/modals/JDModalComponents';
import ProfilePreviewModal from 'components/_v2/profile/preview/ProfilePreviewModal';
import IModalFrameProps from 'interfaces/IModalFrameProps';
import { IResumePreviewAdditionInfo } from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import { inject, observer } from 'mobx-react';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { overDateNow } from 'utils/DateUtils';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 312px;
  padding: 52px 20px 28px;
  border-radius: 12px;
  background-color: ${colors.WHITE_100};


  .preview-btn {
    margin-top: 12px;
  }
`;

const ContentFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px 16px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: ${colors.CG_30};

  .content {
    display: flex;

    span {
      font: ${Fonts.B3_Medium_P};
      color: ${colors.CG_90};

      >b {
        font: ${Fonts.B3_Bold_P};
      }
    }
   

    >div {
      margin-right: 4px;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

`;

interface IMatchAcceptModalProps extends IModalFrameProps {
  onClickApply: () => void;
  sendDateTime: string;
  profileModel?:ProfileModel;
  sender?:string;
  onClickViewProfile?: () => void;
}

const MatchAcceptModal:FC<IMatchAcceptModalProps> = (props) => {
  const { isOpen, onClickApply, onClickClose, sendDateTime, profileModel = new ProfileModel(), sender, onClickViewProfile } = props;
  const [isPreview, setIsPreview] = useState(false);
  const [previewAdditionInfo, setPreviewAdditionInfo] = useState<IResumePreviewAdditionInfo>({ additionInfo: [] });

  const viewPreview = async () => {
    await profileModel.loadProfileDetail();
    setIsPreview(true);
  };
  return (
    <JDBaseModal isOpen={isOpen} onClickClose={() => onClickClose && onClickClose()} isDimCloseBtn underFullModalZIndex>
      <Frame>
        <JDModalTitle>제안을 수락하시겠습니까?</JDModalTitle>
        <SpacingBlock size={16} vertical />
        <ContentFrame>
          <div className='content'><Icon name='check' size={16} /><span>현재 시점의 프로필이 제출되니,<br /> <b>프로필을 업데이트하고 수락해 주세요.</b></span></div>
          <div className='content'><Icon name='check' size={16} /><span>이 제안은 입사지원 제안으로,<br />수락 시 면접 대상자로 우선 검토돼요.</span></div>
          <div className='content'><Icon name='check' size={16} /><span>수락한 제안은 다시 취소할 수 없어요.</span></div>
        </ContentFrame>
        <Button label='수락하기' size='large' buttonMode={ButtonMode.PRIMARY} onClick={onClickApply} />
        {
          sender === 'CBO'
            ? <Button label='프로필 미리보기' size='large' outLined buttonMode={ButtonMode.DEFAULT} onClick={viewPreview} className='preview-btn' />
            : (sendDateTime > '2023-11-30T10:00:00') && <Button label='제안받은 프로필 확인하기' size='large' outLined buttonMode={ButtonMode.DEFAULT} onClick={onClickViewProfile} className='preview-btn' />
        }

      </Frame>
      {isPreview && profileModel.previewProfile && (
        <ProfilePreviewModal
          data={profileModel.previewProfile}
          additionInfo={previewAdditionInfo}
          handleClose={() => setIsPreview(false)}
        />
      )}
    </JDBaseModal>
  );
};
export default inject(injectStore.profileModel)(observer(MatchAcceptModal));
