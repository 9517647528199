import React, { useState } from 'react';
import styled from 'styled-components';
import ConfirmModal from 'components/_v2/_common/modals/ConfirmModal';
import { Button, ButtonMode } from '__designkit__/button/Button';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';

const Frame = styled.div<{height: number}>`
  width: 100%;
  height: ${({ height }) => height - 56}px;
`;

const ContentsFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 78px - env(safe-area-inset-bottom));
`;

const ContentsBox = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.div`
  text-align: center;
  font: ${Fonts.H3_Bold};
  white-space: pre-wrap;

  & > span {
    font: ${Fonts.B1_Bold};
  }

  &.semi {
    color: ${Colors.CG_70};
    font: ${Fonts.B2_Medium_P};
  }
`;

const ButtonFrame = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: fit-content;
  padding: 16px 16px calc(16px + env(safe-area-inset-bottom));
`;

const UnavailableMobile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Frame height={window.innerHeight}>
      <ContentsFrame>
        <ContentsBox>
          <Icon name='error' size={48} color={Colors.ERROR} />
          <SpacingBlock size={8} vertical />
          <Text>
            <span>역량 기반의 취업 매칭 플랫폼</span>
            <br />
            JOBDA로 통합 지원하기
          </Text>
          <SpacingBlock size={24} vertical />
          <Text className='semi'>
            {'죄송합니다.\n해당 지원서는 모바일 지원이 불가능합니다.\nPC에서 접속해 주세요.'}
          </Text>
        </ContentsBox>
      </ContentsFrame>
      <ButtonFrame>
        <Button label='나가기' buttonMode={ButtonMode.PRIMARY} size='large' onClick={() => setIsModalOpen(true)} />
      </ButtonFrame>
      <ConfirmModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Frame>
  );
};

export default UnavailableMobile;
