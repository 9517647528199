import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SingleMenuItem from '__designkit__/components/SingleMenuItem';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import { ICompanyReport } from 'interfaces/_v2/profile/INhisRqRs';
import IComponentProps from 'interfaces/props/IComponentProps';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptyJoySvg } from 'assets/character/character_joy_empty_9_2d.svg';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div.title {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: fit-content;

    & .main {
      font: ${Fonts.H4_Bold};
      color: ${Colors.CG_90};
    }
  }

  & .career-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: fit-content;
  }

  & .empty-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;

    & > div.text {
      font: ${Fonts.H5_Bold};
      color: ${Colors.JOBDA_BLACK};
      text-align: center;
    }
  }
`;

interface ICareerSelectProps extends IComponentProps {
  careerData: ICompanyReport[];
  seletedSetter: Dispatch<SetStateAction<ICompanyReport[]>>;
}

const CareerSelect: FC<ICareerSelectProps> = ({ careerData, seletedSetter }) => {
  const [selected, setSelected] = useState<ICompanyReport[]>([]);

  const makeSubTitle = (startDate: string, endDate: string | null, totalSalary: number): string => `${startDate.replace(/-/g, '.')} ~ ${endDate ? endDate.replace(/-/g, '.') : '재직 중'} | 총 소득 ${Math.floor(totalSalary / 10000).toLocaleString()}만원`;

  useEffect(() => {
    seletedSetter(selected);
  }, [selected, seletedSetter]);

  return (
    <Frame>
      { careerData.length > 0
        ? (
          <>
            <div className='title'>
              <div className='main'>
                성공적으로 경력을 불러왔어요!<br />
                등록할 경력 정보를 선택해 주세요.
              </div>
            </div>
            <SpacingBlock size={32} vertical />
            <div className='career-list'>
              { careerData.map((career) => (
                <SingleMenuItem
                  key={career.token}
                  title={career.companyName}
                  subTitle={makeSubTitle(career.startDate, career.endDate, career.totalSalary)}
                  rightIcon={<Icon name='check-circle-filled' size={32} color={selected.includes(career) ? Colors.G_150 : Colors.CG_40} />}
                  onClick={() => {
                    if (selected.includes(career)) setSelected(selected.filter((selectedCareer) => selectedCareer !== career));
                    else setSelected([...selected, career]);
                  }}
                  selected={selected.includes(career)}
                />
              ))}
            </div>
          </>
        )
        : (
          <div className='empty-box'>
            <SpacingBlock size={120} vertical />
            <EmptyJoySvg viewBox='0 0 121 120' width={120} height={120} />
            <SpacingBlock size={32} vertical />
            <div className='text'>
              불러올 수 있는 경력 정보가 없어요.<br />
              직접 추가해보시겠어요?
            </div>
          </div>
        )}
    </Frame>
  );
};

export default CareerSelect;
