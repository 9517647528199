import ImgLogo from '__designkit__/assets/jobda_app_logo.svg';
import { Button, ButtonMode, ButtonType } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import JDBaseModal from 'components/_v2/_common/modals/JDBaseModal';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import { injectStore } from 'models/store';
import { FC } from 'react';
import MobileStore from 'store/mobileStore';
import styled from 'styled-components';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import ImgBackground from 'assets/_v2/_common/Img_mobile_alarm.png';
import ImgPushAlarm from 'assets/_v2/_common/Img_push_alarm.png';
import { useInsightResumeLog } from 'query/resume/useResumeQuery';
import { useLocation } from 'react-router';
import { InsightResumeLogRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';

interface IAppInduceActionModalProps {
  isOpenAppInduceActionModal: boolean;
  setIsOpenAppInduceActionModal: (isOpen: boolean) => void;
  context?:Context
}

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    position:relative;
    justify-content: center;
    align-items: center;
    background-color: ${colors.WHITE_100};
    width: calc(100vw - 60px);
    height: fit-content;
    padding: 40px 0 24px 0;
    box-shadow: 0px 16px 25px 0px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    overflow: hidden;
    .left-background{
        width: 400px;
        height: 200px;
        position: absolute;
        top: -100px;
        left: -200px;
        border-radius: 400px;
        opacity: 0.8;
        background-color: ${colors.G_90};
        filter:blur(100px);
    }
    .right-background{
        width: 400px;
        height: 200px;
        position: absolute;
        top: -100px;
        left: 200px;
        border-radius: 400px;
        opacity: 0.8;
        background-color: ${colors.S_100};
        filter:blur(100px);
    }
    .bold-text{
        font:${Fonts.H4_Bold};
        color:${colors.JOBDA_BLACK};
        margin-bottom: 8px;
        z-index: 1;
    }
    .small-text{
        font:${Fonts.B2_Medium_P};
        color:${colors.CG_80};
        text-align: center;
        margin-bottom: 16px;
        z-index: 1;
    }
    .img{
        z-index: 1;
        width: 100%;
    }
    .push-alarm{
        width: 100%;
        position: absolute;
        top:166px;
        z-index:2;
        opacity: 0;
        transform: translateY(10px);
        animation: fadeInUp 0.3s ease-in 0.3s forwards; 

    }
    .blur-field{
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
        width: 100%;
        height: 64px;
        position: absolute;
        bottom: 90px;
        z-index: 1;
    }

    @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(10px); 
    }
    to {
      opacity: 1;
      transform: translateY(0); 
    }
  }
`;
const ButtonFrame = styled.div`
    padding: 0 20px;
    width:100%;

`;
const LaterButton = styled.div`
        margin-top: 16px;
        display: flex;
        font:${Fonts.B2_Medium};
        color:${colors.WHITE_100};
        width: 100%;
        justify-content: center;
        opacity: 0.6;
`;

const AppInduceActionModal: FC<IAppInduceActionModalProps> = ({ context = new Context(), isOpenAppInduceActionModal, setIsOpenAppInduceActionModal }) => {
  const handleMoveStore = () => {
    onClickStoreBtn();
    MobileStore.moveToStore();
    context.isGlobalModalOpen = false;
    setIsOpenAppInduceActionModal(false);
  };
  const saveSuccess = () => {
  };
  const { mutate: saveResumeInduce } = useInsightResumeLog(saveSuccess);
  const { pathname } = useLocation();

  const onClickStoreBtn = async () => {
    const induceRq:InsightResumeLogRq = {
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: 'app_modal_btn_move_store',
      data: { modalAction: 'click_to_store' },
    };
    saveResumeInduce(induceRq);
  };
  const onClickExitBtn = async () => {
    const induceRq:InsightResumeLogRq = {
      action: 'CLICK',
      actionSource: pathname,
      actionComponent: 'app_modal_btn_exit',
      data: { modalAction: 'click_exit' },
    };
    saveResumeInduce(induceRq);
  };

  return (
    <JDBaseModal
      dimmed
      isOpen={isOpenAppInduceActionModal}
      onClickClose={() => {
        context.isGlobalModalOpen = false;
        onClickExitBtn();
        setIsOpenAppInduceActionModal(false);
      }}
    >
      <Frame>
        <div className='left-background' />
        <div className='right-background' />
        <div className='bold-text'>잠깐! 잡다 앱을 설치하고</div>
        <div className='small-text'>역량으로 채용하는 750여 개 <br />
          기업으로부터 매칭 알람 받아보세요!
        </div>
        <img src={ImgBackground} alt='background' className='img' />
        <img src={ImgPushAlarm} alt='push-alarm' className='push-alarm' />
        <div className='blur-field' />
        <SpacingBlock vertical size={20} />
        <ButtonFrame>
          <Button
            label='앱 설치하고 알람받기'
            size='large'
            buttonMode={ButtonMode.PRIMARY}
            onClick={handleMoveStore}
          />
        </ButtonFrame>

      </Frame>

      <LaterButton onClick={() => {
        TodayRenderCheckUtil.setTodayRenderCheck('appInduce');
        context.isGlobalModalOpen = false;
        setIsOpenAppInduceActionModal(false);
      }}
      >모바일 웹으로 보기
      </LaterButton>
    </JDBaseModal>
  );
};

export default inject(injectStore.context)(observer(AppInduceActionModal));
