import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonMode } from '__designkit__/button/Button';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import ProfileAdditionInfoModal from 'components/_v2/positionJd/ProfileAdditionInfoModal';
import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import ProfilePreviewModal from 'components/_v2/profile/preview/ProfilePreviewModal';
import { ResumeFormType } from 'consts/_v2/profile/ProfileType';
import { AccLiteStatusType } from 'interfaces/_v2/positionJd/IPositionDetailRs';
import { IChoice, IResumeAdditionInfoApplyDto, IResumeAdditionInfoApplyRq, IResumeAdditionInfoTemp, IResumePreviewAdditionInfo, IResumePreviewAdditionInfoDto } from 'interfaces/_v2/positionJd/IResumeAdditionInfoRqRs';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import PositionJdModel from 'models/_v2/PositionJdModel';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import AdditionalMoveDirtyModal from '../profile/AdditionalMoveDirtyModal';

const Frame = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 16px;
    position: relative;
    height: 100%;

    .title {
        font: ${Fonts.H4_Bold};
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 16px;
    }

    .desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font: ${Fonts.B2_Medium_P};
        color: ${colors.CG_70};
        background-color: ${colors.CG_30};
        padding: 20px;
        margin-bottom: 24px;
        border-radius: 8px;
        width: 100%;
        .sub-desc {
            display: flex;
            align-items: center;
            font: ${Fonts.B3_Medium};
            color: ${colors.CG_90};

            span {
                margin-left: 4px;
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

`;

const BoxComponent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    width: 100%;
    height: 64px;
    border-radius: 8px;
    border: 1px solid ${colors.CG_50};
    background: ${colors.WHITE_100};
    margin-bottom: 12px;

    .box-name {
        display: flex;
        align-items: center;
        font: ${Fonts.B1_Bold};
        color: ${colors.JOBDA_BLACK};
    }
    .disabled-box-name{
      display: flex;
        align-items: center;
        font: ${Fonts.B1_Bold};
        color: ${colors.CG_50};
        .subtitle{
          margin-left: 8px;
          font:${Fonts.B3_Medium};
          color:${colors.CG_50};
        }
    }
    .badge-complete{
      display: flex;
      padding:4px 6px;
      border-radius: 4px;
        align-items: center;
        font: ${Fonts.B4_Medium};
        color: ${colors.WHITE_100};
        background: ${colors.G_150};
    }
    .badge-in-progress{
      display: flex;
      padding:4px 6px;
      border-radius: 4px;
        align-items: center;
        font: ${Fonts.B4_Medium};
        color: ${colors.WHITE_100};
        background: ${colors.CG_60};
    }
    .badge-not{
      display: flex;
      padding:4px 6px;
      border-radius: 4px;
        align-items: center;
        font: ${Fonts.B4_Medium};
        color: ${colors.WHITE_100};
        background: ${colors.ERROR};
    }
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        font: ${Fonts.B3_Bold};
        color: ${colors.WHITE_100};
        border-radius: 4px;
        background-color: ${colors.JOBDA_BLACK};
    }
`;

const SubmitButtonFrame = styled.div`
    width: 100%;
    position: fixed;
    bottom: calc(0px + env(safe-area-inset-bottom));
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: center;
    border-top: 1px solid ${colors.CG_40};
    margin-top: auto;

    .preview-btn {
      margin-right: 8px;
    }

`;
export interface IProfileApplyModalProps extends IProfileProps {
    handleClose: () => void;
    handleSuccess: (additionInfo: IResumeAdditionInfoApplyRq) => void;
    positionJdModel?: PositionJdModel;
}

const defaultValues: Partial<IResumeAdditionInfoTemp> = { additionInfo: [] };

const ProfileApplyModal:FC<IProfileApplyModalProps> = ({ handleClose, handleSuccess, profileModel = new ProfileModel(), positionJdModel = new PositionJdModel() }) => {
  const [isPreview, setIsPreview] = useState(false);
  const [transAdditionInfo, setTransAdditionInfo] = useState<IResumeAdditionInfoApplyRq>({ additionInfo: [] });
  const [previewAdditionInfo, setPreviewAdditionInfo] = useState<IResumePreviewAdditionInfo>({ additionInfo: [] });
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const additionInfoSchema = yup.object();
  const useFormed = useForm<IResumeAdditionInfoTemp>({
    mode: 'all',
    resolver: yupResolver(additionInfoSchema),
    defaultValues,
  });

  const [isOpenAdditionInfoModal, setIsOpenAdditionInfoModal] = useState(false);

  const viewPreview = async () => {
    await profileModel.loadProfileDetail();
    setIsPreview(true);
  };

  const handleApply = async () => {
    handleSuccess(transAdditionInfo);
  };

  const transformAdditionInfo = () => {
    const additionInfo: IResumeAdditionInfoApplyDto[] = [];
    const previewAdditionInfoTemp: IResumePreviewAdditionInfoDto[] = [];
    const originalInfo = positionJdModel.profileAdditionalInfo;
    const tempInfo = positionJdModel.tempAdditionalInfo?.additionInfo;

    if (originalInfo && tempInfo) {
      tempInfo.forEach((info, idx) => {
        const originalChoices = originalInfo[idx].choices || [];

        const choicesTransformed: IChoice[] = originalChoices.map((originalChoice) => {
          const isSelected = info.choices ? info.choices.includes(originalChoice) : false;
          return {
            name: originalChoice,
            selectYn: isSelected,
          };
        });

        let fileUids: string[] = [];
        if (info && info.files) {
          if (Array.isArray(info.files)) {
            fileUids = info.files.map((file) => file.fileUid);
          } else if (info.files) {
            fileUids = [info.files.fileUid];
          } else {
            fileUids = [];
          }
        }

        additionInfo.push({
          question: originalInfo[idx].question,
          type: originalInfo[idx].type,
          answer: info?.answer ? info.answer : '',
          choices: choicesTransformed,
          files: fileUids,
        });

        previewAdditionInfoTemp.push({
          question: originalInfo[idx].question,
          type: originalInfo[idx].type,
          answer: info?.answer ? info.answer : '',
          choices: choicesTransformed,
          files: info?.files,
        });
      });
    }
    const additionInfoRq: IResumeAdditionInfoApplyRq = { additionInfo };
    const additionPreview: IResumePreviewAdditionInfo = { additionInfo: previewAdditionInfoTemp };
    setTransAdditionInfo(additionInfoRq);
    setPreviewAdditionInfo(additionPreview); // file 형식이 다름
  };

  useEffect(() => {
    const init = async () => {
      await positionJdModel.getPositionAccStatus(positionJdModel.positionSn);
    };
    init();
    transformAdditionInfo();
  }, [isOpenAdditionInfoModal]);

  const closeModal = () => {
    if (positionJdModel.profileAdditionalInfo && positionJdModel.tempAdditionalInfo) {
      setIsDirtyModalOpen(true);
    } else {
      handleClose();
    }
  };

  const closeDirtyModal = () => {
    setIsDirtyModalOpen(false);
    handleClose();
  };

  return (
    <JDFullModal closeCustom handleClose={closeModal}>
      <FormProvider {...useFormed}>
        <Frame>
          <div className='title'>간편 지원하기</div>
          <div className='desc'>
            <div className='sub-desc'>
              <Icon name='check' size={16} color={colors.JOBDA_BLACK} />
              <span>잡다에서 작성한 내 프로필로 간단히 지원할 수 있어요.</span>
            </div>
            <div className='sub-desc'>
              <Icon name='check' size={16} color={colors.JOBDA_BLACK} />
              <span>제출해야 하는 정보를 꼼꼼히 확인해 주세요.</span>
            </div>
          </div>
          <BoxComponent>
            <div className='box-name'>내 프로필</div>
            <div className='badge-complete'>완료</div>

          </BoxComponent>
          {
            positionJdModel.profileAdditionalInfo && (
              <BoxComponent onClick={() => setIsOpenAdditionInfoModal(true)}>
                <div className='box-name'>추가 정보<Icon name='arrow-right' size={24} color={colors.JOBDA_BLACK} /></div>
                {
                    positionJdModel.additionInfoValid ? (
                      <div className='badge-complete'>
                        완료
                      </div>

                    ) : (
                      <div className='badge-not'>
                        미완료
                      </div>
                    )
}
              </BoxComponent>
            )
        }
          {
          positionJdModel.positionAccStatus?.accApplyRequiredYn && (
          <BoxComponent>

            <div className='disabled-box-name'>역량검사
              <div className='subtitle'>응시는 PC 환경에서 가능합니다</div>
            </div>
            {
            positionJdModel.positionAccStatus?.accLiteStatus === AccLiteStatusType.COMPLETED ? (
              <div className='badge-complete'>
                완료
              </div>
            ) : positionJdModel.positionAccStatus?.accLiteStatus === AccLiteStatusType.IN_PROGRESS ? (
              <div className='badge-in-progress'>
                응시 중
              </div>
            ) : (
              <div className='badge-not'>
                미완료
              </div>
            )
          }

          </BoxComponent>
          )
        }
        </Frame>
        <SubmitButtonFrame>
          <Button className='preview-btn' buttonMode={ButtonMode.DEFAULT} outLined size='large' onClick={viewPreview} label='제출 전 미리보기' />
          <Button
            disabled={(!!positionJdModel.profileAdditionalInfo && !positionJdModel.additionInfoValid) || (positionJdModel.positionAccStatus?.accApplyRequiredYn && positionJdModel.positionAccStatus?.accLiteStatus !== AccLiteStatusType.COMPLETED)}
            buttonMode={ButtonMode.PRIMARY}
            size='large'
            onClick={handleApply}
            label='제출하기'
          />
        </SubmitButtonFrame>
        {isPreview && profileModel.previewProfile && (
        <ProfilePreviewModal
          data={profileModel.previewProfile}
          additionInfo={previewAdditionInfo}
          handleClose={() => setIsPreview(false)}
        />
        )}
        { isOpenAdditionInfoModal
          && (
          <ProfileAdditionInfoModal
            useFormed={useFormed}
            handleClose={() => setIsOpenAdditionInfoModal(false)}
          />
          )}
        <AdditionalMoveDirtyModal
          isOpen={isDirtyModalOpen}
          onSuccess={() => closeDirtyModal()}
          onClickClose={() => setIsDirtyModalOpen(false)}
        />
      </FormProvider>
    </JDFullModal>
  );
};

export default inject(injectStore.profileModel, injectStore.positionJdModel)(observer(ProfileApplyModal));
