import { IEventChallengeProps } from '__pc__/page/eventChallenge';
import MarketingConsentModal from '__pc__/page/eventChallenge/MarketingConsentModal';
import classnames from 'classnames/bind';
import JDALink from 'components/JDALink';
import JDSwiper, { JDSwiperSlide, PageNationType } from 'components/_v2/_common/JDSwiper';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import { TOAST_TYPE } from 'components/_v2/_common/toast/JDToast';
import ErrorCode, { ErrorCodeText } from 'consts/ErrorCodes';
import RoutePaths from 'consts/RoutePaths';
import useToast from 'hooks/useToast';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import EventChallengeModel from 'models/_v2/event/EventChallengeModel';
import { injectStore } from 'models/store';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import FileUtil from 'utils/FileUtil';
import styles from './challengeMission2.module.scss';

const cx = classnames.bind(styles);

const ChallengeMission2:FC<IEventChallengeProps> = ({ eventChallengeModel = new EventChallengeModel(), login = new Login() }) => {
  const [openParticipationCompletedModal, setOpenParticipationCompletedModal] = useState<boolean>(false);
  const [openApplyCompletedModal, setOpenApplyCompletedModal] = useState<boolean>(false);
  const [openEventSoldOutModal, setOpenEventSoldOutModal] = useState<boolean>(false);
  const [openMarketingConsentModal, setOpenMarketingConsentModal] = useState<boolean>(false);
  const [openWaitingModal, setOpenWaitingModal] = useState<boolean>(false);
  const { setToastObject } = useToast();
  const history = useHistory();
  const talentPoolAccaTestApply = async () => {
    try {
      await eventChallengeModel.loadEventsTalentPoolAccTestApply();
      setOpenApplyCompletedModal(true);
      await eventChallengeModel.loadEventsTalentPoolUserStatus();
    } catch (e: any) {
      switch (e.response?.data?.errorCode) {
        case ErrorCode.B904:
        case ErrorCode.B905:
          setOpenMarketingConsentModal(true);
          break;
        case ErrorCode.B906:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '이벤트 응모 대상이 아닙니다.', subMessage: '이력서 모음ZIP은 다운로드 가능해요!' });
          break;
        case ErrorCode.B907:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: ErrorCodeText.post_event_challenge.B907, subMessage: undefined });
          break;
        case ErrorCode.B998:
          setOpenEventSoldOutModal(true);
          break;
        default:
          setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '다시 시도해 주세요.', subMessage: undefined });
          break;
      }
    }
  };

  const handleTalentPoolAccaTestApply = async () => {
    if (!login.userInfo) {
      history.push(RoutePaths.login);
      return;
    } if (eventChallengeModel.userEventStatus?.talentPoolEventDto.isTalentPoolAccTestEventParticipation) {
      setOpenParticipationCompletedModal(true);
      return;
    } if (!eventChallengeModel.userEventStatus?.talentPoolEventDto.isTalentPoolAccTest) {
      setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '역량검사 응시를 완료해야 응모가 가능합니다', subMessage: undefined });
      return;
    }
    talentPoolAccaTestApply();
  };
  const handleDownloadResume = async () => {
    if (!login.userInfo) {
      history.push(RoutePaths.login);
      return;
    }
    try {
      const res = await eventChallengeModel.loadEventsTalentPoolAccTestResumeDownload();
      if (res) {
        FileUtil.fileDownload('직무별 합격자 이력서 모음', res, true);
      }
    } catch (e) {
      setToastObject({ isOpen: true, type: TOAST_TYPE.ERROR, message: '역량검사 응시를 완료해야 응모가 가능합니다', subMessage: undefined });
      console.error(e);
    }
  };
  return (
    <div className={cx('inner')}>
      <div className={cx('sectionTitleArea')}>
        <strong className={cx('sectionTitle')}>챌린지 미션 2<br />역량검사 응시하기</strong>
      </div>

      {/* 미션 영역 */}
      <div className={cx('missionArea')}>
        <div className={cx('missionInner')}>
          <JDSwiper className='service-swiper' paginationType={PageNationType.bullets} autoplay>
            <JDSwiperSlide className='service-card'>
              <div className={cx('sliderItem', 'type1')} />
            </JDSwiperSlide>
            <JDSwiperSlide className='service-card'>
              <div className={cx('sliderItem', 'type2')} />
            </JDSwiperSlide>
            <JDSwiperSlide className='service-card'>
              <div className={cx('sliderItem', 'type3')} />
            </JDSwiperSlide>
          </JDSwiper>
        </div>
        <div className={cx('btnArea')}>
          <JDALink
            to={RoutePaths.acca_test}
            className={cx('btnEvent', 'eventBtn_testGo', { outLine: eventChallengeModel.userEventStatus?.talentPoolEventDto.isTalentPoolAccTest })}
          >
            {eventChallengeModel.userEventStatus?.talentPoolEventDto.isTalentPoolAccTest ? '역량검사 재응시하기' : '역량검사 응시하기'}
          </JDALink>
        </div>
      </div>

      {/* 혜책 영역 */}
      <div className={cx('benefitArea')}>
        <div className={cx('benefitInner')}>
          <div className={cx('benefitWrap', 'type1')}>
            <div className={cx('textArea')}>
              <em className={cx('benefitTitle')}>
                <i className={cx('lightText')}>혜택 1</i>
                <br />
                합격자이력서모음.zip
              </em>
              <p className={cx('benefitDesc')}>
                인사담당자&합격자의 작성 Tip 포함
                <br />
                15개 직무별 금손 선배들의 합격 이력서 제공!
              </p>
            </div>

            {/* 합격자 이력서 다운로드 버튼 */}
            <button
              onClick={handleDownloadResume}
              className={cx('btnDownload', 'eventBtn_zip')}
            >
              직무별 합격자 이력서 모음.ZIP 받기
            </button>
          </div>
        </div>
        <div className={cx('benefitInner')}>
          <div className={cx('benefitWrap', 'type2')}>
            <div className={cx('textArea')}>
              <em className={cx('benefitTitle')}>
                <i className={cx('lightText')}>혜택 2</i>
                <br />
                내 역량 진단
              </em>
              <p className={cx('benefitDesc')}>진단 결과표를 면접 준비에 활용할 수 있어요!</p>
            </div>
          </div>
          <div className={cx('benefitWrap', 'type3')}>
            <div className={cx('textArea')}>
              <em className={cx('benefitTitle')}>
                <i className={cx('lightText')}>혜택 3</i>
                <br />
                네이버페이
              </em>
              <p className={cx('benefitDesc')}>
                10,000P
              </p>
            </div>
          </div>
          {/* 응모하기 버튼 영역 */}
          <div className={cx('btnEventArea')}>
            <button
              className={cx('btnEvent', 'btnApply', 'eventBtn_eventApply2')}
              onClick={handleTalentPoolAccaTestApply}
            >
              이벤트 응모하고 혜택 바로 받기
            </button>
          </div>
        </div>
      </div>

      {/* 알림 텍스트 영역 */}
      <p className={cx('notice')}>
        - 챌린지 미션 완료 후 [응모하기] 버튼으로
        <br />
        &nbsp;&nbsp;이벤트 응모가 가능합니다.
        <br />
        - 네이버페이: 응모 후 즉시 기프티콘 발송
        <br />
        (회원가입 정보의 핸드폰 번호로 발송), 소진 시 마감됩니다.
        <br />
        - 이벤트 시작일 이전에 이미 역량검사를 응시 완료한 회원은
        <br />
        네이버페이 제공 대상에서 제외됩니다.
        <br />
        - 면접 기출 연습: 이벤트 응모 시 입력한 이메일로
        <br />
        접속 URL과 인증코드 발송합니다.
        <br />
        - 중복 참여 불가합니다. (핸드폰 번호 당 1번 지급)
      </p>
      <JDSimpleInfoModal title='혜택 지급 완료!' descriptions='추첨 이벤트에 참여해보세요.' dimmed isOpen={openParticipationCompletedModal} onClickClose={() => setOpenParticipationCompletedModal(false)} />
      <JDSimpleInfoModal title='준비중입니다.' dimmed isOpen={openWaitingModal} onClickClose={() => setOpenWaitingModal(false)} />
      <JDSimpleInfoModal title='<혜택 지급 완료>' descriptions={`1. 핸드폰 번호로 발송된 기프티콘을 확인해 주세요. \n2. 합격자 이력서 모음ZIP은 다운로드 가능해요!`} dimmed isOpen={openApplyCompletedModal} onClickClose={() => setOpenApplyCompletedModal(false)} />
      <JDSimpleInfoModal title='<혜택 지급 완료>' descriptions={`1. 선착순 혜택은 마감되었습니다. \n2. 합격자 이력서 모음ZIP은 다운로드 가능해요!`} dimmed isOpen={openEventSoldOutModal} onClickClose={() => setOpenEventSoldOutModal(false)} />
      <MarketingConsentModal isOpen={openMarketingConsentModal} onClickClose={() => setOpenMarketingConsentModal(false)} />
    </div>
  );
};

export default inject(injectStore.eventChallengeModel, injectStore.login)(observer(ChallengeMission2));
