import JDAPopover from 'components/_v2/_common/popover/JDPopover';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { lineClampBox, lineClampOnlyOne } from 'consts/_v2/_common/style/mixins';
import React, { FC } from 'react';
import styled from 'styled-components';
import Icon from '__designkit__/icon/Icon';
import ImgEmpty from 'assets/_v2/positionList/img_character_joy_find.png';
import { getChipDday } from 'utils/DateUtils';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  width: 302px;
  border-radius: 8px;
  background-color: ${Colors.WHITE_100};
  border: 1px solid ${Colors.CG_40};

  .thumbnail-img {
    position: relative;
    flex: none;
    width: 100%;
    height: 168px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;

    .img-file {
      width: 100%;
      max-height:168px;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
    }

    .test-ing {
      width: 100%;
      height: 100%;

      .default-img {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: ${Colors.CG_30};
        border-radius: 8px 8px 0 0;

        &::after {
          content: '';
          display: block;
          width: 96px;
          height: 96px;
          position: absolute;
          top: 36px;
          left: 50%;
          transform: translateX(-50%);
          background: url(${ImgEmpty}) no-repeat;
          background-size: contain;
        }
      }
    }
    /* 뱃지 */
    .badge {
      position: absolute;
      display: flex;
      align-items: center;
      top: 16px;
      left: 16px;
      height: 22px;
      padding: 0 6px;
      border-radius: 4px;
      font: ${Fonts.B4_Medium};

      &.badge-basic {
        border: 1px solid rgba(0, 0, 0, 0.05);
        background-color: ${Colors.CG_60};
        color: ${Colors.WHITE_100};
      }

      &.badge-dDay {
        border: 1px solid ${Colors.CG_50};
        background:${Colors.WHITE_100};
        color: ${Colors.CG_70};
      }

      &.badge-complete {
        border: 1px solid rgba(0, 0, 0, 0.05);
        background:${Colors.G_150};
        color: ${Colors.WHITE_100};
      }
    }
  }

  .list-desc {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 16px 24px;

    .result-title {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;

      .date {
        font: ${Fonts.B3_Medium};
        color: ${Colors.CG_60};
      }

      .title {
        font: ${Fonts.H5_Bold};
        color: ${Colors.JOBDA_BLACK};
        ${lineClampBox(1)};
      }
    }
  }

  .more-button {
    position: absolute;
    top: 24px;
    right: 16px;
  }

  .header-frame {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
      > h3 {
        margin-left: 2px;
        font: ${Fonts.B3_Medium};
        color: ${Colors.CG_70};
      }
    }
    > button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }
  }

  .result-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    margin: 24px 0 8px 0;

    > h2 {
      font: ${Fonts.B2_Medium};
      color: ${Colors.CG_60};
    }

    > button {
      font: ${Fonts.B3_Medium};
      color: ${Colors.JOBDA_BLACK};
    }

    .progress-state {
      font: ${Fonts.B2_Medium};
      color: ${Colors.CG_60};

      .test-count {
        margin-left: 8px;
        
        &::before {
          content: '';
          display: inline-block;
          margin-right: 8px;
          width: 1px;
          height: 8px;
          background-color: ${Colors.CG_40};
        }
      }
    }
    .view-disabled{
      font:${Fonts.B3_Medium};
      color:${Colors.CG_50};
    }
    .view-result {
      display: flex;
      align-items: center;
    }
  }
  
  > h1 {
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
    ${lineClampOnlyOne()};
    margin-bottom: 26px;
  }
  > div {
    display: flex;
    justify-content: space-between;
    
    > h2,
    button {
      font: ${Fonts.B2_Medium};
      color: ${Colors.CG_70};

      > em {
        font-style: normal;
        font: ${Fonts.B3_Medium};
        color: ${Colors.JOBDA_BLACK};
      }
    }
  }

  progress {
    width: 100%;
    height: 8px;
    -webkit-appearance: none;
    ::-webkit-progress-bar {
      border-radius: 48px;
      background-color: ${Colors.CG_50};
    }
    ::-webkit-progress-value {
      background-color: ${Colors.G_150};
      border-radius: 48px;
    }
  }
`;

const PopuoverFrame = styled.div`

  > button {
    color: ${Colors.CG_70};
  }
`;

interface IResultCard {
  date: string;
  title: string;
  doneExamCount: number;
  maxExamCount: number;
  thumbnailUrl?: string;
  completed:number;
  additional?:boolean;
  dDate?:string;
  submitYn?:boolean;
  liteYn?:boolean;
  handleClink?: () => void;
  handleDelete?: () => void;
}

const ResultCard: FC<{ className?: string; item: IResultCard }> = ({ className, item }) => {
  const a = 'as?';
  return (
    <Frame>
      <div className='thumbnail-img'>
        {
        item.thumbnailUrl && item.completed === 100 && (
          <div className='test-done'>
            <img src={item.thumbnailUrl} alt='' className='img-file' />
          </div>
        )
      }
        {/* 응시 미완료인 경우 */}
        <div className='test-ing'>
          {
          item.thumbnailUrl
            ? <img src={item.thumbnailUrl} alt='' className='img-file' />
            : <div className='default-img' />
        }
          {/* {
          item.submitYn ? <div className='badge badge-complete'>제출 완료</div>
            : (
              getChipDday(item.dDate) === -1 ? <div className='badge badge-basic'>제출 마감</div>
                : <div className='badge badge-dDay'>D{getChipDday(item.dDate)}</div>
            )

        } */}
          {/* 뱃지 종류
          badge + badge-클래스명
          = 추가 응시 필요, 제출마감: basic
          = 디데이 : dDay
          = 완료 : complete
          */}
          {/* <div className='badge badge-basic'>추가 응시 필요</div> */}
          {/* <div className='badge badge-dDay'>D-15</div>
        <div className='badge badge-complete'>제출 완료</div> */}
        </div>
      </div>
      <div className='list-desc'>
        <div className='header-frame'>
          <div className='result-title'>
            <div className='date'>{item.date}</div>
            <div className='title'>{item.title ? item.title : '역량검사 결과표'}</div>
          </div>
          <div className='more-button'>
            <JDAPopover popoverWidth={139} useOutsideClick position='non_pony_under_right' popoverMargin={0} whiteMode anchorIcon={<Icon name='more-horizon' size={24} />}>
              <PopuoverFrame>
                <button
                  onClick={(e) => {
                    if (item.handleDelete !== undefined) item.handleDelete();
                    e.stopPropagation();
                  }}
                >
                  이 리스트에서 제거
                </button>
              </PopuoverFrame>
            </JDAPopover>
          </div>
        </div>
        <div className='result-detail'>
          {item.doneExamCount === item.maxExamCount ? (
            <>
              <h2>응시 완료</h2>
              <button
                onClick={(e) => {
                  if (item.handleClink !== undefined) item.handleClink();
                  e.stopPropagation();
                }}
              >
                <em className='view-result'>결과표 보기<Icon name='arrow-right' size={24} /></em>
              </button>
            </>
          ) : (
            <>
              <div className='progress-state'>
                {((item.doneExamCount / item.maxExamCount) * 100).toFixed(0)}% 진행
                <span className='test-count'>
                  {`${item.doneExamCount}/${item.maxExamCount}`}
                </span>
              </div>
              {
                item.liteYn
                  ? <div className='view-disabled'>PC에서 응시 가능</div>
                  : (
                    <button
                      onClick={(e) => {
                        if (item.handleClink !== undefined) item.handleClink();
                        e.stopPropagation();
                      }}
                    >
                      <em className='view-result'>이어하기<Icon name='arrow-right' size={32} /></em>
                    </button>
                  )
              }

            </>
          )}
        </div>
        <progress value={item.doneExamCount} max={item.maxExamCount} />
      </div>
    </Frame>
  );
};
export default ResultCard;
