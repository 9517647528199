/* eslint-disable class-methods-use-this */
import URIs from 'consts/URIs';
import { IInsightResumeListGetRs, InsightResumeAutoSavedGetRs, InsightResumeGetRs, InsightResumeLogRq, InsightResumeSaveRq } from 'interfaces/_v2/insight/IInsightResumeRqRs';
import { request } from 'utils/request';

export default interface ResumeRepo {
    // 지원서 목록 조회
    fetchInsightResumeList(): Promise<IInsightResumeListGetRs>;

    // 지원서 내용 조회
    fetchInsightResumeDetail(resumeSn: number): Promise<InsightResumeGetRs>;

    // 지원서 가져오기
    saveInsightResume(rq: InsightResumeSaveRq): Promise<boolean>;

    // 미열람 지원서 존재 여부 확인
    fetchInsightResumeUnread(): Promise<boolean>;

    // 지원서 로그 저장
    saveInsightResumeLog(rq:InsightResumeLogRq): Promise<boolean>;

    // 지원서 자동 저장 여부 확인
    fetchInsightResumeAutoSave(): Promise<InsightResumeAutoSavedGetRs>;
}

export class RemoteResumeRepo implements ResumeRepo {
  fetchInsightResumeList(): Promise<IInsightResumeListGetRs> {
    return request<IInsightResumeListGetRs>({
      method: 'get',
      url: URIs.get_insight_resume_list,
    });
  }

  fetchInsightResumeDetail(resumeSn: number): Promise<InsightResumeGetRs> {
    return request<InsightResumeGetRs>({
      method: 'get',
      url: URIs.get_insight_resume(resumeSn),
    });
  }

  saveInsightResume(rq: InsightResumeSaveRq): Promise<boolean> {
    return request({
      method: 'post',
      url: URIs.post_insight_convert_profile(rq.resumeSn),
      data: rq.resumeData,
    });
  }

  fetchInsightResumeUnread(): Promise<boolean> {
    return request<boolean>({
      method: 'get',
      url: URIs.get_insight_resume_unread,
    });
  }

  saveInsightResumeLog(rq:InsightResumeLogRq): Promise<boolean> {
    return request({
      method: 'post',
      url: URIs.post_insight_resume_log,
      data: rq,
    });
  }

  fetchInsightResumeAutoSave(): Promise<InsightResumeAutoSavedGetRs> {
    return request({
      method: 'get',
      url: URIs.get_insight_resume_auto_saved,
    });
  }
}
