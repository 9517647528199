import React, { FC } from 'react';
import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import styled from 'styled-components';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { Button, ButtonMode } from '__designkit__/button/Button';
import { useHistory } from 'react-router';
import Context from 'models/Context';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import Login from 'models/Login';
import RoutePaths from 'consts/RoutePaths';
import JDBaseModal from './JDBaseModal';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 312px;
  height: 262px;
  padding: 40px 24px 24px;
  border-radius: 12px;
  background-color: ${Colors.WHITE_100};
`;

const Text = styled.div`
  text-align: center;
  font: ${Fonts.H4_Bold};
  white-space: pre-wrap;

  &.semi {
    color: ${Colors.CG_70};
    font: ${Fonts.B2_Medium_P};
  }
`;

interface IConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  context?: Context;
  login?: Login;
}

const ConfirmModal: FC<IConfirmModalProps> = ({ isOpen = false, onClose = () => {}, context = new Context(), login = new Login() }: IConfirmModalProps) => {
  const history = useHistory();

  const onClickChatJoinBtn = () => {
    if (login.userInfo) history.push(RoutePaths.chat);
    else {
      context.setRedirectUrl(RoutePaths.chat);
      history.push(RoutePaths.login);
    }
  };

  return (
    <JDBaseModal
      dimmed
      isOpen={isOpen}
      onClickClose={onClose}
    >
      <Frame>
        <Text>{`해당 기업 지원자들이\n모여있는 곳이 있어요`}</Text>
        <SpacingBlock size={8} vertical />
        <Text className='semi'>함께 채용 정보, 고민을 이야기해볼까요?</Text>
        <SpacingBlock size={20} vertical />
        <Button label='닫기' buttonMode={ButtonMode.DEFAULT} outLined onClick={() => window.close()} />
        <SpacingBlock size={8} vertical />
        <Button label='채팅 참여하기' buttonMode={ButtonMode.PRIMARY} onClick={onClickChatJoinBtn} />
      </Frame>
    </JDBaseModal>
  );
};

export default inject(injectStore.context, injectStore.login)(observer(ConfirmModal));
