import ProfileMoveDirtyModal from 'components/_v2/profile/ProfileMoveDirtyModal';
import ProfileItemTitle from 'components/_v2/profile/ProfileItemTitle';
import PreferSkillEditModal from 'components/_v2/profile/skill/PreferSkillEditModal';
import FormRoundChip from 'components/_v2/_common/form/FormRoundChip';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import { SkillLevelText } from 'interfaces/_v2/profile/IProfileKnowledgeAndSkillsRs';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import ProfileSkillModel from 'models/_v2/profile/ProfileSkillModel';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

const Frame = styled.div`
`;

const ContentFrame = styled.div`
    margin-bottom: 26px;

    .title {
        font: ${Fonts.B2_Bold};
        color: ${colors.JOBDA_BLACK};
        letter-spacing: -0.01em;
        margin-bottom: 20px;
    }

    .list-frame {
        display: flex;
        flex-wrap: wrap;
    }
`;

export interface IProfileSkillProps {
    profileSkillModel?: ProfileSkillModel;
    hidden?: boolean;
}
const ProfileSkillView:FC<IProfileSkillProps> = ({ profileSkillModel = new ProfileSkillModel() }) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDirtyModalOpen, setIsDirtyModalOpen] = useState<boolean>(false);
  const { skill } = profileSkillModel;

  const closeDirtyModal = () => {
    profileSkillModel.isDirty = false;
    setIsDirtyModalOpen(false);
  };
  const handleEditClick = () => {
    setIsEditOpen(true);
  };

  return (
    <Frame>
      <ProfileItemTitle icon='design' text='지식 · 기술' subText='자격증, 공인 어학시험, 툴, 개발스택 등을 적어주세요.' onClickEditBtn={handleEditClick} />
      { skill?.licenses && skill.licenses.length > 0 && (
        <ContentFrame>
          <p className='title'>자격증</p>
          <div className='list-frame'>
            { skill.licenses.map((license) => (
              <FormRoundChip key={`skill-license-${license.code}`}>{license.name}</FormRoundChip>
            ))}
          </div>
        </ContentFrame>
        )}
      { skill?.examinations && skill.examinations.length > 0 && (
        <ContentFrame>
          <p className='title'>공인 어학 시험</p>
          <div className='list-frame'>
            { skill.examinations.map((examination) => (
              <FormRoundChip key={`skill-examination-${examination.code}`}>{examination.name} - {examination.score ? examination.score : examination.grade}</FormRoundChip>
            ))}
          </div>
        </ContentFrame>
        )}
      { skill?.skills && skill.skills.length > 0 && (
        <ContentFrame>
          <p className='title'>기술 키워드</p>
          <div className='list-frame'>
            { skill.skills.map((item) => (
              <FormRoundChip key={`skill-skill-${item.code}`}>{item.keyword} - {SkillLevelText[item.level]}</FormRoundChip>
            ))}
          </div>
        </ContentFrame>
        )}
      <PreferSkillEditModal
        isOpen={isEditOpen}
        onClose={async () => {
          if (profileSkillModel.isDirty) setIsDirtyModalOpen(true);
          else {
            await profileSkillModel.loadSkill();
            profileSkillModel.recommendSkills = [];
            setIsEditOpen(false);
          }
        }}
      />
      <ProfileMoveDirtyModal isOpen={isDirtyModalOpen} onSuccess={() => setIsEditOpen(false)} onClickClose={closeDirtyModal} />
    </Frame>
  );
};

export default inject(injectStore.profileSkillModel)(observer(ProfileSkillView));
