import IconCheckboxChecked from 'assets/icon/icon_checkbox_check.svg';
import IconRadioChecked from 'assets/icon/icon_radio_check.svg';
import IconSmallCheckbox from 'assets/icon/icon_small_checkbox.svg';
import IconSmallCheckboxChecked from 'assets/icon/icon_small_checkbox_check.svg';
import Fonts, { fonts, lineHeights } from '__designkit__/common/fonts';
import colors from '__designkit__/common/colors';

export const absoluteHorizonCenter = () => `
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const absoluteVerticalCenter = () => `
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
`;

export const absoluteCenterCenter = () => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const clearIonButtonStyle = () => `
  --background:none;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --margin-start:0;
  --margin-end:0;
  --margin-top:0;
  --margin-bottom:0;
  --padding-start:0;
  --padding-end:0;
  --padding-top:0;
  --padding-bottom:0;
  --box-shadow:none;
  --border-radius:none;
  padding:0;
  margin:0;
`;

export const dimmer = () => `
  position:fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color:${colors.c000000_70};
  z-index:5;
`;

export const JDACheckBoxInput = () => `
  width: 0;
  height: 0;
  margin: 10px;
  position: relative;
  
  :disabled {
    opacity: 0.4;
  }
  
  :after {
    content: '';
    ${absoluteCenterCenter()};
    height: 20px;
    width: 20px;
    background: inherit;
    border-radius: 50%;
    transition: background 0.1s;
    background-position: center center;
    background: ${colors.WHITE_100};
    border: 2px solid ${colors.cCACCCF};
  }
  
  :before {
    content: '';
    ${absoluteCenterCenter()};
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: inherit;
    opacity: 1;
  }
  
  &:checked {
    background: ${colors.G_100};
    :after {
      background: inherit;
      background-image: url(${IconCheckboxChecked});
      background-size: 10px 7.5px;
      background-repeat: no-repeat;
      background-position: center center;
      border:none;
    }

  }
`;

export const JDARadioInput = () => `
  width: 0;
  height: 0;
  margin: 10px;
  position: relative;
  
  :disabled {
    opacity: 0.4;
  }
  
  :after {
    content: '';
    ${absoluteCenterCenter()};
    height: 20px;
    width: 20px;
    background: inherit;
    border-radius: 50%;
    transition: background 0.25s;
    background-position: center center;
    background: ${colors.WHITE_100};
    border: 2px solid ${colors.cCACCCF};
  }
  
  :before {
    content: '';
    ${absoluteCenterCenter()};
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: inherit;
    opacity: 1;
  }
  
  &:checked {
    background: ${colors.G_100};
    :after {
      background: inherit;
      background-image: url(${IconRadioChecked});
      background-size: 10px 7.5px;
      background-repeat: no-repeat;
      background-position: center center;
      border:none;
    }
    :before {
      transition: opacity 0.5s, width 0.5s, height 0.5s;
      opacity: 0;
      width: 30px;
      height: 30px;
    }
  }
  :hover:not(:disabled) {
    :after {
      background-position: center center;
    }
    :checked {
      :after {
        background: ${colors.c3ED160};
        background-image: url(${IconRadioChecked});
        background-size: 10px 7.5px;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
`;

export const JDRadioCircleInput = () => `
  width: 0;
  height: 0;
  margin: 10px;
  position: relative;
  
  :disabled {
    opacity: 0.4;
  }
  
  :after {
    content: '';
    ${absoluteCenterCenter()};
    height: 20px;
    width: 20px;
    background: inherit;
    border-radius: 50%;
   
    background-position: center center;
    background: ${colors.WHITE_100};
    border: 2px solid ${colors.cCACCCF};
  }
  
  :before {
    content: '';
    ${absoluteCenterCenter()};
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: inherit;
    opacity: 1;
  }
  
  &:checked {
    :after {
      background: inherit;
      background-image: url(${IconRadioChecked});
      background-size: 10px 7.5px;
      background-repeat: no-repeat;
      background-position: center center;
      border:none;
    }
    :before {
      
      opacity: 0;
      width: 30px;
      height: 30px;
    }
  }
  :hover:not(:disabled) {
    :after {
      background-position: center center;
    }
    :checked {
      :after {
        background-image: url(${IconRadioChecked});
        background-size: 10px 7.5px;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
`;

export const JDASqureCheckBoxInput = () => `
  ${JDACheckBoxInput()};
  
  :after {
    border-radius: 2.5px;
  }
  
  :before {
    border-radius: 2.5px;
  }
 
`;

export const JDRoundRadioBox = () => `
  ${JDACheckBoxInput()};
  
  :after {
    border-radius: 50%;
  }
  
  :before {
    border-radius: 50%;
  }
 
`;

export const JDASmallCheckBoxInput = () => `
  width: 0;
  height: 0;
  margin: 10px;
  position: relative;
  :disabled {
    opacity: 0.4;
  }

  :before {
    content: '';
    ${absoluteCenterCenter()};
    height: 24px;
    width: 24px;
    background: white;
    background-image: url(${IconSmallCheckbox});
    background-repeat: no-repeat;
    background-position: center center;
  }

   &:checked {
    :before {
      background-image: url(${IconSmallCheckboxChecked});
    }
  }
  
`;

export const JDAButton = (ghost: boolean = false, effect: boolean = false, width: string = '100%') => `
  display: block;
  position: relative;
  background: ${ghost ? colors.WHITE_100 : colors.G_100};
  color: ${ghost ? colors.c3ED160 : colors.WHITE_100};
  width: ${width};
  ${ghost && `border: 1px solid ${colors.G_100};`}
  :disabled {
    opacity: 0.5;
  }

  ${
  effect
    ? `:before {
      content: '';
      ${absoluteCenterCenter()};
      height: 100%;
      width: ${width};
      border-radius: inherit;
      border: 8px solid;
      border-color: ${ghost ? colors.G_100 : 'inherit'};
      opacity: 0;
      transition: border 0.5s, opacity 0.5s;
    }
    
    :active {
      :before {
        opacity: 1;
        border: 0px solid;
        border-color: ${ghost ? colors.G_100 : 'inherit'};
        transition: 0s;
      }
    }` : ''
}
`;

export const JDADisabledButton = (width: string = '100%') => `
  display: block;
  position: relative;
  background: ${colors.CG_60};
  border-radius: 4px;
  color: ${colors.WHITE_100};
  width: ${width};
`;

export const JDALargeButton = (ghost: boolean = false, effect: boolean = false) => `
  ${JDAButton(ghost, effect)};
  padding: 16px 0;
  ${fonts.NOTO_17_700};
  ${lineHeights.LINEHEIGHT_1_40};
`;

export const JDAMediumButton = (ghost: boolean = false, effect: boolean = false, width: string = '100%') => `
  ${JDAButton(ghost, effect, width)};
  padding: 12px 0;
  ${fonts.NOTO_15_700};
  ${lineHeights.LINEHEIGHT_1_40};
`;

export const JDASmallButton = (ghost: boolean = false, effect: boolean = false) => `
  ${JDAButton(ghost, effect)};
  padding: 8px 0;
  ${fonts.NOTO_13_700};
  ${lineHeights.LINEHEIGHT_1_40};
`;

export const JDASubmitButton = (ghost: boolean = false, effect: boolean = false, width: string = '100%') => `
  ${JDAButton(ghost, effect, width)};
  border:none;
  height:56px;
  background:${colors.G_100};
  font: ${Fonts.B1_Bold};
  color:${colors.WHITE_100};
  border-radius:4px;
  &:disabled {
    opacity:.5;
  }
`;

export const JDAInputFrame = () => `
  display: block;
  width: 100%;
  height:48px;
  color: ${colors.CG_90};
  border: 1px solid ${colors.CG_30};
  background: ${colors.CG_30};
  border-radius: 4px;
  
  input {
    padding: 0px 16px;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${colors.CG_30} inset !important;
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px ${colors.WHITE_100} inset !important;
  }
  input {
    background: inherit;
    font: ${Fonts.B2_Medium};
    height: 100%;
    border: none;
    outline: none;
    ::placeholder {
      color: ${colors.CG_60};
    }
  }
  
  // 웨일에서 TYPE NUMBER일때 화살표 뜨는 문제
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  :focus-within, &.active {
    border: 1px solid ${colors.CG_90};
    background: transparent;
  }

  &.false{
    border: 1px solid ${colors.ERROR};
    .icon-close{
      filter: invert(44%) sepia(78%) saturate(2124%) hue-rotate(331deg) brightness(102%) contrast(97%);
    }
  }
`;

export const JDAInputFrameBasic = () => `
  ${JDAInputFrame()};
  position:relative;

  .btn-clear {
    display:none;
  }
  input{
    width:100%;
  }
  input:not([value=""])+.btn-clear {
    display:block;
    position:absolute;
    top:35%;
    margin-right:16px;
    right:0;
    width:20px;
    height:20px;
    background: none;
  }
  &.false{
    border: 1px solid ${colors.ERROR};
    .icon-close{
      filter: invert(44%) sepia(78%) saturate(2124%) hue-rotate(331deg) brightness(102%) contrast(97%);
    }
  }
`;

export const JDAInputFrameMessage = () => `
  ${fonts.NOTO_11_400};
  color:${colors.CG_60};
  white-space: break-spaces;
  margin-top:5px;
  margin-left:16px;
  &.false{
    color:${colors.ERROR};
  }
`;

export const IonSlideSpaceBetween = (space: number) => `
  margin-right: ${space}px;
  &:last-child {
    margin-right: 0px;      
  }
`;

export const ScrollHiddenMixin = () => `
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const keyframesSubscribe = () => `
  @keyframes subscribe {
    0% {
      transform:translateZ(100000px) rotateY(0) scale(1);
    }
    80% {
      transform:translateZ(100000px) rotateY(720deg) scale(1.2);
    }
    100% {
      transform:translateZ(100000px) rotateY(0deg) scale(1);
    }
  }
`;

export const keyFrameFadeIn = () => `
  @keyframes fadein {
    0%{
      opacity:0;
    }
    100%{
      opacity:1;
    }
  }
`;

export const keyFrameFadeInUp = () => `
  @keyframes fadeinup {
    0%{
      opacity:0;
      transform: translateY(0);
    }

    100%{
      opacity:1;
      transform: translateY(-24px);
    }
  }

  @keyframes fadeinup10 {
    0%{
      opacity:0;
      transform: translateY(0);
    }

    100%{
      opacity:1;
      transform: translateY(-10px);
    }
  }
`;

export const keyFrameFadeOut = () => `
  @keyframes fadeout {
    0%{
      opacity:1;
    }
    100%{
      opacity:0;
    }
  }
`;

export const lineClampBox = (line: number) => `
  white-space: pre-line;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: ${line};
`;

export const TNBLine = () => `box-shadow: inset 0px -0.5px 0.5px rgba(207, 209, 213, 0.5);`; // 라인 넣는게 세트로 들어가는거 같아서 따로 color 안뺌.

export const importantify = (style:string) => style.replace(/;/g, ' !important;');

export const numberLetterSpacing = () => 'letter-spacing: 7px;';

export const inputNumberLetterSpacing = () => `
  ${numberLetterSpacing()};
  -moz-appearance:textfield;
  &::-webkit-outer-spin-button,&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin:0;
  }
  &::placeholder {
    letter-spacing:0;
  }
`;

export const keywordTag = () => `
  display:inline-block;
  padding: 2px 4px;
  background: ${colors.G_30};
  border-radius: 3px;
  ${fonts.NOTO_11_400};
  color:${colors.G_200};
`;

export default ({
  clearIonButtonStyle,
  dimmer,
  importantify,
});
