import URIs from 'consts/URIs';
import { IGetCompaniesReportRs, IPostAuthRequestRq } from 'interfaces/_v2/profile/INhisRqRs';
import request from 'utils/request';

const NhisAPI = {
  // NHIS 인증 요청
  postAuthRequest: async (data: IPostAuthRequestRq) => {
    try {
      await request<void>({
        method: 'POST',
        url: URIs.post_nhis_auth_request,
        data,
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // NHIS 인증 체크
  postAuthCheck: async () => {
    try {
      await request<void>({
        method: 'POST',
        url: URIs.post_nhis_auth_check,
      });
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
  // NHIS 경력 리스트 조회
  getCompaniesReport: async () => {
    try {
      const res = await request<IGetCompaniesReportRs>({
        method: 'GET',
        url: URIs.get_nhis_companies_report,
      });
      return res.companies;
    } catch (e) {
      const err = e as { response: any };
      throw err;
    }
  },
};

export default NhisAPI;
