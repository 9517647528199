import Colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import SpecialtyFoldedCard from 'components/_v2/company/CompanyFoldedCard';
import mapTextToImage from 'consts/_v2/company/CompanyReportType';
import { Strength } from 'interfaces/_v2/matchingReport/IMatchingReportRs';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import CompanyModel from 'models/_v2/CompanyModel';
import MatchReportModel from 'models/_v2/MatchReportModel';
import { injectStore } from 'models/store';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const Frame = styled.div`
  width: 100%;
`;

const TitleFrame = styled.div`
display: flex;
flex-direction: column;
padding:40px 24px 0 24px;
  .title-text {
    font: ${Fonts.H4_Bold};
    color: ${Colors.JOBDA_BLACK};
    margin-bottom: 32px;
  }
  @keyframes moveTooltip {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-6px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .tooltip {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    height: 32px;
    padding: 0 12px;
    border-radius: 4px;
    background: linear-gradient(90deg, #4bd667 0%, #40ccc6 100%);
    animation: moveTooltip 2s infinite ease-in-out;
    font: ${Fonts.B3_Medium};
    color: ${Colors.WHITE_100};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 18px;
      width: 0;
      height: 0;
      border-top: 8px solid #40ccc6;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
  }
  .middle-text{
    font:${Fonts.B1_Bold_P};
    color:${Colors.JOBDA_BLACK};
    margin-bottom: 24px;
    margin-top: 8px;
  }
  .tip-text{
    font:${Fonts.B3_Bold};
    color:${Colors.G_200};
    margin-bottom: 8px;
  }
  .sub-text{
    font:${Fonts.B3_Medium_P};
    color:${Colors.CG_70};
    margin-bottom: 40px;
  }
`;

const CardFrame = styled.div`
   display : flex ;
    .specialty-custom{
        width:186px;
        margin-right: 16px;
  }
`;
const SpecialtyFrame = styled.div<{item?:any, index?:number}>`
    position: relative;
    width: 186px;
    height: 180px;
    padding: 32px 24px 24px 32px;
    .icon{
        position: absolute;
        bottom: 24px;
        right: 24px;
        width: 54px;
        height: 54px;

    }
    ${(props) => props.index === 0
    && css`
      background-color: ${Colors.LG_100};
    `}

  ${(props) => props.index === 1
    && css`
      background-color: #A2D6FC;
    `}

  ${(props) => props.index === 2
    && css`
      background-color: ${Colors.LB_100};
    `}    border-radius: 16px;
    .title-text{
        font:${Fonts.B1_Bold};
        color:${Colors.JOBDA_BLACK};
    }

`;
const SpecialtyFoldedFrame = styled.div`
    padding: 40px 24px;
`;

const SpecialtyCard = (item:Strength, idx:number) => (
  <SpecialtyFrame index={idx}>
    <div className='title-text'>{item.keyword}</div>
    <img src={mapTextToImage(item.keyword)} alt='' className='icon' />
  </SpecialtyFrame>
);

interface ICompanyReportCompetency {
  companyModel?: CompanyModel;
  matchReportModel?:MatchReportModel;
  login?:Login
}
const CompanyReportCompetency: FC<ICompanyReportCompetency> = ({ companyModel = new CompanyModel(), matchReportModel = new MatchReportModel(), login = new Login() }) => {
  
  return (
    <Frame>
      {
        matchReportModel.matchReportPositionsDetail && (
        <>
          <TitleFrame>
            <div className='title-text'>
              기업에서 긍정적으로 평가할 수 있는 <br />
              역량 분석 결과를 확인해보세요.
            </div>
            <div className='tooltip'>기업에서 선호할 수 있는</div>
            <div className='middle-text'>{login.userInfo?.name}님만의 <br />특별한 강점을 알려드릴게요.</div>
            <div className='tip-text'>면접 준비 TIP</div>
            <div className='sub-text'>강점 키워드를 강조하여 자기 소개를 준비하고<br />나의 강점을 살려 면접 스토리를 만들어 보세요.</div>
          </TitleFrame>
          <CardFrame>
            <JDSwiper
              slidesPerView='auto'
              slidesOffsetBefore={24}
              slidesOffsetAfter={16}
              navigation
              main={false}
            >
              {
            matchReportModel.matchReportPositionsDetail.competencyAnalysis.strengths.map((item, idx) => (
              <JDSwiperSlide key={item.keyword} className='specialty-custom'>
                {SpecialtyCard(item, idx)}
              </JDSwiperSlide>
            ))
          }
            </JDSwiper>
          </CardFrame>
          <SpecialtyFoldedFrame>
            {matchReportModel.matchReportPositionsDetail.competencyAnalysis.strengths.map((item:Strength) => (

              <SpecialtyFoldedCard item={item} />
            ))}
          </SpecialtyFoldedFrame>
        </>
        )
      }

    </Frame>
  );
};

export default inject(injectStore.companyModel, injectStore.matchReportModel, injectStore.login)(observer(CompanyReportCompetency));
