// 매칭 알림타입
export enum NotificationType {
  INTERVIEW_OFFER = 'INTERVIEW_OFFER', // 기존 매칭 제안
  STATUS_CHANGE = 'STATUS_CHANGE', // 상태 변경
  DETAILED_BROWSE ='DETAILED_BROWSE', // 프로필 상세 조회
  JD_CHANGE = 'JD_CHANGE', // JD 변경알림
  ACC_ANALYSIS_COMPLETE = 'ACC_ANALYSIS_COMPLETE', // 역량검사 분석완료
  FILE_REQUEST ='FILE_REQUEST', // 추가 서류 제출
  FILE_REQUEST_DEADLINE_REMINDER_D_DAY = 'FILE_REQUEST_DEADLINE_REMINDER_D_DAY', // 추가 서류 제출 마감(D-Day)
  SIMPLE_MESSAGE ='SIMPLE_MESSAGE', // 일반 메시지 도착
  PACC_ANALYSIS_COMPLETE ='PACC_ANALYSIS_COMPLETE', // 개역량검사 분석완료
  PST_ANALYSIS_COMPLETE ='PST_ANALYSIS_COMPLETE', // 개능검 분석완료
  ACC_EXPIRED ='ACC_EXPIRED', // 역량검사 유효기간 만료
  PACC_EXPIRED ='PACC_EXPIRED', // 개역량검사 유효기간 만료
  PST_EXPIRED ='PST_EXPIRED', // 개능검 유효기간 만료
  GENERAL_CREATED ='GENERAL_CREATED', // 일반 인재풀 추가
  DEVELOPER_CREATED ='DEVELOPER_CREATED', // 개발자 인재풀 추가
  GENERAL_DELETED ='GENERAL_DELETED', // 일반 인재풀 제외
  DEVELOPER_DELETED ='DEVELOPER_DELETED', // 개발자 인재풀 제외
  ACC_REQUEST = 'ACC_REQUEST', // 역량검사 제출 요청
  PHS_REQUEST = 'PHS_REQUEST', // 개발자 검사 제출 요청
  MATCHING_TALK_LINK = 'MATCHING_TALK_LINK', // 매칭톡 메세지 연동
  PROFILE_UPDATE_BY_RESUME = 'PROFILE_UPDATE_BY_RESUME', // 프로필 업데이트 알람
  INSIGHT_STATUS_CHANGE='INSIGHT_STATUS_CHANGE', // 인사이트 지원상태 변경 알림
  JOB_APPLICATION_SUBMITTED = 'JOB_APPLICATION_SUBMITTED', // 입사 지원 완료
  JOB_APPLICATION_CANCELED = 'JOB_APPLICATION_CANCELED', // 입사 지원 취소
  INTERVIEW_OFFER_RESPONDED = 'INTERVIEW_OFFER_RESPONDED', // 면접 제안 답변 완료
  INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY ='INTERVIEW_OFFER_DEADLINE_REMINDER_D_DAY', // 면접 제안 답변 기안(D-Day)
  INTERVIEW_OFFER_DEADLINE_REMINDER_D_3 ='INTERVIEW_OFFER_DEADLINE_REMINDER_D_3', // 면접 제안 답변 기안(D-3)
  JOB_APPLICATION_DEADLINE_REMINDER_D_DAY ='JOB_APPLICATION_DEADLINE_REMINDER_D_DAY', // 입사 지원 마감(D-Day)
  JOB_APPLICATION_DEADLINE_REMINDER_D_3 ='JOB_APPLICATION_DEADLINE_REMINDER_D_3', // 입사 지원 마감(D-3)
  PROFILE_INPUT_REMINDER = 'PROFILE_INPUT_REMINDER', // 프로필 입력 유도
  ACC_COMPLETE = 'ACC_COMPLETE', // 역량검사 응시완료
  PACC_COMPLETE = 'PACC_COMPLETE', // 개역량검사 응시완료
  PST_COMPLETE = 'PST_COMPLETE', // 개능검 응시완료
  ACC_RESULT_SUBMITTED = 'ACC_RESULT_SUBMITTED', // 역량검사 결과 제출완료
  PACC_RESULT_SUBMITTED = 'PACC_RESULT_SUBMITTED', // 개역량검사 결과 제출완료
  PST_RESULT_SUBMITTED = 'PST_RESULT_SUBMITTED', // 개능검 결과 제출완료
  ACC_REMINDER = 'ACC_REMINDER', // 역량검사 응시 시도 유도
  PACC_REMINDER = 'PACC_REMINDER', // 개역량검사 응시 시도 유도
  PST_REMINDER = 'PST_REMINDER', // 개능검 응시 시도 유도
  ACC_COMPLETE_REMINDER = 'ACC_COMPLETE_REMINDER', // 역량검사 응시 완료 유도
  PACC_COMPLETE_REMINDER = 'PACC_COMPLETE_REMINDER', // 개역량검사 응시 완료 유도
  PST_COMPLETE_REMINDER = 'PST_COMPLETE_REMINDER', // 개능검 응시 완료 유도
  ACC_RESULT_SUBMITTED_REMINDER_D_DAY = 'ACC_RESULT_SUBMITTED_REMINDER_D_DAY', // 역량검사 결과 제출 유도(D-Day)
  PACC_RESULT_SUBMITTED_REMINDER_D_DAY = 'PACC_RESULT_SUBMITTED_REMINDER_D_DAY', // 개역량검사 결과 제출 유도(D-Day)
  PST_RESULT_SUBMITTED_REMINDER_D_DAY = 'PST_RESULT_SUBMITTED_REMINDER_D_DAY', // 개능검 결과 제출 유도(D-Day)
  ACC_RESULT_SUBMITTED_REMINDER_D_3 = 'ACC_RESULT_SUBMITTED_REMINDER_D_3', // 역량검사 결과 제출 유도(D-3)
  PACC_RESULT_SUBMITTED_REMINDER_D_3 = 'PACC_RESULT_SUBMITTED_REMINDER_D_3', // 개역량검사 결과 제출 유도(D-3)
  PST_RESULT_SUBMITTED_REMINDER_D_3 = 'PST_RESULT_SUBMITTED_REMINDER_D_3', // 개능검 결과 제출 유도(D-3)
  REGISTER_USER = 'REGISTER_USER', // 회원가입 축하
  ACC_RECOMMEND = 'ACC_RECOMMEND', // 역량검사 응시 유도
  MATCH_INTERVIEW_OFFER = 'MATCH_INTERVIEW_OFFER', // 매칭 대시보드 > 면접 제안
  RECOMMEND = 'RECOMMEND', // 매칭 추천됨
  MATCH_OFFER = 'MATCH_OFFER', // 매칭 대시보드 > 입사지원 제안
  INSIGHT_RESUME_SAVED = 'INSIGHT_RESUME_SAVED', // 잡플렉스 지원 현황 연동
  CHAT = 'CHAT', // 채팅 관련 알림
}
export interface INotification {
  notificationSn: number;
  type: NotificationType;
  data: INotificationDataRs;
  createdDateTime: string;
  accessYn: boolean;
}

export interface INotificationDataRs {
  companySn?: number;
  companyName?: string;
  positionSn?: number;
  positionName?: string;
  expirationDays?: number;
  applySn?: number;
}

export interface INotificationRs {
  unreadCount: number;
  notifications: INotification[];
}
