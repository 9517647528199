/* eslint-disable @typescript-eslint/no-shadow */
import { yupResolver } from '@hookform/resolvers/yup';
import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import JDSaveButton from 'components/_v2/_common/button/JDSaveButton';
import JDCheckInput, { JD_CHECK_INPUT_TYPE } from 'components/_v2/_common/input/JDCheckInput';
import ProfilePreferentialDisability from 'components/_v2/profile/preferential/ProfilePreferentialDisability';
import ProfilePreferentialMilitary from 'components/_v2/profile/preferential/ProfilePreferentialMilitary';
import ProfilePreferentialVeteran from 'components/_v2/profile/preferential/ProfilePreferentialVeteran';
import ErrorCode, { ErrorCodeText } from 'consts/ErrorCodes';
import { DisabilityGrade } from 'consts/_v2/profile/DisabilityType';
import { MilitaryStatus } from 'consts/_v2/profile/MilitaryType';
import { VeteranType } from 'consts/_v2/profile/VeteranType';
import { useProfileFormDirtyObserver } from 'hooks/useFormDirtyObserver';
import useToast from 'hooks/useToast';
import { IProfilePreferentialRqRs } from 'interfaces/_v2/profile/IProfilePreferentialRqRs';
import { IProfileProps } from 'interfaces/_v2/profile/IProfileProps';
import { inject, observer } from 'mobx-react';
import TermsModel from 'models/TermsModel';
import ProfileModel from 'models/_v2/profile/ProfileModel';
import { injectStore } from 'models/store';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import ProfileEditTitleComponent from 'components/_v2/profile/ProfileEditTitleComponent';
import SensitiveTermsModal from 'components/_v2/_common/terms/SensitiveTermsModal';

const Frame = styled.div`
    padding: 0 16px 108px 16px;
    .header-title {
        font: ${Fonts.H5_Bold};
        line-height: 22px;
        color: ${colors.JOBDA_BLACK};
        margin-bottom: 24px;
    }

    .box-frame {
      display: flex;
      width: 100%;
      padding: 16px 0;
      font: ${Fonts.B2_Medium_P};
      color: ${colors.JOBDA_BLACK};
      background: ${colors.CG_30};
      margin-bottom: 16px;

      .label {
        position: relative;
        display: flex;
        align-items: center;

      .title {
        padding-right: 48px;
      }
    }

    .input {
      margin-right: 12px;
    }

    .mark {
      margin: 0 12px;
    }

    .arrow-btn {
      position: absolute;
      right: 12px;
    }
    }


`;

const FormSquareCheckBoxFrame = styled.div`
  display: flex;
  align-items: center;

  .text {
    font: ${Fonts.B1_Medium};
    padding-left: 0;
    color: ${colors.CG_90};
    margin-right: 24px;
  }

`;

const profilePreferentialSchema = yup.object({
  military: yup.object().when('militaryCheckBox', {
    is: true,
    then: (schema) => yup.lazy((military) => {
      if (!military) return schema;
      if (military.status === null)
        return schema.shape({ status: yup.string().required('내용을 입력해 주세요.') });

      // 제대, 복무중인 경우에만 내부 필드 필수값
      if ([MilitaryStatus.IN_SERVICE, MilitaryStatus.DISCHARGE].includes(military.status))
        return schema.shape({
          status: yup.string().required('내용을 입력해 주세요.'),
          type: yup.string().required('군별을 입력해 주세요.').nullable(),
          militaryClass: yup.string().required('계급을 입력해 주세요.').nullable(),
          dischargeType: yup.lazy(() => (
            yup.string().when('military.status', (status: string, schema: any) => {
              if (military.status === MilitaryStatus.IN_SERVICE) {
                return schema.nullable();
              }
              return yup.string().required('제대 구분을 입력해 주세요.').nullable();
            }))),
          startDate: yup.lazy((startDate) => (startDate ? yup.date() : yup.string().required('입대 일자를 입력해 주세요.').nullable())),
          endDate: yup.lazy((endDate) => (endDate
            ? yup.date().when('startDate', (startDate: string, schema: any) => (startDate ? schema.min(startDate, '제대 일자가 입대 일자보다 빠릅니다.') : schema))
            : yup.string().when('military.status', (status: string, schema: any) => {
              // 복무 중인 경우 종료일자 없음.
              if (military.status === MilitaryStatus.IN_SERVICE) {
                return schema.nullable();
              }
              return schema.required('제대 일자를 입력해 주세요.').nullable();
            }))),
        });

      return schema.shape({ status: yup.string().required('내용을 입력해 주세요.') });
    }),
    otherwise: (schema) => schema,
  }),
  militaryCheckBox: yup.boolean(),
  disability: yup.object().when('profileDisabilityCheckBox', {
    is: true,
    then: (schema) => schema.shape({
      grade: yup.string().required('장애 등급을 입력해 주세요.'),
      type: yup.string().when('grade', {
        is: (grade: DisabilityGrade) => grade !== DisabilityGrade.NONE,
        then: yup.string().required('내용을 입력해 주세요.'),
        otherwise: yup.string().nullable(),
      }),
    }),
    otherwise: (schema) => schema,
  }),
  profileDisabilityCheckBox: yup.boolean(),
  veteran: yup.object().when('profileVeteranCheckBox', {
    is: true,
    then: (schema) => schema.shape({
      status: yup.string().required('보훈 여부를 입력해 주세요.'),
      reason: yup.string().when('status', {
        is: (status: VeteranType) => status !== VeteranType.NONE,
        then: yup.string().required('대상 사유를 입력해 주세요.'),
        otherwise: yup.string().nullable(),
      }),
    }),
    otherwise: (schema) => schema,
  }),
  profileVeteranCheckBox: yup.boolean(),
});

interface IProfilePreferentialProps extends IProfileProps{
    termsModel?: TermsModel;
    onClose?: () => void;
  }

const ProfilePreferential: FC<IProfilePreferentialProps> = ({ onClose, termsModel = new TermsModel(), profileModel = new ProfileModel() }) => {
  const [isOpenTermModal, setIsOpenTermModal] = useState<boolean>(false);

  const useFormed = useForm<Partial<IProfilePreferentialRqRs>>({
    mode: 'onSubmit',
    resolver: yupResolver(profilePreferentialSchema),
    defaultValues: {
      military: {
        status: undefined,
        type: undefined,
        militaryClass: undefined,
        dischargeType: undefined,
        startDate: undefined,
        endDate: undefined,
      },
      disability: {
        grade: undefined,
        type: undefined,
      },
      veteran: {
        reason: undefined,
        status: undefined,
      },
    },
  });
  const { handleSubmit, control, setValue, getValues, formState, reset } = useFormed;
  const { setToastObject } = useToast();
  const { fields: militaryFields, append: militaryAppend, remove: militaryRemove } = useFieldArray({
    control,
    name: 'profileMilitary',
  });

  const { fields: disabilityFields, append: disabilityAppend, remove: disabilityRemove } = useFieldArray({
    control,
    name: 'profileDisability',
  });

  const { fields: veteranFields, append: veteranAppend, remove: veteranRemove } = useFieldArray({
    control,
    name: 'profileVeteran',
  });
  useProfileFormDirtyObserver(formState);

  const onSubmit = useCallback(async () => {
    const inputData = getValues();

    // military enum null값 변환
    if (inputData.military) {
      if (!inputData.military.type) inputData.military.type = null;
      if (!inputData.military.militaryClass) inputData.military.militaryClass = null;
      if (!inputData.military.dischargeType) inputData.military.dischargeType = null;
    }
    if (inputData.disability) {
      if (!inputData.disability.type) inputData.disability.type = null;
    }
    if (inputData.veteran) {
      if (!inputData.veteran.reason) inputData.veteran.reason = null;
      if (!inputData.veteran.status) inputData.veteran.status = null;
    }
    inputData.sourcePage = profileModel.sourcePage;
    try {
      await profileModel.savePreferential(inputData as Partial<IProfilePreferentialRqRs>);
      profileModel.setIsDirty(false);
      setToastObject({ isOpen: true, type: 'SUCCESS', message: '성공적으로 저장되었습니다.', subMessage: undefined });
      if (onClose) onClose();
    } catch (e) {
      const err = e as { response: { data: { errorCode: string } } };
      switch (err.response?.data?.errorCode) {
        case ErrorCode.B901:
          setToastObject({ isOpen: true, type: 'ERROR', message: ErrorCodeText.put_match_profiles_seek.B901 });
          break;
        case ErrorCode.B902:
          setToastObject({ isOpen: true, type: 'ERROR', message: ErrorCodeText.put_match_profiles_seek.B902 });
          break;
        case ErrorCode.B903:
          setToastObject({ isOpen: true, type: 'ERROR', message: ErrorCodeText.put_match_profiles_seek.B903 });
          break;
        default:
          setToastObject({ isOpen: true, type: 'ERROR', message: '저장에 실패하였습니다.' });
      }
      console.error(e);
    }
  }, []);

  const onError = () => {

  };

  useEffect(() => {
    const init = async () => {
      await profileModel.loadPreferential();

      if (profileModel.preferential) reset(profileModel.preferential);

      if (profileModel.preferential?.military && militaryFields.length === 0) {
        setValue('militaryCheckBox', true);
        militaryAppend({});
      }

      if (profileModel.preferential?.disability && disabilityFields.length === 0) {
        setValue('profileDisabilityCheckBox', true);
        disabilityAppend({});
      }

      if (profileModel.preferential?.veteran && veteranFields.length === 0) {
        setValue('profileVeteranCheckBox', true);
        veteranAppend({});
      }
    };

    init();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormProvider {...useFormed}>
          <ProfileEditTitleComponent
            title='병역 · 취업 우대사항이 있으면 입력해 주세요.'
            subtitle='해당 사항이 없다면 병역 사항에서 비대상을 선택해 주세요.'
            divider={false}
          />
          <Frame>
            <FormSquareCheckBoxFrame>
              <JDCheckInput
                type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
                name='militaryCheckBox'
                value
                label={<span className='text'>병역</span>}
                onChange={(checked: boolean) => {
                  if (checked) {
                    militaryAppend({
                      status: undefined,
                      type: undefined,
                      militaryClass: undefined,
                      dischargeType: undefined,
                      startDate: undefined,
                      endDate: undefined,
                    });
                  } else {
                    militaryRemove();
                    profileModel.militaryReset();
                  }
                }}
              />
              <JDCheckInput
                type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
                name='profileDisabilityCheckBox'
                value
                label={<span className='text'>장애</span>}
                onChange={(checked: boolean) => {
                  if (checked === true) {
                    if (termsModel.termsSensitiveYn?.privacyPolicyYn) {
                      disabilityAppend({
                        grade: undefined,
                        type: undefined,
                      });
                    } else {
                      setIsOpenTermModal(true);
                    }
                  } else {
                    disabilityRemove();
                    setValue('disability.grade', null);
                    setValue('disability.type', null);
                    profileModel.disabilityReset();
                  }
                }}
              />
              <JDCheckInput
                type={JD_CHECK_INPUT_TYPE.CHECK_BOX}
                name='profileVeteranCheckBox'
                value
                label={<span className='text'>보훈</span>}
                onChange={(checked: boolean) => {
                  if (checked === true) {
                    if (termsModel.termsSensitiveYn?.privacyPolicyYn) {
                      veteranAppend({
                        reason: '',
                        status: undefined,
                      });
                    } else {
                      setIsOpenTermModal(true);
                    }
                  } else {
                    veteranRemove();
                    setValue('veteran.status', null);
                    setValue('veteran.reason', '');
                    profileModel.veteranReset();
                  }
                }}
              />
            </FormSquareCheckBoxFrame>
            <ProfilePreferentialMilitary militaryFields={militaryFields} />
            <ProfilePreferentialDisability disabilityFields={disabilityFields} />
            <ProfilePreferentialVeteran veteranFields={veteranFields} />
          </Frame>
          <JDSaveButton fixed type='submit' onSubmit={() => {}} />
          { isOpenTermModal && (
            <SensitiveTermsModal
              onClickComplete={() => {
                if (getValues('profileDisabilityCheckBox')) {
                  disabilityAppend({
                    grade: undefined,
                    type: undefined,
                  });
                } else if (getValues('profileVeteranCheckBox')) {
                  veteranAppend({
                    reason: '',
                    status: undefined,
                  });
                }
              }}
              onClickClose={() => {
                if (!termsModel.termsSensitiveYn?.privacyPolicyYn) {
                  setValue('profileDisabilityCheckBox', false);
                  setValue('profileVeteranCheckBox', false);
                }
                setIsOpenTermModal(false);
              }}
            />
          )}
        </FormProvider>
      </form>
    </>
  );
};

export default inject(injectStore.termsModel, injectStore.profileModel)(observer(ProfilePreferential));
