import { Divider12Grey } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import colors from '__designkit__/common/colors';
import { lineClampBox } from 'consts/style/mixins';
import Fonts from '__designkit__/common/fonts';
import { ScrollHiddenMixin } from 'consts/_v2/_common/style/mixins';
import { IInfoCardData } from 'interfaces/IInfoData';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Swiper from 'swiper';
import Contents from 'models/_v2/Contents';
import { Space } from 'interfaces/_v2/contents/IContentsRq';
import JDSwiper, { JDSwiperSlide } from 'components/_v2/_common/JDSwiper';
import classnames from 'classnames/bind';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import styles from './accaQuestion.module.scss';

const cx = classnames.bind(styles);

interface IAccaQuestion{
  contents?: Contents;
}
const Frame = styled.div``;
const MainContentsFrame = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  background: ${colors.JOBDA_BLACK};
  margin-bottom: 32px;
  .contents-custom{
    width:240px;
    margin-right: 16px;
    border-radius: 10px;
  }
  
`;

const MainContentsCardFrame = styled.div`
  position:relative;
  width: 240px;
  justify-content: flex-start;
  .img{
    width: 240px;
    height: 180px;
    border-radius: 9.6px;
  }
  .title{
    display: flex;
    font: ${Fonts.B3_Medium};
    color:${colors.JOBDA_WHITE};
    text-decoration: underline;
    margin-top: 16px;
  }
  .subtitle{
    display: flex;
    text-align: start;
    font: ${Fonts.B2_Bold};
    color:${colors.JOBDA_WHITE};
    height: 36px;
    margin-top: 12px;
  }
  .hash-tag-frame{
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
    .hash-tag{
        font: ${Fonts.B3_Medium};
        color:${colors.CG_60};
        margin-right: 8px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }
  }
`;
const MainContentsCard = (item:IInfoCardData) => (
  <Link to={RoutePaths.info_detail(item.id)}>
    <MainContentsCardFrame>
      <img src={`${item.imageSrc}?w=480&h=360`} className='img' alt='img' />
      <div className='title'>{item.category}</div>
      <div className='subtitle'>{item.title}</div>
      <div className='hash-tag-frame'>
        <div className='hash-tag'>{item.subTitle}</div>
      </div>
    </MainContentsCardFrame>
  </Link>
);

const AccaQuestion:FC<IAccaQuestion> = ({ contents = new Contents() }) => {
  const [swiperObj, setSwiperObj] = useState<Swiper | null>(null);

  useEffect(() => {
    (async () => {
      await contents.getCategoryListDChamps(Space.ACC_GAME);
    })();
  }, [contents]);

  useEffect(() => {
    if (swiperObj && contents.dChampsContents) {
      swiperObj.slideTo(0);
    }
  }, [swiperObj, contents.dChampsContents]);

  return (
    <Frame>
      <em className={cx('detailInfoTitle')}>역량검사<br />문제 참고하기</em>
      <SpacingBlock size={24} vertical />
      <MainContentsFrame>
        <JDSwiper
          slidesPerView='auto'
          slidesOffsetBefore={16}
          slidesOffsetAfter={16}
          navigation
          contents
          main={false}
          onSwiper={(e) => setSwiperObj(e)}
        >
          {
            contents.dChampsContents.length > 0 && contents.dChampsContents.map((item) => (
              <JDSwiperSlide key={item.id} className='contents-custom'>
                {MainContentsCard(item)}
              </JDSwiperSlide>
            ))
          }
        </JDSwiper>
      </MainContentsFrame>
    </Frame>
  );
};

export default inject(injectStore.contents)(observer(AccaQuestion));
