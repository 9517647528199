import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import Icon from '__designkit__/icon/Icon';
import CountUpAni from 'components/_v2/_common/count/CountUpAni';
import AccResultSampleModal from 'components/_v2/acc/AccResultSampleModal';
import { Divider12G } from 'components/divider/Divider';
import RoutePaths from 'consts/RoutePaths';
import OLD_CANDIDATE_COUNT from 'consts/_v2/acc/acc';
import { inject, observer } from 'mobx-react';
import AccaGateModel from 'models/_v2/acca/AccaGateModel';
import { injectStore } from 'models/store';
import { IAccaGateProps } from 'pages/_v2/acca/AccaGate';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const Frame = styled.div`
  padding: 24px 16px 40px;

  & > .acca-total-count {
    font: ${Fonts.B3_Medium};
    color: ${colors.G_300};

    & > span {
      font: ${Fonts.B3_Bold};
    }
  }

  & > .title {
    font: ${Fonts.H3_Bold};
    color: ${colors.JOBDA_BLACK};
    animation: ${slideIn} .6s ease-in-out;
  }

  & > .description {
    font: ${Fonts.B3_Medium_P};
    color: ${colors.CG_80};
    transform: translateY(10px);
    opacity: 0;
    animation: ${slideIn} .6s ease-in-out 1 .12s forwards;
    

    & > .title {
      margin-bottom: 8px;
      font: ${Fonts.B1_Bold};
      color: ${colors.JOBDA_BLACK};
    }
  }
`;

const AdvantageList = styled.div<{ itemCount: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(10px);
  opacity: 0;
  animation: ${slideIn} .6s ease-in-out 1 .2s forwards;

  & > .advantage-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${({ itemCount }) => `calc(((100% - (8px * ${itemCount - 1})) / ${itemCount}))`};
    height: fit-content;
    padding: 16px 0;
    border-radius: 8px;
    background-color: ${colors.JOBDA_WHITE};
    
    & > div {
      font: ${Fonts.B3_Medium};
      color: ${colors.JOBDA_BLACK};
      text-align: center;

      & > em {
        font: ${Fonts.B3_Bold};
      }
    }
  }
`;

const AdditionalExplanationList = styled.div`
  display: flex;
  align-items: center;
  transform: translateY(10px);
  opacity: 0;
  animation: ${slideIn} .6s ease-in-out 1 .16s forwards;

  & > .additional-explanation-item {
    display: flex;
    align-items: center;
    font: ${Fonts.B3_Medium};
    color: ${colors.CG_70};
  }
`;

const BtnList = styled.div`
  & > .btn-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 16px 24px;
    border: 2px solid ${colors.JOBDA_BLACK};
    border-radius: 8px;
    font: ${Fonts.B1_Bold};
    color: ${colors.JOBDA_BLACK};

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    & > .left {
      display: flex;
      align-items: center;

      & > span {
        font: ${Fonts.B1_Medium};
      }
    }
  }

  & > .notice-box {
    width: 100%;
    height: fit-content;
    padding: 24px 20px;
    margin-top: 16px;
    border-radius: 8px;
    background-color: ${colors.JOBDA_WHITE};

    & > .title {
      margin-bottom: 8px;
      font: ${Fonts.B2_Bold};
      color: ${colors.JOBDA_BLACK};
    }

    & > .description {
      font: ${Fonts.B3_Medium};
      color: ${colors.CG_70};
    }
  }
`;

const MoreInformationList = styled.div`
  & > .title {
    margin-bottom: 12px;
    font: ${Fonts.B2_Bold};
    color: ${colors.JOBDA_BLACK};
  }

  & > .information-list {
    display: flex;
    align-items: center;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    
    & > .information-item {
      padding: 8px 16px;
      border: 1px solid ${colors.CG_50};
      border-radius: 48px;
      font: ${Fonts.B3_Bold};
      white-space: nowrap;

      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
`;

const AccaGateService: FC<IAccaGateProps> = ({ accaGateModel = new AccaGateModel() }) => {
  const history = useHistory();
  const [isOpenAccResultSampleModal, setIsOpenAccResultSampleModal] = useState(false);

  useEffect(() => {
    (async () => {
      await accaGateModel.loadMaxApplyChange();
    })();
  }, [accaGateModel]);

  return (
    <>
      <Frame>
        <div className='acca-total-count'>
          현재 누적 응시자 : &nbsp;<CountUpAni number={OLD_CANDIDATE_COUNT + (accaGateModel.applyCount?.total || 0)} font={Fonts.B3_Bold} color={colors.G_300} /><span>명</span>
        </div>
        <SpacingBlock size={8} vertical />
        <div className='title'>역량검사 한 번으로, 취업 준비 끝!</div>
        <SpacingBlock size={32} vertical />
        <div className='description'>
          <div className='title'>
            역량검사란?
          </div>
          <div>
            나의 잠재 역량을 파악하고 취업에 직접 활용할 수 있는 검사예요.
          </div>
        </div>
        <SpacingBlock size={24} vertical />
        <AdvantageList itemCount={3}>
          <div className='advantage-item'>
            <Icon name='matching_person_green' size={40} />
            <SpacingBlock size={12} vertical />
            <div>한 번 응시하면<br /><em>750개 기업<br />취업 준비</em> 완료</div>
          </div>
          <div className='advantage-item'>
            <Icon name='acca' size={40} />
            <SpacingBlock size={12} vertical />
            <div>나만의 <em>자기소개서<br />작성 가이드,<br />면접 가이드</em> 제공</div>
          </div>
          <div className='advantage-item'>
            <Icon name='invitation-user' size={40} />
            <SpacingBlock size={12} vertical />
            <div>채용 담당자에게<br /><em>제안받는 매칭<br />대상자</em>로 선정</div>
          </div>
        </AdvantageList>
        <SpacingBlock size={24} vertical />
        <AdditionalExplanationList>
          <div className='additional-explanation-item'>
            <Icon name='time' size={24} color={colors.CG_70} />
            <SpacingBlock size={4} />
            <span>80분 (이어하기 가능)</span>
          </div>
          <SpacingBlock size={24} />
          <div className='additional-explanation-item'>
            <Icon name='contents' size={24} color={colors.CG_70} />
            <SpacingBlock size={4} />
            <span>질의응답, 게임, 영상면접</span>
          </div>
        </AdditionalExplanationList>
        <SpacingBlock size={32} vertical />
        <BtnList>
          <div className='btn-item' role='button' onClick={() => { history.push(RoutePaths.acca_results()); }}>
            <div className='left'>
              <Icon name='project-light' size={32} color={colors.JOBDA_BLACK} style={{ marginRight: '8px' }} />
              결과표 확인하기
            </div>
            <Icon name='arrow-right' size={32} color={colors.JOBDA_BLACK} />
          </div>
          <div className='notice-box'>
            <div className='title'>튜토리얼 연습 및 실전 응시는 PC에서 가능해요.</div>
            <div className='description'>정확도 높은 역량 측정을 위해 PC에서 응시를 권장하고 있어요.</div>
          </div>
        </BtnList>
        <SpacingBlock size={32} vertical />
        <MoreInformationList>
          <div className='title'>
            더 알아보기
          </div>
          <div className='information-list'>
            <div className='information-item' role='button' onClick={() => { history.push(RoutePaths.acca_introcue); }}>소개</div>
            <div className='information-item' role='button' onClick={() => setIsOpenAccResultSampleModal(true)}>결과표 예시</div>
            <div className='information-item' role='button' onClick={() => { window.location.href = 'https://m.jobda.im/info/344'; }}>응시 가이드</div>
          </div>
        </MoreInformationList>
      </Frame>
      <Divider12G />
      <AccResultSampleModal isOpen={isOpenAccResultSampleModal} onClickClose={() => setIsOpenAccResultSampleModal(false)} />
    </>
  );
};
export default inject(injectStore.accaGateModel)(observer(AccaGateService));
